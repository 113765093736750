import { Session } from "../../../business";
import { baseUrl } from "../../../constants/enviroment";
import { GenericObject } from "../../types/common";

const removeDataForRequest = (formData: GenericObject) => {
    let isSave = false;
    let formKey = null;
    let process = null;
    let task = null;

    for (var pair of formData.entries()) {
        if (pair[0] === "accao" && pair[1] === "guardar") {
            isSave = true;
        } else if (pair[0] === "formulario") {
            formKey = pair[1];
        } else if (pair[0] === "tarefa") {
            task = pair[1];
        } else if (pair[0] === "processo") {
            process = pair[1];
        }
    }

    if (task && process) {
        formKey = `${process}-${task}`;
    }

    if (isSave && formKey) {
        localStorage.removeItem(formKey);
    }
};

const saveDataForRequest = (formData: GenericObject, route = "", backup: any = null) => {
    if (!route.includes("gereprocesso") || !backup) {
        return;
    }

    let isSave = false;
    let formKey = null;
    let process = null;
    let task = null;

    for (var pair of formData.entries()) {
        if (pair[0] === "accao" && pair[1] === "guardar") {
            isSave = true;
        } else if (pair[0] === "formulario") {
            formKey = pair[1];
        } else if (pair[0] === "tarefa") {
            task = pair[1];
        } else if (pair[0] === "processo") {
            process = pair[1];
        }
    }

    if (task && process) {
        formKey = `${process}-${task}`;
    }

    if (!isSave || !formKey) {
        return;
    }

    const tempSaveData = btoa(JSON.stringify(backup));
    localStorage.setItem(formKey, tempSaveData);
};

type RequestResponseStatus = "SUCCESS" | "ERROR" | "UNAUTHORIZED";

// Use on login
interface RequestOptions {
    method: string;
    endPoint: string;
    body?: any;
    params?: GenericObject;
    headers?: any;
    backup?: any;
}
interface ApiFetchResponse<T> {
    status: RequestResponseStatus;
    response: T | null;
    error?: any;
    message?: string;
}
export const api_fetch = <T = any>({
    endPoint,
    body,
    params,
    method = "POST",
    backup = null,
}: RequestOptions): Promise<ApiFetchResponse<T>> =>
    new Promise(async (resolve) => {
        if (params) { 
            body = new FormData();
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    body.append(key, params[key]);
                }
            }
        }

        if (Session.isCLient()) {
            const params: any = Session.getAll();
            body.append("usr_parametro1", params?.parametro1 || '');
            body.append("usr_parametro2", params?.parametro2 || '');
            body.append("usr_parametro3", params?.parametro3 || '');
        }

        fetch(`${baseUrl}/${endPoint}`, {
            method: method,
            body,
            headers: {
                Authorization: `Bearer ${Session.getToken()}`,
            },
        })
            .then(async (response) => {
                if (response.status >= 400) {
                    if (response.status === 401 || response.status === 403) {
                        saveDataForRequest(body, endPoint, backup);
                        Session.remove();
                        window.location.reload();
                    }

                    return response.json().then((responseError) => {
                        return resolve({
                            status: "ERROR",
                            message: responseError.message,
                            error: responseError,
                            response: null,
                        });
                    });
                }

                removeDataForRequest(body);

                return response
                    .clone()
                    .text()
                    .then((text) => {
                        if (/error:/i.test(text) || /erroqa:/i.test(text) || /\s+line\s+/.test(text)) {
                            return resolve({
                                status: "ERROR",
                                message: text.replace(/error:|erroqa:|context:[\W\w]+/gi, ""),
                                error: text,
                                response: null,
                            });
                        }

                        return response.json().then((response) => {
                            if (response.erro) {
                                return resolve({
                                    status: "ERROR",
                                    message: response.erro,
                                    error: response,
                                    response: null,
                                });
                            } else {
                                return resolve({
                                    status: "SUCCESS",
                                    response,
                                });
                            }
                        });
                    });
            })
            .catch((error) => {
                return resolve({
                    message: error.message,
                    error,
                    status: "ERROR",
                    response: null,
                });
            });
    });
