import { Icon, Menu, message, Modal, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { backup } from "../../actions";
import { Session, Utilitaries } from "../../business";
import { initProcess } from "../../modules/process_execution/actions";

import { IMenu, IMenuType } from "../../V2/types/menuTypes";
import { qaRoutesFromDB } from "../routes";

interface IProps {
    recentsProcesses: any[];
    defaultListOfMenus: IMenu[];
    changeForumModalState: any;
    changeChatModalState: any;
    changeForumConfModalState: any;
    changeChatConfModalState: any;
    changeUpdateModalState: any;
    showColorPalette: any;
}

export function arrayToTree(list: IMenu[], parent: IMenu | null = null, tree: IMenu[] = [] as IMenu[]): IMenu[] {
    let children: IMenu[] = [];

    if (!parent && Session.isPortalOrClient()) {
        children = list.filter((item: IMenu) => {
            if (typeof item.pai === "object" || list.filter((item2: IMenu) => item2.idmenu === item.pai).length === 0) {
                return true;
            }

            return false;
        });
    } else if (!parent) {
        children = list.filter((item) => item.pai === null || typeof item.pai === "object");
    } else {
        children = list.filter((item) => item.pai === parent.idmenu);
    }

    if (children.length > 0) {
        if (parent === null) {
            tree = children;
        } else {
            parent.children = children;
        }

        children.forEach((child) => {
            arrayToTree(list, child);
        });
    }

    return tree;
}

const { SubMenu } = Menu;

const listNameToOpenFormV2 = [
    "orbis_lista_entidades.xml",
    "orbis_consulta_despesas.xml",
    "orbis_consulta_fcr.xml",
    "fac_resumo_faturacao.xml",
    "qa_lista_despesas.qml.xml",
    "orbis_cons_valores_divida.xml",
    "orbis_consulta_movimentos_caixa.xml",
    "orbis_confirmacao_extracto_bancario.xml",
    "orbis_relatorios_exercicio.xml",
    "qa_lista_processamentos.qml.xml",
    "orbis_configs_condominios_v2.qml.xml",
    "qa_trad_processos.xml",
    'qualia_parametrizacao_mensalidade2.qml.xml',
    'qualia_perfil_inicial_pi.qml.xml',
    'portal_cli_registo_diarios.qml.xml'
];

const openInFormV2 = (formName: string) => {
    return listNameToOpenFormV2.includes(formName);
};

export function SideBarMenu({
    recentsProcesses,
    defaultListOfMenus,
    changeForumModalState,
    changeChatModalState,
    changeForumConfModalState,
    changeChatConfModalState,
    changeUpdateModalState,
    showColorPalette,
}: IProps) {
    const [listOfMenus, setListOfMenus] = useState<IMenu[]>([]);
    const [menuTree, setMenuTree] = useState<IMenu[]>([]);
    const [stackMenu, setStackMenu] = useState<IMenu[]>([]);
    const [showRecents, setShowRecents] = useState(false);
    const [menuType, setMenuType] = useState<IMenuType>("ALL");

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const list = Utilitaries.toArray(JSON.parse(JSON.stringify(defaultListOfMenus))) as IMenu[];
        const tree = arrayToTree(list);
        console.log("tree", { tree });
        setMenuTree(tree);
    }, [defaultListOfMenus]);

    useEffect(() => {
        changeMenuType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuType]);

    useEffect(() => {
        if (listOfMenus.length === 0) {
            changeMenuType();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuTree]);

    const changeMenuType = () => {
        if (Utilitaries.isEmpty(menuTree) && !Utilitaries.isEmpty(defaultListOfMenus)) {
            return;
        }
        if (menuType && menuType === "MENU_DIREITA") {
            const _items = menuTree.find((it) => it.webaccao === "MENU_DIREITA");
            setListOfMenus(_items ? (_items.children as IMenu[]) : []);
        } else {
            setListOfMenus(menuTree.filter((it) => it.webaccao !== "MENU_DIREITA"));
        }
        setStackMenu([]);
    };

    const qaActionFromDBRoutes = {
        FORUM: () => changeForumModalState(true),
        CHAT: () => changeChatModalState(true),
        OpenChat: () => changeChatModalState(true),
        OpenUpdateApp: () => changeUpdateModalState(true),
        // Mudar Para um componente modal
        "configuracao_forum.xml": (form: any) => changeForumConfModalState(true, form),
        listar_salas_chat: (list: any) => changeChatConfModalState(true, list),
    };

    const setchildrenToMainMenu = (item: IMenu, children: IMenu[]) => {
        const menuClicked = { ...item };

        if (menuClicked.goBack) {
            setListOfMenus(menuClicked.goBack);

            const clickedItemInStackIndex = stackMenu.findIndex((it) => it.idmenu === menuClicked.idmenu);

            let root = [...stackMenu];
            if (clickedItemInStackIndex > 0) {
                root = root.slice(0, clickedItemInStackIndex);
            } else {
                root = [];
            }
            setStackMenu(root);
        } else {
            const currentItem = { ...menuClicked, goBack: [...listOfMenus] };

            const root = [...stackMenu, currentItem];

            setListOfMenus([...root, ...children]);
            setStackMenu(root);
        }
    };

    const executeTask = async (processName: any) => {
        // @ts-ignore
        const { processId, taskId, erro } = await dispatch(
            // @ts-ignore
            initProcess({
                process: {
                    nome: processName,
                },
                stablishment: 1,
            })
        );
        if (processId && taskId) {
            history.push({
                pathname: `/portal/process/${processId}/${taskId}`,
            });
        } else {
            message.error("Ocorreu um erro: " + (erro || ""));
        }
    };

    const handleClickProcessRecents = async (processName: string) => {
        // @ts-ignore
        const { processId, taskId } = await dispatch(
            // @ts-ignore
            initProcess({
                process: {
                    nome: processName,
                },
                stablishment: 1,
            })
        );
        if (processId && taskId) {
            const search = `?taskId=${taskId}&routeContext=recents`;
            history.push({
                pathname: "/task/execution/map/" + processId,
                search,
            });
        } else {
            message.error("Ocorreu um erro");
        }
    };

    const makeBackup = () => {
        Modal.confirm({
            title: "Pretende realizar a cópia de segurança?",
            onOk: async () => {
                try {
                    const response = await backup();
                    response ? message.success(response.mensagem) : message.error("Ocorreu um erro");
                } catch {
                    message.error("Ocorreu um erro");
                }
            },
        });
    };

    const renderMenus = (menus: IMenu[] = []) => {
        return (
            menus
                // eslint-disable-next-line eqeqeq
                .filter((menu) => !menu?.pai || menu.pai != "0")
                .map((child, index) => {
                    if (child.children && child.children?.length > 0) {
                        // tem filho
                        return (
                            <SubMenu
                                key={"menu_" + child.idmenu}
                                onTitleClick={() => setchildrenToMainMenu({ ...child }, child.children!!)}
                                title={
                                    <>
                                        <Icon type="folder" />
                                        <span>{child.titulo}</span>
                                    </>
                                }
                            >
                                {/* {renderMenus(child.children)} */}
                            </SubMenu>
                        );
                    } else {
                        // não tem filho

                        // Verificar se não há nenhuma ação para a rota
                        const nameAction = Utilitaries.toString(
                            typeof child.webaccao == "string" ? child.webaccao : child.webtipo
                        ) as string;
                        // @ts-ignore
                        let _action = qaActionFromDBRoutes[nameAction];

                        if (_action) {
                            return (
                                <Menu.Item
                                    key={"menu_" + child.idmenu}
                                    onClick={() => _action(child.webaccao)}
                                    title={child.titulo}
                                >
                                    <Tooltip title={child.titulo}>
                                        <Icon type="unordered-list" />
                                        <span>{child.titulo}</span>
                                    </Tooltip>
                                </Menu.Item>
                            );
                        }

                        // Obter rota da ação
                        // @ts-ignore
                        let _router = qaRoutesFromDB[child.webaccao];

                        if (_router) {
                            let path = _router.path;

                            if (Utilitaries.toString(path).endsWith(":id?")) {
                                path = path.replace(":id?", "");
                            }

                            return (
                                <Menu.Item key={"menu_" + child.idmenu} title={child.titulo}>
                                    <Link to={path}>
                                        <Tooltip title={child.titulo}>
                                            <Icon type="unordered-list" />
                                            <span>{child.titulo}</span>
                                        </Tooltip>
                                    </Link>
                                </Menu.Item>
                            );
                        } else {
                            // verificar se a rota não é um formulario
                            if (child.webtipo === "FORMULARIO") {
                                if (openInFormV2(child.webaccao.toString())) {
                                    return (
                                        <Menu.Item key={"menu_" + child.idmenu} title={child.titulo}>
                                            <Link to={`/f2/${child.webaccao}`}>
                                                <Tooltip title={child.titulo}>
                                                    <Icon type="unordered-list" />
                                                    <span>{child.titulo}</span>
                                                </Tooltip>
                                            </Link>
                                        </Menu.Item>
                                    );
                                } else {
                                    return (
                                        <Menu.Item key={"menu_" + child.idmenu} title={child.titulo}>
                                            <Link to={`/f/${child.webaccao}`}>
                                                <Tooltip title={child.titulo}>
                                                    <Icon type="unordered-list" />
                                                    <span>{child.titulo}</span>
                                                </Tooltip>
                                            </Link>
                                        </Menu.Item>
                                    );
                                }
                            } else if (child.webtipo === "PROCESSO_CLIENTE") {
                                return (
                                    <Menu.Item
                                        key={"menu_" + child.idmenu}
                                        onClick={() => executeTask(child.webaccao)}
                                        title={child.titulo}
                                    >
                                        <Tooltip title={child.titulo}>
                                            <Icon type="unordered-list" />
                                            <span>{child.titulo}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                );
                            } else if (child.webtipo === "BACKUP") {
                                return (
                                    <Menu.Item
                                        key={"menu_" + child.idmenu}
                                        onClick={() => makeBackup()}
                                        title={child.titulo}
                                    >
                                        <Tooltip title={child.titulo}>
                                            <Icon type="unordered-list" />
                                            <span>{child.titulo}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                );
                            } else if (child.webtipo === "OPENCOLORPALETTE") {
                                return (
                                    <Menu.Item
                                        key={"menu_" + child.idmenu}
                                        onClick={() => showColorPalette()}
                                        title={child.titulo}
                                    >
                                        <Tooltip title={child.titulo}>
                                            <Icon type="unordered-list" />
                                            <span>{child.titulo}</span>
                                        </Tooltip>
                                    </Menu.Item>
                                );
                            } else if (child.webtipo === "ALUGUER") {
                                return (
                                    <Menu.Item key={"menu_" + child.idmenu} title={child.titulo}>
                                        <Link to="/ALUGUER">
                                            <Tooltip title={child.titulo}>
                                                <Icon type="unordered-list" />
                                                <span>{child.titulo}</span>
                                            </Tooltip>
                                        </Link>
                                    </Menu.Item>
                                );
                            }

                            const menuTypeListToDisableActions = {
                                father: 416,
                                menus: ["parametrizacao_ficha_cliente", "qa_parcerias_entidades", "socios"],
                            };

                            // verificar se a rota é uma lista
                            if (child.webtipo === "LISTA") {
                                let router = `/l/${child.webaccao}`;

                                if (
                                    Utilitaries.toString(child.pai) === menuTypeListToDisableActions.father &&
                                    menuTypeListToDisableActions.menus.includes(Utilitaries.toString(child.webaccao))
                                ) {
                                    router = `${router}?action=false`;
                                }

                                return (
                                    <Menu.Item key={"menu_" + child.idmenu} title={child.titulo}>
                                        <Link to={router}>
                                            <Tooltip title={child.titulo}>
                                                <Icon type="unordered-list" />
                                                <span>{child.titulo}</span>
                                            </Tooltip>
                                        </Link>
                                    </Menu.Item>
                                );
                            }

                            return (
                                <Menu.Item key={"menu_" + child.idmenu} title={child.titulo}>
                                    <Link to={"/" + child.webaccao}>
                                        <Tooltip title={child.titulo}>
                                            <Icon type="unordered-list" />
                                            <span>{child.titulo}</span>
                                        </Tooltip>
                                    </Link>
                                </Menu.Item>
                            );
                        }
                    }
                })
        );
    };

    if (Utilitaries.toArray(menuTree).length === 0) {
        return null;
    }

    return (
        <>
            <Menu
                className="qa_menu_left"
                theme="dark"
                inlineCollapsed={false}
                mode="inline"
                style={{
                    overflowY: "auto",
                    height: "calc(100vh - 106px)",
                }}
            >
                {stackMenu.length > 0 && menuType !== "MENU_DIREITA" && (
                    <li
                        className="ant-menu-item"
                        role="menuitem"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <span></span>
                        <Icon type="up" onClick={() => changeMenuType()} />
                    </li>
                )}

                {menuType === "MENU_DIREITA" && (
                    <li
                        className="ant-menu-item"
                        role="menuitem"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <span>
                            {defaultListOfMenus.find((item) => item.identificador === "qa_definicoes")?.titulo ||
                                "Definições"}
                        </span>
                        {stackMenu.length > 0 && <Icon type="up" onClick={() => changeMenuType()} />}
                    </li>
                )}

                {menuType !== "MENU_DIREITA" && Utilitaries.toArray(recentsProcesses).length > 0 && (
                    <SubMenu
                        onTitleClick={() => setShowRecents(!showRecents)}
                        title={
                            <span>
                                <Icon type="history" />
                                <span>Recentes</span>
                            </span>
                        }
                    ></SubMenu>
                )}

                {showRecents
                    ? recentsProcesses.map((child, index) => (
                          <Menu.Item key={"recente-" + index} onClick={() => handleClickProcessRecents(child.nome)}>
                              <Icon type="unordered-list" />
                              <Tooltip title={child.nome}>
                                  <span> {child.nome}</span>
                              </Tooltip>
                          </Menu.Item>
                      ))
                    : renderMenus(listOfMenus)}
            </Menu>
            {!Session.isPortalOrClient() && (
                <div
                    style={{
                        height: "40px",
                        background: "#002140",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Button
                        id="btn-theme"
                        title="Abrir menu de definições"
                        type="link"
                        icon="setting"
                        style={{ color: "white" }}
                        onClick={() => {
                            setMenuType(menuType === "MENU_DIREITA" ? "ALL" : "MENU_DIREITA");
                        }}
                    >
                        {defaultListOfMenus.find((item) => item.identificador === "qa_definicoes")?.titulo ||
                            "Definições"}
                    </Button>
                </div>
            )}
        </>
    );
}
