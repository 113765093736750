import { Button, message, Upload } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Utilitaries } from "../../../../business";
import { downloadFile } from "../../../../components/util";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { HandleOnInputChangeValueProps, InputInTableProps } from "../../../types/common";
import {
    FormInputCalcTriggerProps,
    FormInputsObjectProps,
    FormInputsProps,
    RenderContextType,
} from "../../../types/formTypes";
import { api_fetch } from "../../../utils/HttpRequest/request";
import { FormLabel, getLabel } from "../Components/FormLabel";
import { Group } from "../Components/Group";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";

// import { nullSafe, canCorrectField } from "../../utils/fields";

interface ComponentsPropertiesProps {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
    formState: FormInputsObjectProps;
    handleOnInputChangeValue: (props: HandleOnInputChangeValueProps) => void;
    tableRow?: InputInTableProps;
    inputCalcTrigger: FormInputCalcTriggerProps;
    justSee?: boolean;
    triedSave: boolean;
}

const keyLoading = "uploadkey";

const _QAUpload: React.FC<ComponentsPropertiesProps> = ({
    // itemData, label, formItemStyle, onChange, correctForm, currentTaskStatus, readonly
    formState,
    componentsProperties,
    renderContext,
    handleOnInputChangeValue,
    tableRow,
    inputCalcTrigger,
    justSee,
    triedSave,
}) => {
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    print_when_component_is_rendered(componentsProperties);

    //
    const [fileList, setFileList] = useState([] as any[]);
    const [loadingDownload, setLoadingDownload] = useState(false);

    useEffect(() => setShowError(triedSave), [triedSave]);

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);
    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    // function getBase64(img: any, callback: Function) {
    //     const reader = new FileReader();
    //     reader.addEventListener("load", () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // }

    const uploadFileToServer = async (file: any) => {
        const data = {
            accao: "upload",
            nome: componentsProperties.key === "instituicao_imagem_canto" ? "logo_instituicao.jpg" : file.name,
            descricao: file.type,
            ficheiro: file,
            chave_sessao: "qa",
        };
        try {
            setLoading(true);
            message.loading({ content: "A carregar ficheiro...", key: keyLoading });

            const { response } = await api_fetch({
                endPoint: `Gestor/gereficheiros.php`,
                method: "POST",
                params: {
                    ...data,
                },
            });

            if (response.sucesso && response.sucesso !== "0") {
                handleOnInputChangeValue({
                    value: response.sucesso,
                    componentsProperties,
                    row_key: tableRow?.row_key,
                    full_path,
                });
                //odigo por verificar no formulario de instituição
                // if (field === "instituicao_imagem_canto") {
                //     //     getLogo(response.sucesso);
                //     // }
                message.success({ content: "Ficheiro carregado", key: keyLoading });
            } else {
                message.error("Erro ao gravar ficheiro");
            }
        } catch (error) {
            message.error("Ocorreu um erro");
        } finally {
            setLoading(false);
        }
    };

    const props = {
        onRemove: () => {
            handleOnInputChangeValue({
                value: "",
                componentsProperties,
                row_key: tableRow?.row_key,
                full_path,
            });
            setFileList([]);
        },
        beforeUpload: (file: any) => {
            return uploadFileToServer(file);
        },
        // beforeUpload: (file: any) => {
        //     getBase64(file, (img: any) => {
        //         handleOnInputChangeValue({
        //             value: `${img}@@${file.name}`,
        //             componentsProperties,
        //             row_key: tableRow?.row_key,
        //         });
        //     });
        //     setFileList([file]);
        //     return false;
        // },~

        fileList,
    };

    const renderDownloadButton = () => {
        let value =
            renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;
        value = Utilitaries.toString(value);
        if (value && !value.includes("@@")) {
            return (
                <>
                    <Button
                        type="link"
                        loading={loadingDownload}
                        onClick={(e) => {
                            setLoadingDownload(true);
                            downloadFile("/geredocumentos.php", {
                                accao: "ficheiro",
                                ficheiro: value,
                            }).then(() => {
                                setLoadingDownload(false);
                            });
                        }}
                    >
                        Descarregar
                    </Button>
                </>
            );
        } else {
            return null;
        }
    };

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const { required, visible, relevant, readOnly, error } = useInputHandle({
        componentProperties: componentsProperties,
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        value,
        showError,
    });
    const { label } = getLabel({ componentsProperties, renderContext, required });

    // const canEdit = canCorrectField(itemData) && correctForm;

    // const disabled = canEdit ? false : currentTaskStatus || readonly;
    if (!visible || !relevant) {
        return null;
    }

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                {!justSee && !readOnly && (
                    <Upload {...props} disabled={loading}>
                        <Button icon="upload" disabled={loading}>
                            Clique para carregar
                        </Button>
                    </Upload>
                )}

                {renderDownloadButton()}
                {showError && error && <span style={{ color: "red" }}>{error}</span>}
            </FormLabel>
        </Group>
    );
};

export const QAUpload = memo(_QAUpload, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
