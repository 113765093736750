import moment from "moment";
import { ContextType, FormInputsProps, InputGroupPathType, XMLFormInputProps } from "../../types/formTypes";
import { INPUTS_TO_DISPLAY_WITH_LABEL, mapXMLInputAttributes } from "./formAttributes";
import {
    COMPONENT_TYPE,
    GRAPHIC_ELEMENT,
    XML_INPUT_TYPE,
    XML_ORIGINAL_COMPONENT_TYPE,
    XML_ORIGINAL_GRAPHIC_ELEMENT,
} from "./XMLInputType";

const getEtiqueta = (item: XMLFormInputProps) => {
    let etiqueta = "";
    for (const key in item) {
        if (item.label) {
            etiqueta = item.label;
            break;
        } else if (typeof item[key] === "object") {
            etiqueta = getEtiqueta(item[key] as XMLFormInputProps);
        } else {
            continue;
        }
    }

    if (item.label !== "" && !etiqueta && item?.key) {
        etiqueta = item.key.replace(/_/g, " ");
        etiqueta = etiqueta.charAt(0).toUpperCase() + etiqueta.slice(1);
    }

    if (etiqueta !== "") {
        if (Object.keys(item).length <= 7) {
            if (item.key === "cliente_nome") {
                etiqueta = "Cliente";
            } else if (item.key === "estabelecimento_nome") {
                etiqueta = "Estabelecimento";
            } else if (item.key === "valencia_designacao") {
                etiqueta = "Unidade orgânica";
            }
        }
    }

    return etiqueta;
};

const isSpace = (item: FormInputsProps) => {
    if (!item?.key?.includes("espaco")) {
        return false;
    }

    const objectsInsideItem = Object.values(item)
        .filter((values) => typeof values === "object" && !Array.isArray(values))
        .filter((prop: FormInputsProps) => prop.persist === "nao");

    return objectsInsideItem.length > 0;
};

// const setTypeAnalyzingProperty = (item: XMLFormInputProps) => {
//     if (item[XML_INPUT_TYPE.TYPE] === COMPONENT_TYPE.DATAGRID) {
//         const fields = Object.keys(item).filter(
//             (field) => typeof item[field] === "object" && !Array.isArray(item[field])
//         );

//         let isDynamicDataGrid = fields.filter((field) =>
//             ["botoes_seleccao"].includes(item[field][XML_INPUT_TYPE.GRAPHIC_ELEMENT])
//         );

//         if (isDynamicDataGrid && isDynamicDataGrid.length > 0) {
//             item[XML_INPUT_TYPE.TYPE] = COMPONENT_TYPE.DYNAMIC_DATAGRID;
//             fields.forEach((field) => {
//                 item[field] = addItemProps({
//                     item: item[field],
//                     context: "column",
//                     colKey: field,
//                     root: item.key,
//                     input_group_path: [],
//                 });
//             });
//             return item;
//         }
//     }

//     return null;
// };

interface TraverseDeepChildsProps {
    item: XMLFormInputProps;
    root: string;
}
interface ChangeInnerChildsPropsProps {
    item: XMLFormInputProps;
    context: ContextType;
}

const traverseDeepChilds = ({ item, root }: TraverseDeepChildsProps) => {
    const changeInnerChildsProps = ({ item, context }: ChangeInnerChildsPropsProps) => {
        for (const itemKey in item) {
            if (typeof item[itemKey] === "object" && itemKey !== "DATA_SOURCE" && itemKey !== "input_group_path") {
                if (item.type !== COMPONENT_TYPE.DYNAMIC_TABLE && item.type !== COMPONENT_TYPE.SELECTION_TABLE) {
                    item[itemKey] = addItemProps({
                        item: { ...item[itemKey], form_key: item.form_key },
                        context,
                        colKey: itemKey,
                        table_name: itemKey,
                        input_group_path: item.input_group_path || [],
                    });
                } else {
                    for (const colKey in item[itemKey]) {
                        if (typeof item[itemKey][colKey] === "object") {
                            item[itemKey][colKey] = addItemProps({
                                item: { ...item[itemKey][colKey], dbTableName: itemKey, form_key: item.form_key },
                                context,
                                colKey,
                                root,
                                table_name: root,
                                input_group_path: item.input_group_path || [],
                            });
                        }
                    }
                }
            }
        }
    };

    const firstLevelItems: XMLFormInputProps[] = Object.values(
        Object.values(item).filter(
            (firstLevelItem) => typeof firstLevelItem === "object" && !Array.isArray(firstLevelItem)
        )
    )[0];

    const secondLevelItems: XMLFormInputProps[] = Object.values(firstLevelItems).filter(
        (secondLevelItem) => typeof secondLevelItem === "object" && !Array.isArray(secondLevelItem)
    );
    /**
     * * counting number of obj childs and setting new type
     */

    if (secondLevelItems.length === 1 && !Object.keys(item).find((it) => it.startsWith("@accao"))) {
        // if (item.key === "dests_inq_v2") ;
        if (
            secondLevelItems[0]["@tipo"] &&
            secondLevelItems[0]["@tipo"] !== COMPONENT_TYPE.DYNAMIC_LIST &&
            secondLevelItems[0]["@tipo"] !== COMPONENT_TYPE.INPUT_AREA &&
            secondLevelItems[0]["@tipo"] !== COMPONENT_TYPE.DATE
        ) {
            //TODO: nao verificar se nao tem tipo porque componentes to tipo input nem sempre tem tipo
            //TODO: verificar para os outros tipos de campo
            item["@tipo"] = COMPONENT_TYPE.STATIC_LIST;
        }

        changeInnerChildsProps({ item, context: "column" });
        if (!item["@etiqueta"]) {
            item["@etiqueta"] = secondLevelItems[0]["@etiqueta"] || item?.key?.replace(/_/g, " "); // capitalize(item.key.replace(/_/g, " "));
        }
        item["@placeholder"] = `Selecione uma opção`;
    } else {
        /**
         * * check and add type in columns
         */
        changeInnerChildsProps({ item, context: "column" });
    }
};

interface SetItemPropProps {
    item: FormInputsProps;
    context: ContextType;
    root?: string;
    colKey?: string;
    input_group_path?: InputGroupPathType;
    table_name?: string;
}

const setItemDefaultValue = (item: FormInputsProps) => {
    if ("default_value" in item) {
        if (item?.default_value?.startsWith("{$param.")) {
            item.value = "";
        } else if (item.default_value === "{now()}") {
            item.value = moment().format("YYYY-MM-DD");
        } else if (item.default_value === "{now_year()}") {
            item.value = moment().format("YYYY");
        } else {
            item.default_value = item.default_value === "false" ? "f" : item.default_value;
            item.default_value = item.default_value === "true" ? "t" : item.default_value;
            item.value = item.default_value;
        }
    }

    if (item.value === null || item.value === undefined) {
        item.value = "";
    }
    return item;
};

const itemsTypeDataDisplay = (item: FormInputsProps) => {
    if (INPUTS_TO_DISPLAY_WITH_LABEL.includes(item?.key || "") && Object.keys(item).length === 2) {
        item.type = COMPONENT_TYPE.DATA_DISPLAY;
    }

    return item;
};

const addItemProps = ({ item, context, root, colKey, input_group_path, table_name }: SetItemPropProps) => {
    item = setItemDefaultValue(item);

    item = itemsTypeDataDisplay(item);

    if (context === "column") {
        item.key = colKey!!;
        item.isColumn = true;
        item.root = root;
        item.input_group_path = input_group_path;
    } else {
        if (item[XML_INPUT_TYPE.GRAPHIC_ELEMENT] === GRAPHIC_ELEMENT.TITLE && !item.type) {
            item.type = COMPONENT_TYPE.DATA_DISPLAY;
        }
    }

    if (!item?.label) {
        item.label = getEtiqueta(item);
    } else {
        item.label = item.label.trim();
    }

    const is_input_group =
        Object.keys(item).filter(
            (it) => !it.startsWith("@") && typeof item[it] === "object" && !Array.isArray(item[it])
        ).length > 0;

    // const itenmWIthDynamicType = setTypeAnalyzingProperty(item);

    switch (item.type) {
        case XML_ORIGINAL_COMPONENT_TYPE.SUGESTION:
            item.type = COMPONENT_TYPE.SUGESTION;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.DATE:
            item.type = COMPONENT_TYPE.DATE;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.DIVIDER:
            item.type = COMPONENT_TYPE.DIVIDER;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.DYNAMIC_LIST:
            item.type = COMPONENT_TYPE.DYNAMIC_LIST;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.STATIC_LIST:
            item.type = COMPONENT_TYPE.STATIC_LIST;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.CONJUNTO:
            if (item.data_query && !item.transaction_buttons) {
                item.type = COMPONENT_TYPE.SELECTION_TABLE;
                traverseDeepChilds({ item, root: item.key });
            } else {
                item.type = COMPONENT_TYPE.DYNAMIC_TABLE;
                traverseDeepChilds({ item, root: item.key });
            }
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.BOOLEAN:
            if (item.values && item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.selection_button) {
                item.type = COMPONENT_TYPE.STATIC_LIST;
            } else {
                item.type = COMPONENT_TYPE.BOOLEAN;
            }
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.BOOL:
            item.type = COMPONENT_TYPE.BOOLEAN;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.DATAGRID:
            if (item?.show_buttons_add_remove === "sim") {
                item.type = COMPONENT_TYPE.DYNAMIC_DATAGRID;
                traverseDeepChilds({ item, root: item.key });
            } else {
                item.type = COMPONENT_TYPE.DATAGRID;
                traverseDeepChilds({ item, root: item.key });
            }
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.INTEGER:
            if (item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.list_choice_sinfle && item.values) {
                item.type = COMPONENT_TYPE.STATIC_LIST;
            } else if (
                item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.list_choice_sinfle ||
                (item.key_list && item.value_list && item.data_query)
            ) {
                item.type = COMPONENT_TYPE.SELECT;
            } else if (item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.label) {
                item.type = COMPONENT_TYPE.DATA_DISPLAY;
            } else {
                item.type = COMPONENT_TYPE.INPUT_NUMBER;
            }
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.INT:
            item.type = COMPONENT_TYPE.INPUT_NUMBER;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.ALERT:
            item.type = COMPONENT_TYPE.ALERT;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.SHORT_TEXT:
            item.type = COMPONENT_TYPE.INPUT_AREA;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.TEXT:
            item.type = COMPONENT_TYPE.INPUT_AREA;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.AREA_TEXTO:
            item.type = COMPONENT_TYPE.INPUT_AREA;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.ICON:
            item.type = COMPONENT_TYPE.BUTTON;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.LINK_TO_PROCESS:
            item.type = COMPONENT_TYPE.LINK_TO_PROCESS;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.REPORT:
            item.type = COMPONENT_TYPE.REPORT;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.FLOAT:
            if (item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.label) {
                item.type = COMPONENT_TYPE.DATA_DISPLAY;
            } else {
                item.type = COMPONENT_TYPE.INPUT_NUMBER;
            }
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.STRING:
            item.type = COMPONENT_TYPE.INPUT_AREA;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.COMBOBOX:
            item.type = COMPONENT_TYPE.SELECT;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.UPLOAD:
        case XML_ORIGINAL_COMPONENT_TYPE.FILE:
        case XML_ORIGINAL_COMPONENT_TYPE.IMAGE:
            item.type = COMPONENT_TYPE.UPLOAD;
            break;

        case XML_ORIGINAL_COMPONENT_TYPE.LINK:
            item.type = COMPONENT_TYPE.LINK;
            break;

        default:
            if (is_input_group) {
                item.type = COMPONENT_TYPE.INPUT_GROUP;
                const formInputsKey = Object.keys(item).filter(
                    (it) => typeof item[it] === "object" && !Array.isArray(item[it])
                );
                formInputsKey.forEach((key) => {
                    const current_input_group_path = input_group_path ? [...input_group_path, item.key] : [item.key];
                    item[key] = addItemProps({
                        item: { ...item[key], form_key: item.form_key },
                        context: "column",
                        colKey: key,
                        root: item.key,
                        input_group_path: current_input_group_path,
                    });
                });
            } else if (item.mask === "hora") {
                item.type = COMPONENT_TYPE.INPUT_TIME;
            } else if (item.mask === "inteiro") {
                item.type = COMPONENT_TYPE.INPUT_NUMBER;
            } else if (item.graphic_element === "botao") {
                item.type = COMPONENT_TYPE.BUTTON;
            } else if (item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.label) {
                item.type = COMPONENT_TYPE.DATA_DISPLAY;
            } else if (item.key_list && item.value_list && item.data_query) {
                item.type = COMPONENT_TYPE.SELECT;
            } else if (item.type === COMPONENT_TYPE.POPUP) {
                item.type = COMPONENT_TYPE.POPUP;
            } else if (item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.list_choice_sinfle) {
                item.type = COMPONENT_TYPE.SELECT;
            } else if (item.graphic_element === XML_ORIGINAL_GRAPHIC_ELEMENT.selection_button) {
                item.type = COMPONENT_TYPE.STATIC_LIST;
            } else {
                item.type = COMPONENT_TYPE.INPUT;
                // if (item.style?.includes("nif")) {
                //     // item.type = COMPONENT_TYPE.INPUT_NUMBER;
                //     // item.type = COMPONENT_TYPE.INPUT;
                // } else {
                //     item.type = COMPONENT_TYPE.INPUT;
                // }
            }
            break;
    }

    if (item.input_group_path && item.input_group_path?.length > 0) {
        item.full_path =
            item.input_group_path?.join("/") + "/" + (table_name ? "table@" + table_name + "/" : "") + item.key;
    } else {
        item.full_path = (table_name ? "table@" + table_name + "/" : "") + item.key;
    }

    return {
        ...mapXMLInputAttributes(item),
    };
};

export function addFormInputTypes(formInputs: XMLFormInputProps[]) {
    const formInputsWithAttribbutes: FormInputsProps[] = formInputs.map((item) => {
        let itemMapped: FormInputsProps = { ...mapXMLInputAttributes(item) };
        if (isSpace(itemMapped)) {
            itemMapped.visible = "nao";
            return {
                ...itemMapped,
            };
        }
        const it = addItemProps({ item: itemMapped, context: "form" });

        // dataSourceTable convert array to object
        if (it.table) {
            for (var key in it) {
                var auxItem = it?.[key];
                if (Array.isArray(auxItem)) {
                    for (var i = 0; i < auxItem.length; ++i) {
                        it[key] = Object.assign({ ...auxItem[i] });
                    }
                }
            }
        }
        return it;
    });

    return formInputsWithAttribbutes;
}
