import React, { Component } from "react";
import {
    Layout,
    Menu,
    Badge,
    Radio,
    Icon,
    Drawer,
    Button,
    Dropdown,
    Avatar,
    Skeleton,
    notification,
    Modal,
} from "antd";
import { Route, Link, Redirect } from "react-router-dom";
import { qaRoutesFromDB, qaRoutesLocal } from "./routes";
import { message, Row, Col } from "antd";
import ColorPicker from "./ColorPicker/index";
import "./index.less";
import {
    getMenus,
    setRoutes,
    getRemember,
    getContributorsByFuncionalGroup,
    getLembreteVisualization,
    getBadgeResume,
    changeTheme,
    changeThemeProperty,
    getAuthorizations,
    getThemeProperties,
} from "../actions/index";
import {
    getDefinedEvents,
    getEventsPermission,
    getEventsPermissionGroups,
    getEventsPlaces,
} from "./Schedule/actions/index";
import { changeForumModalState, changeForumConfModalState } from "./Forum/actions/index";
import { changeChatModalState, changeChatConfModalState } from "./Chat/actions/index";
import { changeUpdateModalState } from "./Update/actions/index";
import { select as getFunctionalGroups } from "../modules/function_manual/actions/funcional.group";
import { selectAllContributors as getAllContributors } from "../modules/fixed_list/actions/index";
import { getRooms } from "./Chat/actions/index";

import { connect } from "react-redux";

import AlertComponent from "./app.alert";
import MessageComponent from "./Message";
import ScheduleComponent from "./Schedule";
import LembrateComponent from "./Remember/index";
import ForumComponent from "./Forum/Forum";
import ChatComponent from "./Chat/index";
import ChatConfComponent from "./Chat/ChatConf";
import ForumConfConponent from "./Forum/ForumConf";
import UpdateConponent from "./Update/index";
//import QAMenu from "./app.menu.left";
//import QAMenuSettings from "./TopQuickAccessMenu/app.setting.icon";

import AppContext from "./AppContext";
import { baseUrl, IN_DEVELOPMENT } from "../constants/enviroment";
import { sendHttpRequest } from "./http.request";
import { Session, Theme, Utilitaries } from "../business";
import { canRenderMenu, toArray } from "./util";
import EditUserInfo from "../modules/user/components/edit-user";
import ChangePassword from "../modules/user/components/changePassword";
import { SideBarMenu } from "./SideBarMenu/index.tsx";
import { TopQuickAccessMenu } from "./TopQuickAccessMenu/index.tsx";
import { Calulator } from "./Calculator/index.tsx";
import ReportBuilderIndex from "../modules/reports/components/index";
import { UserProvider } from "../V2/hooks/user";

const { Header, Content, Footer, Sider } = Layout;

// const ThemeContext = React.createContext("light");

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
};

class AppLayout extends Component {
    constructor(props) {
        super(props);

        let initialValue = {
            "@primary-color": { value: "#1DA57A", text: "Botões" },
            // "@secondary-color": { value: "#000001", text: "" },
            "@text-color": { value: "fade(#000000, 65%)", text: "Textos" },
            "@text-color-secondary": { value: "fade(#1da57b, 65%)", text: "Textos Secundários" },
            "@heading-color": { value: "fade(#000, 85%)", text: "Títulos" },
            "@layout-header-background": { value: "#000001", text: "Menu Lateral" },
            "@layout-sider-background": { value: "#000001", text: "Área Logo" },
            // "@btn-primary-bg": { value: "#397dcc", text: "sdsdsd" },
            "@processing-color": { value: "fade(@primary-color, 1%)", text: "Focus" },
        };

        let vars = {};

        try {
            // vars = Object.assign({}, initialValue, JSON.parse(localStorage.getItem("app-them1e")));
            // vars = Object.assign({}, initialValue, JSON.parse(Theme.get()));
            vars = Object.assign({}, initialValue);
        } finally {
            this.state = {
                vars,
                initialValue,
                breakPointActive: false,
                sidebarOpen: false,
                isDrawerVisible: false,
                isFetching: false,
                listOfMenus: [],
                routes: [],
                isAlertVisible: false,
                isLembreteVisible: false,
                isMessageVisible: false,
                isScheduleVisible: false,
                loadingContributorsByFuncionalGroup: false,
                mainLoading: true,
                logo: null,
                isVisible: false,
                foto_perfil: undefined,
                showModalUpdates: true,
                getUsersComunicationInterval: 2,
                themePermissions: [],
                themeSelected: null,
                themeId: null,
                allUser: "n",
                changePassword: Session.isFirstLogin() || false,
                isCalculatorVisible: false,
                isVisibelGenerateReport: false,
            };
        }

        this.getLogo = this.getLogo.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this._getDataFromAPI();
        if (!Session.isPortal() && !Session.isClient()) {
            this.props.getDefinedEvents();

            const hasNewEvents = async () => {
                const oldStatistics = { ...this.props.badge_resume };
                const newStatistics = await this.props.getBadgeResume();

                return {
                    newRemembers: newStatistics.lembretes - oldStatistics.lembretes,
                    newMessages: newStatistics.mensagens - oldStatistics.mensagens,
                    newEvents: newStatistics.eventos - oldStatistics.eventos,
                    notifications: newStatistics.notificacoes,
                };
            };

            // check for new messages, shedules, alerts and remembers
            const getComunicacionNotif = () => {
                this.interval = setInterval(async () => {
                    if (!Session.isLockedScreen()) {
                        const statistics = await hasNewEvents();
                        this.showCommunicationNotification(statistics);
                    }
                }, this.state.getUsersComunicationInterval * 60 * 1000);
            };

            getComunicacionNotif();

            this.props.getAuthorizations().then((resp) => {
                const themeRelatedPermissions = Object.values(resp.linha).filter((el) =>
                    ["paleta_cores_admin", "paleta_cores_user"].includes(el.descricao)
                );
                this.setState({ themePermissions: themeRelatedPermissions });
            });

            this.props.getThemeProperties("").then((resp) => {
                if (resp?.linha) {
                    const { botoes, area_logo, focus, id, tipo, titulo, textos, textos_sec, menu_lateral } =
                        resp?.linha;
                    const themeVars = {
                        "@primary-color": { value: "#" + botoes, text: "Botões" },
                        "@text-color": { value: "#" + textos, text: "Textos" },
                        "@text-color-secondary": { value: "#" + textos_sec, text: "Textos Secundários" },
                        "@heading-color": { value: "#" + titulo, text: "Títulos" },
                        "@layout-header-background": { value: "#" + area_logo, text: "Menu Lateral" },
                        "@layout-sider-background": { value: "#" + menu_lateral, text: "Área Logo" },
                        "@processing-color": { value: "#" + focus, text: "Focus" },
                    };

                    Theme.save(themeVars);
                    this.setState({ vars: themeVars });

                    const currentColor = {
                        ...JSON.parse(Theme.get()),
                        ...Object.keys(themeVars).reduce((acum, curr) => {
                            acum[curr] = themeVars[curr]?.value;
                            return acum;
                        }, {}),
                    };

                    if (window?.less?.modifyVars)
                        window.less
                            .modifyVars(currentColor)
                            .then((resp) => {})
                            .catch((err) => () => {});
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    showCommunicationNotification = ({ newEvents, newMessages, newRemembers, notifications }) => {
        let key = `open${Date.now()}`;
        if (newRemembers) {
            key = `${key}_lembretes_${newRemembers}`;
            notification.open({
                message: "Lembretes",
                description: (
                    <div>
                        <b>{newRemembers}</b> lembrete(s) novo(s). <br /> <small>Click para ver</small>
                    </div>
                ),
                key,
                onClick: () => {
                    this.setState({
                        isLembreteVisible: true,
                    });
                    notification.close(key);
                },
                style: { cursor: "pointer" },
            });
        }

        if (newMessages) {
            key = `${key}_mensagens_${newMessages}`;
            notification.open({
                message: "Mensagens",
                description: (
                    <div>
                        <b>{newMessages}</b> mensagen(s) novo(s). <br /> <small>Click para ver</small>
                    </div>
                ),
                key,
                onClick: () => {
                    this.setState({
                        isMessageVisible: true,
                    });
                    notification.close(key);
                },
                style: { cursor: "pointer" },
            });
        }

        if (newEvents) {
            key = `${key}_Eventos_${newEvents}`;
            notification.open({
                message: "Eventos",
                description: (
                    <div>
                        <b>{newEvents}</b> evento(s) novo(s). <br /> <small>Click para ver</small>
                    </div>
                ),
                key,
                onClick: () => {
                    this.setState({
                        isScheduleVisible: true,
                    });
                    notification.close(key);
                },
                style: { cursor: "pointer" },
            });
        }

        if (toArray(notifications).length > 0) {
            toArray(notifications).forEach((notif, index) => {
                key = `${key}_Eventos_${notif.id}_${index}`;
                notification.open({
                    message: notif.title,
                    description: notif.message,
                    key,
                    duration: 0,
                    onClick: () => {
                        this.setState({
                            isScheduleVisible: true,
                        });
                        notification.close(key);
                    },
                    style: { cursor: "pointer" },
                });
            });
        }
    };

    handleColorChange = (varname, color) => {
        const { vars } = this.state;
        if (varname) vars[varname].value = color;

        const result = {
            ...JSON.parse(Theme.get()),
            ...Object.keys(vars).reduce((acum, curr) => {
                acum[curr] = vars[curr]?.value;
                return acum;
            }, {}),
        };

        const variablesMapBackendFrontend = {
            "@primary-color": "botoes",
            "@text-color": "textos",
            "@text-color-secondary": "textos_sec",
            "@heading-color": "titulo",
            "@layout-header-background": "area_logo",
            "@layout-sider-background": "menu_lateral",
            "@processing-color": "focus",
        };

        this.props
            .changeThemeProperty({
                themeId: this.state.themeId,
                propName: variablesMapBackendFrontend[varname],
                propValue: color,
                allUser: this.state.allUser,
            })
            .then((themeIdResp) => {
                window.less
                    .modifyVars(result)
                    .then((resp) => {
                        message.success(`Tema alterado com sucesso`);
                        this.setState({ vars, themeId: themeIdResp[0] });
                        Theme.save(vars);
                    })
                    .catch((error) => {
                        message.error("Ocorreu um erro");
                    });
            });
    };

    getColorPicker = (varName, position, vars) => {
        return (
            <Row className="color-row" key={varName}>
                <Col xs={4} className="color-palette">
                    <ColorPicker
                        type="sketch"
                        small
                        color={this.state.vars[varName].value}
                        position={position || "right"}
                        presetColors={[
                            "#F5222D",
                            "#FA541C",
                            "#FA8C16",
                            "#FAAD14",
                            "#FADB14",
                            "#A0D911",
                            "#52C41A",
                            "#13C2C2",
                            "#1890FF",
                            "#2F54EB",
                            "#722ED1",
                            "#EB2F96",
                        ]}
                        onChangeComplete={(color) => this.handleColorChange(varName, color)}
                    />
                </Col>
                <Col className="color-name" xs={20}>
                    {vars[varName]?.text}
                </Col>
            </Row>
        );
    };

    resetTheme = () => {
        Theme.save({});
        this.setState({ vars: { ...this.state.initialValue } });
        window.less.modifyVars(this.state.initialValue).catch((error) => {
            message.error("Ocorreu um erro");
        });
    };

    showDrawer = () => {
        this.setState({
            isDrawerVisible: true,
        });
    };

    onClose = () => {
        this.setState({
            isDrawerVisible: false,
        });
    };

    toggle = (trigger, value) => {
        if (trigger === "clickTrigger") {
            // false is open and true is close
            this.setState({
                sidebarOpen: !value,
            });
        }
    };

    paddingSectionLayout() {
        if ((this.state.breakPointActive && this.state.sidebarOpen) || !this.state.breakPointActive) return 200;
        else return 0;
    }

    onBreakPoint = (broken) => {
        this.setState({
            breakPointActive: broken,
        });
    };

    _getDataFromAPI = async () => {
        try {
            this.setState({ isFetching: true });

            this.getLogo();

            await this.props.getMenus({ accao: "get-menus" });
            this.setState({ isFetching: false });

            if (!IN_DEVELOPMENT) {
                this.props.getRemember({ accao: "lista-all" });
            }

            if (!Session.isPortalOrClient()) {
                await this.props.getFunctionalGroups({
                    accao: "funcoes",
                    todos_utilizadores: true,
                });
                await this.props.getAllContributors({
                    accao: "lista_funcionarios",
                    lista: 0,
                    status: 1,
                });
                await this.props.getEventsPermission();
                await this.props.getEventsPermissionGroups();
                await this.props.getEventsPlaces();
                const statistics = await this.props.getBadgeResume();
                this.showCommunicationNotification({
                    newRemembers: statistics.lembretes,
                    newMessages: statistics.mensagens,
                    newEvents: statistics.eventos,
                });
            }

            this.handleRoutes();

            this.canShowAlertAndLembretes();
        } catch (error) {
            // message.error("Erro ao aceder à base de dados");
        } finally {
        }
    };

    getLogo = () => {
        const fd = new FormData();
        fd.append("accao", "logo");
        var request = new XMLHttpRequest();
        request.open("POST", baseUrl + "/Gestor/geredocumentos.php", true);
        request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
        request.responseType = "blob";
        request.onload = function () {
            if (request.status === 200) {
                this.getBase64(request.response, (img) => {
                    this.setState({
                        logo: img,
                    });
                });
            }
        }.bind(this);
        request.send(fd);
    };

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    canShowAlertAndLembretes = () => {
        if (IN_DEVELOPMENT) {
            return;
        }

        let isLembreteVisible = false;

        if (this.props.lembretes.filter((item) => item.read === "f" && item.owner !== "t").length > 0) {
            isLembreteVisible = true;
        }

        this.setState({
            isLembreteVisible,
        });
    };

    // lista com acoes nas rotas
    handleRoutes = () => {
        const _routes = [];
        this.props.menus.forEach((menu) => {
            let route = menu.webaccao;
            if (typeof route === "string") {
                _routes.push(route);
            }
        });

        this.props.setRoutes(_routes);
    };

    contributorsByFucionalGroup = async (funcionalGroupId) => {
        try {
            this.setState({
                loadingContributorsByFuncionalGroup: true,
            });

            const moreParams =
                typeof funcionalGroupId === "object"
                    ? { ...funcionalGroupId }
                    : { id_funcional_group: funcionalGroupId };

            await this.props.getContributorsByFuncionalGroup({
                accao: "lista_funcionarios",
                lista: 0,
                status: 1,
                ...moreParams,
            });
        } catch (error) {
            message.error("Erro ao obter utilizadores do grupo funcional");
        } finally {
            this.setState({
                loadingContributorsByFuncionalGroup: false,
            });
        }
    };

    logout = async () => {
        const formData = new FormData();
        formData.append("accao", "logout");

        await sendHttpRequest("POST", "/Gestor/gereutilizador.php", formData);

        Session.remove();
        // window.location.href = `${getBaseName()}/login`;
        // this.props.history.replace(`/login`);
        // window.location.replace("/#/login");
        window.location.reload();
    };

    showEditUserInfoModel = () => {
        const { isVisible } = this.state;
        this.setState({ isVisible: !isVisible });
    };

    getProfilePhoto = (foto_perfil) => {
        this.setState({
            foto_perfil,
        });
    };

    handleAllUserChange = (value) => {
        this.setState({
            allUser: value,
        });
    };

    render() {
        const colorPickers = Object.keys(this.state.vars).map((varName, index) => {
            return this.getColorPicker(varName, index > 3 ? "top" : "right", this.state.vars);
        });

        const canShow = (key) => canRenderMenu(key, this.props.menus);

        const userFullName = Session.getName() || Session.getUsername();

        const HeaderIcons = (props) => (
            <>
                {canShow("OpenMenuAgenda") && (
                    <Badge className={`add-margin ${props.visibleClass}`} count={this.props.badge_resume.eventos}>
                        {props.showText ? (
                            <div>
                                <Link
                                    to="!#"
                                    className="setting-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            isScheduleVisible: true,
                                        });
                                    }}
                                >
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="calendar" />
                                        <span className="setting-item-title">Agenda</span>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <Button
                                icon="calendar"
                                type="link"
                                title="Agenda"
                                onClick={() =>
                                    this.setState({
                                        isScheduleVisible: true,
                                    })
                                }
                            />
                        )}
                    </Badge>
                )}

                {canShow("OpenMenuMensagens") && (
                    <Badge className={`add-margin ${props.visibleClass}`} count={this.props.badge_resume.mensagens}>
                        {props.showText ? (
                            <div>
                                <Link
                                    to="!#"
                                    className="setting-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            isMessageVisible: true,
                                        });
                                    }}
                                >
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="mail" />
                                        <span className="setting-item-title">Mensagens</span>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <Button
                                onClick={() =>
                                    this.setState({
                                        isMessageVisible: true,
                                    })
                                }
                                icon="mail"
                                type="link"
                                title="Mensagens"
                            />
                        )}
                    </Badge>
                )}

                {canShow("OpenLembretes") && !Session.isPortalOrClient() && (
                    <Badge className={`add-margin ${props.visibleClass}`} count={this.props.badge_resume.lembretes}>
                        {props.showText ? (
                            <div>
                                <Link
                                    to="!#"
                                    className="setting-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            isLembreteVisible: true,
                                        });
                                    }}
                                >
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="bell" />
                                        <span className="setting-item-title">Lembretes</span>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <Button
                                onClick={() =>
                                    this.setState({
                                        isLembreteVisible: true,
                                    })
                                }
                                icon="bell"
                                type="link"
                                title="Lembretes"
                            />
                        )}
                    </Badge>
                )}

                {canShow("OpenChat") && (
                    <Badge className={`add-margin ${props.visibleClass}`} count={0}>
                        {props.showText ? (
                            <div>
                                <Link
                                    to="!#"
                                    className="setting-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.changeChatModalState(true);
                                    }}
                                >
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="message" />
                                        <span className="setting-item-title">Chat</span>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <Button
                                onClick={() => this.props.changeChatModalState(true)}
                                icon="message"
                                type="link"
                                title="Chat"
                            />
                        )}
                    </Badge>
                )}

                {!Session.isPortalOrClient() && (
                    <Badge className={`add-margin ${props.visibleClass}`} count={0}>
                        {props.showText ? (
                            <div>
                                <Link
                                    to="!#"
                                    className="setting-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.changeForumModalState(true);
                                    }}
                                >
                                    <div className="setting-item">
                                        <Icon className="setting-item-icon" type="team" />
                                        <span className="setting-item-title">Fórum</span>
                                    </div>
                                </Link>
                            </div>
                        ) : (
                            <Button
                                onClick={() => this.props.changeForumModalState(true)}
                                icon="team"
                                type="link"
                                title="Fórum"
                            />
                        )}
                    </Badge>
                )}
            </>
        );

        const settingsMenu = (
            <Menu className="setting-root">
                <TopQuickAccessMenu
                    menus={this.props.menus}
                    setCalculatorVisible={(visibility) => this.setState({ isCalculatorVisible: visibility })}
                    setGenerateReportVisible={(visibility) => this.setState({ isVisibelGenerateReport: visibility })}
                >
                    <HeaderIcons visibleClass="hidden-large" showText={true} />
                </TopQuickAccessMenu>
            </Menu>
        );

        const menu = (
            <Menu /* onClick={handleMenuClick} */>
                {canShow("OpenPerfil") && !Session.isPortalOrClient() && (
                    <Menu.Item key="5" onClick={this.showEditUserInfoModel}>
                        <Icon type="user" /> Perfil
                    </Menu.Item>
                )}

                {canShow("OpenChangePassword") && !Session.isPortal() && (
                    <Menu.Item key="3" onClick={() => this.setState({ changePassword: true })}>
                        <Icon type="key" /> Alterar senha
                    </Menu.Item>
                )}
                {canShow("qa_registo_ponto") && !Session.isPortalOrClient() && (
                    <Menu.Item key="5">
                        <Link to="/function_manual/time_registration">
                            <Icon type="clock-circle" /> &nbsp; Registo de ponto
                        </Link>
                    </Menu.Item>
                )}
                <Menu.Item key="4" onClick={() => this.logout()}>
                    <Icon type="logout" /> Sair
                </Menu.Item>
            </Menu>
        );

        const HeartSvg = () => (
            // <svg width="1em" height="1em" fill="currentColor"  viewBox="0 0 1024 1024">
            <svg viewBox="0 0 20 20" className="svg-icon">
                <path d="M15.984 20.016v-4.031h4.031v4.031h-4.031zM15.984 14.016v-4.031h4.031v4.031h-4.031zM9.984 8.016v-4.031h4.031v4.031h-4.031zM15.984 3.984h4.031v4.031h-4.031v-4.031zM9.984 14.016v-4.031h4.031v4.031h-4.031zM3.984 14.016v-4.031h4.031v4.031h-4.031zM3.984 20.016v-4.031h4.031v4.031h-4.031zM9.984 20.016v-4.031h4.031v4.031h-4.031zM3.984 8.016v-4.031h4.031v4.031h-4.031z"></path>
                {/* <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" /> */}
            </svg>
        );

        const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;

        const handleThemeChange = (theme, allUser) => {
            this.props.changeTheme(theme, allUser).then((resp) => {
                const { botoes, area_logo, focus, id, tipo, titulo, textos, textos_sec, menu_lateral } =
                    resp?.linha || resp?.prop?.linha;

                this.setState({
                    themeSelected: theme,
                    vars: {
                        "@primary-color": { ...this.state.vars["@primary-color"], value: "#" + botoes },
                        "@text-color": { ...this.state.vars["@text-color"], value: "#" + textos },
                        "@text-color-secondary": {
                            ...this.state.vars["@text-color-secondary"],
                            value: "#" + textos_sec,
                        },
                        "@heading-color": { ...this.state.vars["@heading-color"], value: "#" + titulo },
                        "@layout-header-background": {
                            ...this.state.vars["@layout-header-background"],
                            value: "#" + area_logo,
                        },
                        "@layout-sider-background": {
                            ...this.state.vars["@layout-sider-background"],
                            value: "#" + menu_lateral,
                        },
                        "@processing-color": { ...this.state.vars["@processing-color"], value: "#" + focus },
                    },
                });

                window.less
                    .modifyVars({
                        "@primary-color": "#" + botoes,
                        "@text-color": "#" + textos,
                        "@text-color-secondary": "#" + textos_sec,
                        "@heading-color": titulo,
                        "@layout-header-background": "#" + area_logo,
                        "@layout-sider-background": "#" + menu_lateral,
                        "@processing-color": "#" + focus,
                    })
                    .then((resp) => {})
                    .catch((err) => () => {});
            });
        };

        return (
            <AppContext.Provider
                value={{
                    getLogo: this.getLogo,
                    getChatRoomsOnSaveChatForm: this.props.getRooms,
                }}
            >
                {/* <Stakeholders visible={true} setVisible={() => {}} defaultValues={{}} /> */}

                <UserProvider>
                    <Calulator
                        isCalculatorVisible={this.state.isCalculatorVisible}
                        setCalculatorVisible={(visibility) => this.setState({ isCalculatorVisible: visibility })}
                    />

                    {canShow("OpenChat") && <ChatComponent />}

                    <ChangePassword
                        isVisibleChangePassword={this.state.changePassword}
                        closeModal={() => this.setState({ changePassword: false })}
                    />

                    {!Session.isPortalOrClient() && (
                        <>
                            {/* {this.state.isVisible && ( */}
                            <EditUserInfo
                                isVisible={this.state.isVisible}
                                showEditUserInfoModel={this.showEditUserInfoModel.bind(this)}
                                getProfilePhoto={this.getProfilePhoto.bind(this)}
                            />
                            {/* )} */}

                            <ForumComponent />

                            {canShow("OpenUpdateApp") && (
                                <UpdateConponent
                                    canUpdate={canShow("OpenUpdateApp")}
                                    showModalUpdates={this.state.showModalUpdates}
                                    closeModal={() => this.setState({ showModalUpdates: false })}
                                />
                            )}

                            <ChatConfComponent />

                            <ForumConfConponent />

                            {canShow("OpenMenuAgenda") && this.state.isScheduleVisible && (
                                <ScheduleComponent
                                    visible={this.state.isScheduleVisible}
                                    onCancel={() => this.setState({ isScheduleVisible: false })}
                                    getBadge={this.props.getBadgeResume}
                                    getRemember={() => this.props.getRemember({ accao: "lista-all" })}
                                    definedEvents={{
                                        list: this.props.defined_events,
                                        get: this.props.getDefinedEvents,
                                    }}
                                    places={{
                                        list: this.props.places,
                                        get: this.props.getEventsPlaces,
                                    }}
                                    eventsPermissions={{
                                        list: this.props.events_permissions,
                                        get: this.props.getEventsPermission,
                                    }}
                                    eventsPermissionGroups={{
                                        list: this.props.events_permission_groups,
                                        get: this.props.getEventsPermissionGroups,
                                    }}
                                    selectContributorsProps={{
                                        contributorsByFuncionalGroup: this.props.contributorsByFuncionalGroup,
                                        allContributors: this.props.allContributors,
                                        funcionalGroups: this.props.funcionalGroups,
                                        loadingContributorsByFuncionalGroup: this.contributorsByFucionalGroup,
                                        isloadingContributorsByFuncionalGroup:
                                            this.state.loadingContributorsByFuncionalGroup,
                                    }}
                                />
                            )}
                            {canShow("OpenMenuMensagens") && this.state.isMessageVisible && (
                                <MessageComponent
                                    visible={this.state.isMessageVisible}
                                    onCancel={() => this.setState({ isMessageVisible: false })}
                                    unread={this.props.badge_resume.mensagens}
                                    getBadge={this.props.getBadgeResume}
                                    selectContributorsProps={{
                                        contributorsByFuncionalGroup: this.props.contributorsByFuncionalGroup,
                                        allContributors: this.props.allContributors,
                                        funcionalGroups: this.props.funcionalGroups,
                                        loadingContributorsByFuncionalGroup: this.contributorsByFucionalGroup,
                                        isloadingContributorsByFuncionalGroup:
                                            this.state.loadingContributorsByFuncionalGroup,
                                    }}
                                />
                            )}

                            <AlertComponent
                                isAlertVisible={this.state.isAlertVisible}
                                onCancel={() => this.setState({ isAlertVisible: false })}
                                showModal={() => this.setState({ isAlertVisible: true })}
                            />
                        </>
                    )}

                    {canShow("OpenLembretes") && this.state.isLembreteVisible && (
                        <LembrateComponent
                            isLembreteVisible={this.state.isLembreteVisible}
                            openAlert={() => this.setState({ isAlertVisible: true })}
                            getBadge={this.props.getBadgeResume}
                            dataSource={{
                                lembretes: this.props.lembretes,
                                lembretes_futuros: this.props.lembretes_futuros,
                                // funcionalGroups: this.props.funcionalGroups,
                                // allContributors: this.props.allContributors,
                                // contributorsByFuncionalGroup: this.props
                                //     .contributorsByFuncionalGroup,
                                lembretes_vistos: this.props.lembretes_vistos,
                            }}
                            selectContributorsProps={{
                                contributorsByFuncionalGroup: this.props.contributorsByFuncionalGroup,
                                allContributors: this.props.allContributors,
                                funcionalGroups: this.props.funcionalGroups,
                                loadingContributorsByFuncionalGroup: this.contributorsByFucionalGroup,
                                isloadingContributorsByFuncionalGroup: this.state.loadingContributorsByFuncionalGroup,
                            }}
                            getLembreteVisualization={this.props.getLembreteVisualization}
                            // loadingContributorsByFuncionalGroup={
                            //     this.contributorsByFucionalGroup
                            // }
                            // isloadingContributorsByFuncionalGroup={
                            //     this.state.loadingContributorsByFuncionalGroup
                            // }
                            onCancel={() => this.setState({ isLembreteVisible: false })}
                            updateList={() => this.props.getRemember({ accao: "lista-all" })}
                        />
                    )}

                    <Modal
                        // title="Geracão de relatórios"
                        visible={this.state.isVisibelGenerateReport}
                        onOk={null}
                        onCancel={() => this.setState({ isVisibelGenerateReport: false })}
                        footer={null}
                        width="90%"
                        style={{ top: 20 }}
                        bodyStyle={{
                            // padding: 0,
                            paddingTop: 50,
                            border: "1px solid #ccc",
                            borderTop: "none",
                        }}
                    >
                        <ReportBuilderIndex />
                    </Modal>

                    <Layout
                        style={{
                            minHeight: "100vh",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Sider
                            breakpoint="lg"
                            collapsedWidth="0"
                            onBreakpoint={(broken) => {
                                console.log(broken);
                                this.onBreakPoint(broken);
                            }}
                            onCollapse={(collapsed, type) => {
                                // console.log(collapsed, type);
                                this.toggle(type, collapsed);
                            }}
                            style={{
                                height: "100vh",
                                position: "fixed",
                                zIndex: 99,
                            }}
                        >
                            <Link to="/">
                                <img
                                    className=""
                                    style={{
                                        height: 32,
                                        margin: 16,
                                        maxWidth: 168,
                                    }}
                                    src={this.state.logo}
                                    alt="logo"
                                />
                            </Link>

                            {!Utilitaries.isEmpty(toArray(this.props.menus)) ? (
                                <Skeleton paragraph={{ rows: 8, width: 100 }} active loading={this.state.isFetching}>
                                    <SideBarMenu
                                        recentsProcesses={this.props.processos_utilizados}
                                        defaultListOfMenus={[...this.props.menus]}
                                        changeForumModalState={this.props.changeForumModalState}
                                        changeChatModalState={this.props.changeChatModalState}
                                        changeForumConfModalState={this.props.changeForumConfModalState}
                                        changeChatConfModalState={this.props.changeChatConfModalState}
                                        changeUpdateModalState={this.props.changeUpdateModalState}
                                        showColorPalette={this.showDrawer}
                                    />
                                </Skeleton>
                            ) : null}
                        </Sider>
                        <Layout
                            style={{
                                transition: "all 0.2s",
                                marginLeft: this.paddingSectionLayout(),
                            }}
                        >
                            <Header
                                style={{
                                    background: "#fff",
                                    padding: 0,
                                    position: "fixed",
                                    zIndex: 2,
                                    width: "100%",
                                    boxShadow: "0 1px 4px rgba(0,21,41,.08)",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginRight: this.paddingSectionLayout(),
                                        transition: "all 0.2s",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: !this.state.breakPointActive ? "none" : "block",
                                        }}
                                    >
                                        <Link to="/">
                                            <img
                                                className=""
                                                style={{
                                                    height: 32,
                                                    margin: 16,
                                                    maxWidth: 168,
                                                }}
                                                src={this.state.logo}
                                                alt="logo"
                                            />
                                        </Link>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            flex: 1,
                                        }}
                                    >
                                        <HeaderIcons visibleClass="hidden-small" />

                                        {!Session.isPortalOrClient() && (
                                            <span className="ant-badge">
                                                <Dropdown
                                                    overlay={settingsMenu}
                                                    trigger={["hover", "click"]}
                                                    className=""
                                                >
                                                    <Button type="link">
                                                        <HeartIcon />
                                                    </Button>
                                                </Dropdown>
                                            </span>
                                        )}

                                        <Dropdown overlay={menu} className="">
                                            <Button type="link">
                                                <Avatar
                                                    size="default"
                                                    src={
                                                        this.state.foto_perfil
                                                            ? this.state.foto_perfil
                                                            : Session.getPhotoUrl() ||
                                                              "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                                    }
                                                />
                                                <span style={{ marginLeft: 5 }}>{userFullName}</span>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Header>
                            <Content style={{ padding: "0 20px", marginTop: 84 }}>
                                <div
                                    id="div-content-layout"
                                    style={{ padding: 24, background: "#fff", height: "100%" }}
                                >
                                    <Skeleton
                                        paragraph={{ rows: 12, width: 100 }}
                                        active
                                        loading={this.state.isFetching}
                                    >
                                        {qaRoutesLocal.map((route, index) => (
                                            <Route key={"route-local-" + index} {...route} />
                                        ))}

                                        {/* Routes */}
                                        {this.props.routes.map((route, index) => {
                                            let _router = qaRoutesFromDB[route];
                                            if (_router) {
                                                const inQARoutesLocal = qaRoutesLocal.find(
                                                    (localRoute) => localRoute.path === _router.path
                                                );
                                                if (!inQARoutesLocal) {
                                                    return <Route key={"route-" + index} {..._router} />;
                                                }
                                            }
                                            return null;
                                        })}

                                        <Route path="/login">
                                            <Redirect to="/" />
                                        </Route>
                                    </Skeleton>
                                </div>
                            </Content>
                            <Drawer
                                title="Configurar Grafismo"
                                placement="right"
                                closable={false}
                                onClose={this.onClose}
                                visible={this.state.isDrawerVisible}
                            >
                                {this.state.themePermissions.find(
                                    (item) => item.descricao === "paleta_cores_admin"
                                ) && (
                                    <>
                                        <h3>Para todos utilizadores?</h3>
                                        <Radio.Group
                                            onChange={(e) => this.handleAllUserChange(e.target.value)}
                                            value={this.state.allUser}
                                        >
                                            <Radio style={radioStyle} value={"s"}>
                                                Sim
                                            </Radio>
                                            <Radio style={radioStyle} value={"n"}>
                                                Não
                                            </Radio>
                                        </Radio.Group>
                                    </>
                                )}
                                <h3>Personalizar Tema</h3>
                                {colorPickers}
                                <h3>Temas predefinidos</h3>
                                <Radio.Group
                                    onChange={(e) => handleThemeChange(e.target.value, this.state.allUser)}
                                    value={this.state.themeSelected}
                                >
                                    <Radio style={radioStyle} value={2}>
                                        Vermelho
                                    </Radio>
                                    <Radio style={radioStyle} value={3}>
                                        Verde
                                    </Radio>
                                    <Radio style={radioStyle} value={4}>
                                        Azul
                                    </Radio>
                                </Radio.Group>
                                <h3>Redefinir Tema</h3>
                                <Button type="danger" ghost onClick={() => handleThemeChange(1, this.state.allUser)}>
                                    Redefinir
                                </Button>
                            </Drawer>
                            <Footer style={{ textAlign: "center" }}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="http://qualimaking.com/"
                                    style={{ color: "#00AEEF" }}
                                >
                                    QualityMaking Essencial
                                </a>
                                &nbsp;
                                <span style={{ color: "#201d1d" }}>
                                    © {new Date().getFullYear()} maintenance performed by QualityMaking
                                </span>
                            </Footer>
                        </Layout>
                    </Layout>
                </UserProvider>
            </AppContext.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menus: state.home.domain.menus
            ? Array.isArray(state.home.domain.menus)
                ? state.home.domain.menus
                : [state.home.domain.menus]
            : [],
        processos_utilizados: state.home.domain.processos_utilizados || [],
        routes: state.home.domain.routes || [],
        alerts: state.home.domain.alerts || [],
        lembretes_vistos: state.home.domain.lembretes_vistos || [],
        lembretes: state.home.domain.lembretes || [],
        alertas_nao_listar: state.home.domain.alertas_nao_listar || [],
        contributorsByFuncionalGroup: state.home.domain.contributors_by_funcional_group || [],
        funcionalGroups: Object.values(state.funcionalGroup.domain.byId),
        allContributors: state.fixedList.domain.byId,
        lembretes_futuros: state.home.domain.lembretes_futuros === "object" ? [] : state.home.domain.lembretes_futuros,
        defined_events: state.schedule.domain.defined_events || [],
        events_permissions: state.schedule.domain.events_permissions || [],
        badge_resume: state.home.domain.badge_resume,
        events_permission_groups: state.schedule.domain.events_permission_groups || [],
        places: state.schedule.domain.places || [],
        modalForumState: state.forum.modalForumState || false,
    };
};
const mapDispatchToProps = {
    getMenus,
    setRoutes,
    getRemember,
    getFunctionalGroups,
    getAllContributors,
    getContributorsByFuncionalGroup,
    getLembreteVisualization,
    getDefinedEvents,
    getEventsPermission,
    getEventsPermissionGroups,
    getBadgeResume,
    getEventsPlaces,
    changeForumModalState,
    changeChatModalState,
    changeUpdateModalState,
    changeForumConfModalState,
    changeChatConfModalState,
    getRooms,
    getAuthorizations,
    getThemeProperties,
    changeTheme,
    changeThemeProperty,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
// export default AppLayout;
