import React, { useContext } from "react";
// import PropTypes from "prop-types";
import { Col, Form } from "antd";

import FormContext from "../context/FormContext";
import { QADivider, QAToolTip } from "./";
import { QaDynamicSelect } from "../../DynamicList/index";
import { getInputLabel } from "../utils/functions";

const QaDynamicList = ({
    inputAttribute,
    value, 
    self,
    visible,
    handleInputChange,
    renderGroup,
    isRequired,
    colSize,
    disabled,
    ...inputProps
}) => {
    const { changeInputValue, justSee } = useContext(FormContext);

    if (!visible) {
        return null;
    }

    const RenderSelect = () => (
        <QaDynamicSelect
            attributes={inputAttribute}
            // key={"_dynamicList_" + self + "_" + new Date().getTime()}
            name={inputAttribute["@nome_lista"] || self}
            disabled={justSee || disabled}
            listKey={inputAttribute["@chave_lista"]}
            listValue={inputAttribute["@valor_lista"]}
            value={value}
            onChange={(value, option) => {
                changeInputValue({
                    value, // valor selecionado  in value
                    key: self, // NOme do campo
                    ...inputProps, // propriedades da tabela
                    inputAttribute, // input atributos
                    _value: option && option.props ? option.props.children : "",
                    // _value: option.props.children // valor selecionado - descricao do options
                });
                // handleInputChange(value, self, inputProps);
            }}
            // label={
            //     <QAToolTip
            //         label={
            //             inputAttribute["@etiqueta"]
            //                 ? inputAttribute["@etiqueta"]
            //                 : self.toPascalCase(true)
            //         }
            //         help={inputAttribute["@ajuda"]}
            //     />
            // }
            col={24}
            {...inputProps}
        />
    );

    const { inTable } = inputProps;

    return inTable ? (
        RenderSelect()
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            <Col key={"col-"} xs={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, self)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    {RenderSelect()}
                </Form.Item>
            </Col>
        </>
    );
};

QaDynamicList.propTypes = {};

export default QaDynamicList;
