import React, { useEffect } from "react";
import { Select, Form } from "antd";
import { componentType } from "../../constants/index";
import { getSelectDataKeys } from "../../utils/input_attributes";
import { nullSafe, canCorrectField } from "../../utils/fields";
import { Utilitaries } from "../../../../business";
import { removeDuplicates } from "../../../../business/utils";

const { Option } = Select;

const QaSelect = ({
    itemData,
    dataSource,
    label,
    root,
    rowKey,
    columnDataSource,
    formItemStyle,
    currentTaskStatus,
    type,
    error,
    handleSelectChange,
    onChange,
    readonly,
    renderContext,
    showFieldEditorModal,
    correctForm,
    removeInactivedValueError,
    fixedSize,
    loadData,
}) => {
    const _data =
        rowKey && dataSource && dataSource.length === 0 ? (columnDataSource ? columnDataSource : []) : dataSource;

    let { key, value } = getSelectDataKeys(itemData, _data);

    if (itemData?.["@valor_lista"]?.includes(";")) {
        value = itemData?.["@valor_lista"].split(";").filter((it) => it.trim());
    } else {
        value = [value];
    }

    let selectDataSource =
        (rowKey || rowKey === 0) && dataSource && dataSource.length === 0
            ? columnDataSource
                ? columnDataSource
                : []
            : Array.isArray(dataSource)
            ? dataSource
            : dataSource?.["1"]?.[itemData.key]?.["dataSource"];

    if (
        Array.isArray(selectDataSource) &&
        selectDataSource.length === 0 &&
        Array.isArray(columnDataSource) &&
        columnDataSource.length > 0
    ) {
        selectDataSource = columnDataSource;
    }

    // useEffect(() => {
    //     const valideDynamicListIsActive = (value) => {
    //         const item = Utilitaries.toArray(selectDataSource)?.find(
    //             (it) => Utilitaries.toString(it[key]) == Utilitaries.toString(value)
    //         );

    //         console.log("valideDynamicListIsActive", itemData.key, item);
    //     };

    //     const _value_ = nullSafe(itemData.value);

    //     if (_value_) {
    //         valideDynamicListIsActive(_value_);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectDataSource]);

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus || readonly;

    let selectStyle = Utilitaries.toString(itemData?.["@estilo"]).includes("qa_without_width")
        ? { minWidth: 150 }
        : renderContext === "column"
        ? { width: showFieldEditorModal ? 150 : "100%" }
        : { width: "100%" };

    let highestValueLength = Utilitaries.toArray(selectDataSource)?.map((item) => item?.value?.length);
    if (highestValueLength) {
        highestValueLength = Math.max.apply(null, highestValueLength);
    }

    if (renderContext === "column" && highestValueLength < 4) {
        selectStyle = { width: 90 };
    }

    const selectedDefaultValue = () => {
        const _value_ = nullSafe(itemData.value);

        const item = Utilitaries.toArray(selectDataSource)?.find(
            (it) => Utilitaries.toString(it[key]) == Utilitaries.toString(_value_)
        );

        if (item) {
            return Utilitaries.toString(item[key]);
        }

        return "";
    };

    const isInactivedSelectedItem = () => {
        const _value_ = nullSafe(itemData.value);

        const item = Utilitaries.toArray(selectDataSource)?.find(
            (it) => Utilitaries.toString(it[key]) == Utilitaries.toString(_value_)
        );

        if (item?.activo === "f" /*|| item?.is_disable === "t"*/) {
            return true;
        }

        return false;
    };

    if (itemData?.["@modo"] === "multiple") {
        return (
            <Form.Item label={label} style={formItemStyle}>
                <Select
                    allowClear
                    disabled={disabled}
                    // disabled={currentTaskStatus || canCorrectField(itemData)}
                    dropdownMatchSelectWidth={false}
                    value={Utilitaries.toArray(itemData.value)}
                    onChange={(value) => {
                        handleSelectChange({
                            value: Utilitaries.toString(value),
                            key: itemData.key,
                            root,
                            rowKey,
                            inputGroupPath: itemData.inputGroupPath,
                        });
                    }}
                    mode="multiple"
                    style={selectStyle}
                    placeholder={itemData["@placeholder"]}
                >
                    {dataSource.map((data) => {
                        const optionValue =
                            value.length === 1
                                ? data[value[0]]
                                : value.reduce((accumulator, current) => {
                                      accumulator = `${accumulator} ${accumulator ? " - " : ""} ${data[current]}`;
                                      return accumulator;
                                  }, "");
                        const title = data?.["descricao"] || optionValue;
                        return (
                            <Select.Option
                                key={itemData.key + Utilitaries.toString(data[key])}
                                value={Utilitaries.toString(data[key])}
                                title={title}
                            >
                                {optionValue}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    }

    return (
        <Form.Item label={label} style={formItemStyle}>
            {type === componentType._select && (
                <Select
                    showSearch
                    allowClear
                    dropdownMatchSelectWidth={false}
                    disabled={disabled}
                    loading={loadData}
                    value={selectedDefaultValue()}
                    style={selectStyle}
                    placeholder={itemData["placeholder"]}
                    onChange={(value, option) => {
                        onChange(value, { selectValueText: option?.props?.["attr-text"] });
                        if (isInactivedSelectedItem()) {
                            removeInactivedValueError();
                        }
                    }}
                    onFocus={() => {}}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    {Utilitaries.toArray(removeDuplicates(selectDataSource, itemData["@chave_lista"]))?.map((data) => {
                        const isDisable = data?.["is_disable"] === "t" ? true : false;
                        const optionValue = `${
                            value.length === 1
                                ? data[value[0]]
                                : value.reduce((accumulator, current) => {
                                      accumulator = `${accumulator} ${accumulator ? " - " : ""} ${data[current]}`;
                                      return accumulator;
                                  }, "")
                        } ${isDisable ? "(inativo)" : ""}`;
                        const title = `${data?.["descricao"] || optionValue}`;

                        return (
                            <Option
                                key={itemData.key + Utilitaries.toString(data[key])}
                                attr-text={optionValue}
                                value={Utilitaries.toString(data[key])}
                                title={title}
                                disabled={isDisable}
                            >
                                {optionValue}
                            </Option>
                        );
                    })}
                </Select>
            )}
            {error && <span style={{ color: "red" }}>{error}</span>}

            {isInactivedSelectedItem() && <span style={{ color: "red" }}> Valor inativo </span>}
        </Form.Item>
    );
};

QaSelect.propTypes = {};

export default QaSelect;
