import { Button, Icon, Input, Modal, Select, Table } from "antd";
import React from "react";
import Highlighter from "react-highlight-words";

import { Utilitaries } from "../../../business";
import { GenericObject } from "../../types/common";

interface IProps {
    options: any[];
    value?: any;
    onChange: (value: any, record?: any) => void;
    placeholder?: string;
    disabled?: boolean;
    key_list: string;
    value_list: string;
    typeList: "estatica" | "dinamica";
    style?: any;
    loading?: boolean;
}

export function SelectInput({
    options,
    onChange,
    value,
    placeholder,
    value_list,
    key_list,
    disabled,
    typeList,
    style,
    loading,
}: IProps) {
    const [visible, setVisible] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [searchedColumn, setSearchedColumn] = React.useState("");

    const onRow = (record: any) => {
        return {
            onClick: () => {
                const options = {
                    ...record,
                    title: record[value_list.split(";").filter((it) => !Utilitaries.isEmpty(it))[0]],
                };

                onChange(record[key_list], options);
                handleOk();
            },
        };
    };

    function handleOpen() {
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        setVisible(false);
    }

    function handleClear() {
        onChange("");
        setVisible(false);
    }

    const searchInput = React.useRef<Input>(null);

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Pesquisar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    limpar
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value: any, record: any) =>
            Utilitaries.toString(record[dataIndex]).toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select());
            }
        },
        render: (text: string) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText("");
    };

    const columns: any[] = Utilitaries.toString(value_list)
        .split(";")
        .filter((item: string) => !Utilitaries.isEmpty(item))
        .map((item: string) => {
            const title = item.charAt(0).toUpperCase() + item.slice(1);

            return {
                title,
                dataIndex: item,
                key: item,
                ...getColumnSearchProps(item),
            };
        });

    const selectedOption = Utilitaries.toArray(options).find((item: any) => item[key_list] === value);

    const getValue = () =>
        selectedOption
            ? columns.reduce((acc, current) => {
                  if (acc === "") {
                      acc = selectedOption[current.dataIndex];
                  } else {
                      acc = acc + " - " + selectedOption[current.dataIndex];
                  }

                  return acc;
              }, "")
            : "";

    const selectedValue = (item: any) => {
        if (typeList === "dinamica") return item[key_list];

        if (item[1]) return item[1].trim();

        return item[0];
    };

    const optionsAttributes = (option: any) => {
        const attr = Object.keys(option || {}).reduce((acc, item) => {
            acc[`attr-${item}`] = option[item];

            return acc;
        }, {} as GenericObject);

        return attr;
    };

    return (
        <div>
            {Utilitaries.toArray(options).length <= 10 || typeList === "estatica" ? (
                <Select
                    // defaultValue={value}
                    showSearch
                    disabled={disabled}
                    allowClear
                    value={value}
                    style={style}
                    placeholder=""
                    loading={loading}
                    dropdownMatchSelectWidth={false}
                    optionFilterProp="children"
                    onChange={(value: any, option: any) => {
                        const allValues = Object.keys(option?.props || {}).reduce((acc, item) => {
                            if (item.includes("attr-")) {
                                acc[item.replace("attr-", "")] = option?.props[item];
                            } else {
                                acc[item] = option?.props[item];
                            }

                            return acc;
                        }, {} as GenericObject);

                        onChange(value, allValues);
                    }}
                    filterOption={(input, option) =>
                        Utilitaries.toString(option?.props?.children)?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {options.map((item, idx) => {
                        const isDisable = item?.["is_disable"] == "t" ? true : false;
                        return (
                            <Select.Option
                                key={
                                    typeList === "dinamica"
                                        ? `${item[key_list]}${idx + 500 * Math.random()}`
                                        : item[1] + new Date().getTime()
                                }
                                value={selectedValue(item)}
                                title={
                                    typeList === "dinamica"
                                        ? item[value_list.split(";").filter((it) => !Utilitaries.isEmpty(it))[0]]
                                        : item[0]
                                }
                                {...optionsAttributes(item)}
                                // attr-text={optionValue}
                                disabled={isDisable}
                            >
                                {typeList === "dinamica"
                                    ? value_list
                                          .split(";")
                                          .filter((it) => !Utilitaries.isEmpty(it))
                                          .map((it) => item[it])
                                          .join(" - ")
                                    : item[0]}
                            </Select.Option>
                        );
                    })}
                </Select>
            ) : (
                <Button onClick={handleOpen} style={{ width: "100%" }} disabled={disabled} title={getValue()}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <div style={{ flex: "1", display: "flex", justifyContent: "left", width: "calc(100% - 10px)" }}>
                            {!Utilitaries.isEmpty(value) && (
                                <span
                                    style={{
                                        color: "rgba(0, 0, 0, 0.65)",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {getValue()}
                                </span>
                            )}
                            {Utilitaries.isEmpty(value) && (
                                <span style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                                    {placeholder ?? " Selecione uma opção"}
                                </span>
                            )}
                        </div>
                        <Icon type={loading ? "loading" : "down"} />
                    </div>
                </Button>
            )}
            <Modal
                title="Selecione uma opção"
                visible={visible}
                onCancel={handleCancel}
                width={columns.length > 5 ? "80%" : "60%"}
                footer={[
                    <Button key="clear" type="danger" onClick={handleClear}>
                        Limpar
                    </Button>,
                    <Button key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                ]}
            >
                <Table
                    dataSource={Utilitaries.toArray(options)}
                    columns={columns}
                    size="small"
                    onRow={onRow}
                    rowKey={(record: any) => record[key_list]}
                    rowSelection={{
                        type: "radio",
                        selectedRowKeys: [value],
                        onSelect: (record: any) => {
                            const options = {
                                ...record,
                                title: record[value_list.split(";").filter((it) => !Utilitaries.isEmpty(it))[0]],
                            };

                            onChange(record[key_list], options);
                            handleOk();
                        },
                        getCheckboxProps: (record) => ({
                            disabled: record?.["is_disable"] === "t",
                        }),
                    }}
                    pagination={{
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        pageSizeOptions: ["20", "50", "100", "200"],
                    }}
                    scroll={{ y: 240 }}
                />
            </Modal>
        </div>
    );
}
