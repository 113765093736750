import { Form, Button, Icon } from "antd";
import React from "react";
import { xmlProps } from "../../constants";
import { canCorrectField } from "../../utils/fields";
import { ActionBotton } from "./actionBotton";
// import { xmlProps, componentType as ComponentType, componentType } from "../../constants";

const QAButton = ({
    state,
    label,
    itemData,
    correctForm,
    handleButtonComponentClick,
    currentTaskStatus,
    record,
    showModal,
    error,
    readonly,
    formItemStyle,
}) => {
    const getIcon = () => {
        if (itemData?.[xmlProps.imagem]?.endsWith("calendar-icon.gif")) {
            return "schedule";
        }
        if (itemData?.[xmlProps.imagem] === "send_email") {
            return "redo";
        } else if (itemData[xmlProps.popupForm]) {
            return "form";
        }
        return "reload";
    };

    if (itemData[xmlProps.popupForm]) {
        return (
            <>
                <Form.Item label={label} style={formItemStyle}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                            disabled={itemData["@apenas_leitura"] ? readonly : false}
                            icon={getIcon()}
                            onClick={() => showModal({ column: itemData, record })}
                        />

                        {((record && record[itemData.key] && typeof record[itemData.key] !== "object") ||
                            (itemData.value && typeof itemData.value !== "object")) && (
                            <Icon
                                style={{
                                    color: "green",
                                    marginLeft: 10,
                                }}
                                type="check"
                            />
                        )}
                    </div>
                    {error && <span style={{ color: "red" }}>{error}</span>}
                </Form.Item>
            </>
        );
    } else if (itemData[xmlProps.buttonAction]) {
        return <ActionBotton icon={getIcon()} label={label} record={record} />;
    }

    const canEdit = canCorrectField(itemData) && correctForm;

    const disabled = canEdit ? false : currentTaskStatus;

    return (
        <Form.Item label={label}>
            <Button
                icon="reload"
                disabled={disabled || readonly}
                onClick={() => handleButtonComponentClick({ item: itemData })}
            />
            {error && <span style={{ color: "red" }}>{error}</span>}
        </Form.Item>
    );

    // if (itemData["@tipo"] === componentType.icon) {
    //     return (
    //         <>
    //             <Form.Item label={label} style={formItemStyle}>
    //                 <Button
    //                     disabled={itemData["@apenas_leitura"] ? readonly : false}
    //                     icon="edit"
    //                     onClick={() => showModal({ column: itemData, record })}
    //                 />

    //                 {((record && record[itemData.key] && typeof record[itemData.key] !== "object") ||
    //                     (itemData.value && typeof itemData.value !== "object")) && (
    //                     <Icon
    //                         style={{
    //                             color: "green",
    //                             marginLeft: 10,
    //                         }}
    //                         type="check"
    //                     />
    //                 )}
    //                 {error && <span style={{ color: "red" }}>{error}</span>}
    //             </Form.Item>
    //         </>
    //     );
    // }

    // return (
    //     <Form.Item label={label}>
    //         <Button icon="reload" onClick={() => handleButtonComponentClick({ item: itemData })} />
    //         {error && <span style={{ color: "red" }}>{error}</span>}
    //     </Form.Item>
    // );
};

export default QAButton;
