import React, { useState } from "react";
import { Form, Icon, Input, Button, Typography, Alert } from "antd";

import { api } from "../../http.request";
import { Session } from "../../../business";
import { DEV_UNICV, IS_UNICV } from "../../../constants/enviroment";
import { getuserComputerInfo } from "../../util";

const { Title } = Typography;

const LockScreen = (props) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const userInfo = getuserComputerInfo();

    const textColor = IS_UNICV ? {} : { color: "white" };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setLoading(true);
                const formData = new FormData();
                // formData.append("accao", "login-json");
                formData.append("accao", DEV_UNICV ? "login-teste" : "login-json");
                formData.append("validar", "true");
                formData.append("utilizador", values.username);
                formData.append("senha", values.password);
                formData.append("token", Session.getToken());
                formData.append("os", userInfo.plataform);
                formData.append("browser", userInfo.browser);
                formData.append("computer", userInfo.computer_uuid);

                try {
                    const resp = await api("/Gestor/gereutilizador.php", formData);
                    if (resp.erro) {
                        setError(resp.erro);
                    } else {
                        Session.save({
                            username: resp.username,
                            name: resp.nome,
                            token: resp.token,
                            type: resp.tipo,
                            parametro1: resp.parametro1,
                            parametro2: resp.parametro2,
                            parametro3: resp.parametro3,
                        });
                        props.unlocked();
                    }
                } catch (error) {
                    console.error(error);
                    setError(error.erro);
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    const { getFieldDecorator } = props.form;

    return (
        <div className="login-body">
            <Title style={{...textColor}} level={3}>QM - Sessão expirada</Title>

            <div style={{ width: "300px", marginTop: "20px" }}>
                {error && <Alert message={error} type="error" banner />}
            </div>

            <Form onSubmit={handleSubmit} className="login-form">
                <Form.Item>
                    {getFieldDecorator("username", {
                        // initialValue: Session.getLastLoginUsername(),
                        initialValue: Session.getUsername(),
                    })(
                        <Input
                            disabled={true}
                            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder="Utilizador"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator("password", {
                        rules: [
                            {
                                required: true,
                                message: "Informe a senha!",
                            },
                        ],
                    })(
                        <Input
                            disabled={loading}
                            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                            type="password"
                            placeholder="Senha"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                        Desbloquear
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Form.create({ name: "form_lock_screen" })(LockScreen);
