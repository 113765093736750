import { Button, List, PageHeader, Tabs, Select, message, Icon, Tooltip, Menu, Dropdown } from "antd";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../actions/index";
import * as acPanel from "../../actions/panel";
import * as acExecution from "../../actions/execution";
import TaskPanelFilter from "./index.filter";
import TaskCard from "./task.card";
import TaskReassignModal from "./task.reassign.modal";
import ProcessAnnulmentModal from "./process.annulment.modal";
import IndexSkeleton from "./index.skeleton";
import "../../styles/index.css";
import { User_Preferences, Utilitaries } from "../../../../business/index";
import { toArray } from "../../../../components/util";
import { annulmentProcess, reassignTasks, delegateTasks } from "../../actions/panel";
import TaskDelegateModal from "./task.delegate.modal";
import QATable from "../../../../V2/components/Table";

const { TabPane } = Tabs;
const { Option } = Select;

const getPagination = (total, defaultValue) => {
    if (Utilitaries.isEmpty(total)) {
        return defaultValue;
    }

    try {
        return parseInt(total);
    } catch (error) {
        return defaultValue;
    }
};

class TaskPanel extends React.Component {
    state = {
        isReassignModalVisible: false,
        isDelegateModalVisible: false,
        isAnnulmentModalVisible: false,
        isLoading: false,
        isStablishmentsLoading: true,
        isFilterVisible: true,
        activeTabKey: "em_curso",
        estabelecimentos: [],
        authorizations: [],
        establishment: null,
        currentPage: 1,
        resultPerPage: 12,
        filters: {
            processName: "",
            processoDecision: "",
            processNumber: 0,
            taskName: "",
            taskNumber: 0,
            aditionalInfo: "",
            establishment: "",
            beginDate: "",
            endDate: "",
            order: "desc",
        },
        listType: "list",
    };

    handleMenuClick(e) {
        this.setState({ listType: e.key === "list" ? "list" : "grid" });
    }

    handleButtonClick() {
        this.setState({ listType: this.state.listType === "list" ? "grid" : "list" });
    }

    menuTypeList = (
        <Menu onClick={this.handleMenuClick.bind(this)}>
            <Menu.Item key="list">
                <Icon type="unordered-list" />
                {/* <Icon type="table" /> */}
                Lista
            </Menu.Item>
            <Menu.Item key="grid">
                <Icon type="appstore" />
                Coluna
            </Menu.Item>
        </Menu>
    );

    async componentDidMount() {
        this.setState({ isLoading: true });
        let defaultEstablishment = null;

        const establishmentPrefe = User_Preferences.getPanelEstablishedPref();

        await this.props.getStablishments().then((data) => {
            const estabelecimentos = toArray(data.estabelecimentos?.linha);

            if (establishmentPrefe) {
                defaultEstablishment = establishmentPrefe;
            } else {
                defaultEstablishment = Utilitaries.isEmpty(data.estabelecimento_defeito)
                    ? null
                    : data.estabelecimento_defeito;
            }

            this.setState({
                estabelecimentos,
                authorizations: toArray(data.autorizacoes?.linha),
                establishment: defaultEstablishment,
                isStablishmentsLoading: false,
            });
        });

        if (defaultEstablishment) {
            this.props
                .getDoingTasks({
                    accao: "em_curso",
                    pagina: this.state.currentPage,
                    resultados_pagina: this.state.resultPerPage,
                    filterParams: {
                        establishment: defaultEstablishment,
                    },
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        } else {
            this.setState({ isLoading: false });
        }

        await this.props.getTasksToReassign();
        await this.props.getGroupsToDelegate();
        await this.props.getProcessesToCancel();
    }

    handleXFormatDownloadDocument = (payload) => {
        const estabelecimento = this.state.establishment;
        // const areas = forms[taskId]?.area_atual?.value;
        // const valencia = forms[taskId]?.valencia?.value;
        this.props.xFormatDownloadDocument({
            ...payload,
            estabelecimento,
            // areas,
            // valencia,
            // departamento: department,
        });
    };

    getReports = (record) => {
        let reports = record?.report;

        if (Utilitaries.isEmpty(reports)) {
            return [];
        }

        reports = reports.split(", ");

        reports = reports.map((report) => {
            report = report.split(":");
            return {
                id: report[0],
                name: report[1],
            };
        });

        return reports;
    };

    render() {
        const { isLoading } = this.state;

        return (
            <>
                <PageHeader
                    title="Painel Tarefas"
                    extra={[
                        <>
                            <label style={{ marginRight: 8 }}>
                                <strong>Estabelecimento:</strong>
                            </label>
                            <Select
                                showSearch
                                name="establishment"
                                value={this.state.establishment || undefined}
                                style={{ width: "17em" }}
                                placeholder="Selecione um estabelecimento"
                                optionFilterProp="children"
                                onChange={(value) => {
                                    this.setState({ ...this.state, establishment: value });
                                    this.handleFilter({ ...this.state.filters, establishment: value });
                                    User_Preferences.addPanelEstablishedPref(value);
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                loading={this.state.isStablishmentsLoading}
                            >
                                {this.state.estabelecimentos.map((e) => (
                                    <Option value={e.id_estabelecimento}>{e.nome}</Option>
                                ))}
                            </Select>
                        </>,
                        <Button icon="filter" title="Filtrar" type="dashed" onClick={() => this.toggleFilter()}>
                            Outros filtros
                        </Button>,
                        <Dropdown.Button
                            // icon={this.state.listType === "list" ? "unordered-list" : "appstore"}
                            type="dashed"
                            style={{ marginLeft: 8 }}
                            onClick={this.handleButtonClick.bind(this)}
                            overlay={this.menuTypeList}
                        >
                            {this.state.listType === "list" ? "Lista" : "Colunas"}
                        </Dropdown.Button>,
                    ]}
                >
                    {this.state.authorizations.find((it) => it.descricao === "INICIAR_PROCESSO") && (
                        <Button
                            style={{ fontSize: "18px" }}
                            icon="play-circle"
                            onClick={() => this.goTo({ pathname: "/process/execution/index" })}
                            title="Iniciar processo"
                            type="link"
                        />
                    )}
                    {this.state.authorizations.find((it) => it.descricao === "GESTÃO_DOCUMENTOS") && (
                        <Button
                            style={{ fontSize: "18px" }}
                            icon="book"
                            title="Consulta de documentos e registos"
                            type="link"
                        />
                    )}
                    {this.state.authorizations.find((it) => it.descricao === "ANUL_PROC") && (
                        <Button
                            style={{ fontSize: "18px" }}
                            icon="rollback"
                            title=""
                            type="link"
                            onClick={() => this.showProcessAnnulmentModal()}
                        >
                            <span style={{ fontSize: "14px" }}>
                                {this.props.tasks.app.processesToCancelCount} Processos para anular
                            </span>
                        </Button>
                    )}

                    {this.state.authorizations.find((it) => it.descricao === "REATRIBUI_TAREFA") && (
                        <Button
                            style={{ fontSize: "18px" }}
                            icon="team"
                            title=""
                            type="link"
                            onClick={() => this.showTaskReassignModal()}
                        >
                            <span style={{ fontSize: "14px" }}>
                                {this.props.tasks.app.tasksToReassignCount === 1
                                    ? `${this.props.tasks.app.tasksToReassignCount} Tarefa para reatribuir`
                                    : `${this.props.tasks.app.tasksToReassignCount} Tarefas para reatribuir`}
                            </span>
                        </Button>
                    )}

                    {this.props.tasks.app.tasksToDelegateCount > 0 && (
                        <Button
                            style={{ fontSize: "18px", color: "red" }}
                            icon="team"
                            title=""
                            type="link"
                            onClick={() => this.showTaskDelegateModal()}
                        >
                            <span style={{ fontSize: "14px" }}>
                                {`${
                                    this.props.tasks.app.tasksToDelegateCount === 1
                                        ? "1 tarefa"
                                        : `${this.props.tasks.app.tasksToDelegateCount} tarefas`
                                } para delegar`}
                            </span>
                        </Button>
                    )}
                </PageHeader>
                {this.state.isFilterVisible ? (
                    <TaskPanelFilter
                        filters={this.state.filters}
                        setFilters={this.setFilters.bind(this)}
                        handleFilter={this.handleFilter.bind(this)}
                    />
                ) : null}
                {this.props.tasks.domain["em_curso"].lenght !== 0 && (
                    <Tabs
                        defaultActiveKey={this.state.activeTabKey}
                        activeKey={this.state.activeTabKey}
                        onChange={this.handleTabChange}
                    >
                        {[
                            {
                                title: "Em curso",
                                key: "em_curso",
                                name: "doing",
                            },
                            {
                                title: "Não Atribuidas",
                                key: "nao_atribuidas",
                                name: "todo",
                            },
                            {
                                title: "Terminadas",
                                key: "terminadas",
                                name: "done",
                            },
                        ].map((type) => {
                            const total = getPagination(
                                this.props.tasks.domain?.[`${type.key}_attributes`]?.resultados,
                                this.state.resultPerPage
                            );

                            return (
                                <TabPane tab={type.title} key={type.key}>
                                    {isLoading ? (
                                        <IndexSkeleton
                                            current={this.state.currentPage}
                                            pageSize={this.state.resultPerPage}
                                            total={total}
                                        />
                                    ) : (
                                        <>
                                            {this.state.listType !== "grid" ? (
                                                <QATable
                                                    data={
                                                        this.state.establishment
                                                            ? this.props.tasks.domain[type.key]
                                                            : []
                                                    }
                                                    columns={[
                                                        {
                                                            dataIndex: "tarefa",
                                                            title: "Número tarefa",
                                                        },
                                                        {
                                                            dataIndex: "nome_tarefa",
                                                            title: "Tarefa",
                                                        },
                                                        {
                                                            dataIndex: "processo",
                                                            title: "Número processo",
                                                        },
                                                        {
                                                            dataIndex: "nome_externo_processo",
                                                            title: "Processo",
                                                            render: (text) => {
                                                                return typeof text !== "object" ? text : "Não definido";
                                                            },
                                                        },
                                                        {
                                                            dataIndex: "informacao_adicional",
                                                            title: "Informação adicional",
                                                        },
                                                        {
                                                            dataIndex: "inicio",
                                                            title: "Data inicio",
                                                        },
                                                        {
                                                            dataIndex: "limite",
                                                            title: "Data limite",
                                                        },
                                                        {
                                                            dataIndex: "fim",
                                                            title: "Data fim",
                                                        },
                                                        {
                                                            dataIndex: "reports",
                                                            title: "",
                                                            render: (_text, record, index) => {
                                                                return (
                                                                    <>
                                                                        {!Utilitaries.isEmpty(record.report) &&
                                                                            this.getReports(record).map((report) => (
                                                                                <Tooltip
                                                                                    key={report.id + report.nome}
                                                                                    mouseEnterDelay={1}
                                                                                    title={report.name}
                                                                                >
                                                                                    <Icon
                                                                                        type="file-pdf"
                                                                                        style={{
                                                                                            color: "red",
                                                                                            marginLeft: 4,
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            this.handleXFormatDownloadDocument(
                                                                                                {
                                                                                                    id: report.id,
                                                                                                    saida: "pdf",
                                                                                                    processo:
                                                                                                        record.processo,
                                                                                                    tarefa: record.tarefa,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            ))}
                                                                    </>
                                                                );
                                                            },
                                                        },
                                                        {
                                                            dataIndex: "actions",
                                                            title: "Ação",
                                                            render: (_text, record, index) => {
                                                                return (
                                                                    <>
                                                                        <Button
                                                                            icon={
                                                                                type.key === "nao_atribuidas" ||
                                                                                record.isReassigned
                                                                                    ? "team"
                                                                                    : "file-search"
                                                                            }
                                                                            title="Detalhes"
                                                                            type="link"
                                                                            onClick={() =>
                                                                                this.handleGetTask({
                                                                                    processId: record.processo,
                                                                                    taskId: record.tarefa,
                                                                                    processParentId: record.proc_pai,
                                                                                    taskFormParams:
                                                                                        record.task_form_params,
                                                                                })
                                                                            }
                                                                        >
                                                                            <span>Detalhes</span>
                                                                        </Button>
                                                                    </>
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                    currentPage={this.state.currentPage}
                                                    datakey="id"
                                                    loadingData={false}
                                                    pageChange={(page, pageSize) => {
                                                        this.setState({
                                                            currentPage: page,
                                                            resultPerPage: pageSize,
                                                        });
                                                        this.handlePaginationChange(type.key, page, pageSize);
                                                    }}
                                                    totalResult={getPagination(
                                                        this.props.tasks.domain?.[`${type.key}_attributes`]?.resultados,
                                                        this.state.resultPerPage
                                                    )}
                                                    onRowClick={(record) => {}}
                                                    size="middle"
                                                    tableChange={(pagination, filter, sort) => {
                                                        // this.setState({
                                                        //     currentPage: page,
                                                        //     resultPerPage: pageSize,
                                                        // });
                                                        // this.handlePaginationChange(type.key, page, pageSize);
                                                    }}
                                                />
                                            ) : (
                                                <List
                                                    itemLayout="vertical"
                                                    isLoading={false}
                                                    style={{ marginTop: 50 }}
                                                    grid={{
                                                        gutter: 16,
                                                        xs: 1,
                                                        sm: 2,
                                                        md: 4,
                                                        lg: 4,
                                                        xl: 4,
                                                        xxl: 4,
                                                    }}
                                                    size="default"
                                                    pagination={{
                                                        current: this.state.currentPage,
                                                        pageSize: this.state.resultPerPage,
                                                        total: getPagination(
                                                            this.props.tasks.domain?.[`${type.key}_attributes`]
                                                                ?.resultados,
                                                            this.state.resultPerPage
                                                        ),
                                                        showTotal: (total) => `Total registos: ${total} `,
                                                        onShowSizeChange: (current, size) => {
                                                            this.setState({
                                                                currentPage: current,
                                                                resultPerPage: size,
                                                            });
                                                            this.handlePaginationChange(type.key, current, size);
                                                        },
                                                        onChange: (page, pageSize) => {
                                                            this.setState({
                                                                currentPage: page,
                                                                resultPerPage: pageSize,
                                                            });
                                                            this.handlePaginationChange(type.key, page, pageSize);
                                                        },
                                                        showSizeChanger: true,
                                                        pageSizeOptions: [12, 24, 48, 72, 96],
                                                    }}
                                                    dataSource={
                                                        this.state.establishment
                                                            ? this.props.tasks.domain[type.key]
                                                            : []
                                                    }
                                                    renderItem={(item) => (
                                                        <List.Item>
                                                            <TaskCard
                                                                type={type}
                                                                handleGetTask={this.handleGetTask.bind(this)}
                                                                getTaskReassignmentHistory={this.getTaskReassignmentHistory.bind(
                                                                    this
                                                                )}
                                                                handleXFormatDownloadDocument={
                                                                    this.handleXFormatDownloadDocument
                                                                }
                                                                item={item}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                </TabPane>
                            );
                        })}
                    </Tabs>
                )}

                {this.state.isReassignModalVisible && (
                    <TaskReassignModal
                        isVisible={this.state.isReassignModalVisible}
                        data={Object.values(this.props.tasks.domain.tasksToReassign)}
                        groups={this.props.tasks.domain.groupsToDelegate}
                        tasksSelectedToReassign={this.props.tasks.domain.tasksSelectedToReassign}
                        tasksToReassign={this.props.tasks.domain.tasksToReassign}
                        isFetched={this.props.tasks.app.isTasksToReassignFetched}
                        usersByGroup={this.props.tasks.domain.usersByGroup}
                        handleOk={this.handleReassignTasks.bind(this)}
                        handleCancel={this.handleCancel.bind(this)}
                        handleGetUserByGroup={this.handleGetUserByGroup.bind(this)}
                        // handleSetTasksToReassign={this.handleSetTasksToReassign.bind(this)}
                        handleGroupUserToDelegateChange={this.handleGroupUserToDelegateChange.bind(this)}
                    />
                )}

                {this.state.isDelegateModalVisible && (
                    <TaskDelegateModal
                        isVisible={this.state.isDelegateModalVisible}
                        data={Object.values(this.props.tasks.domain.tasksToDelegate)}
                        groups={this.props.tasks.domain.groupsToDelegate}
                        isFetched={this.props.tasks.app.isTasksToDelegateFetched}
                        handleOk={this.handleDelegateTasks.bind(this)}
                        handleCancel={this.handleDelegateCancel.bind(this)}
                    />
                )}

                <ProcessAnnulmentModal
                    isVisible={this.state.isAnnulmentModalVisible}
                    data={toArray(this.props.tasks.domain.processesToCancel)}
                    handleOk={this.handleProcessAnnulment.bind(this)}
                    handleCancel={this.handleProcessAnnulmentCancel.bind(this)}
                />
            </>
        );
    }

    toggleFilter() {
        this.setState({ isFilterVisible: !this.state.isFilterVisible });
    }

    showTaskReassignModal = async () => {
        this.setState({
            isReassignModalVisible: true,
        });
        await this.props.getTasksToReassign();
        await this.props.getGroupsToDelegate();
    };

    showTaskDelegateModal = async () => {
        this.setState({
            isDelegateModalVisible: true,
        });
        await this.props.getGroupsToDelegate();
    };

    handleDelegateTasks = async (tasks) => {
        if (tasks.length === 0) {
            message.warn("Nenhuma linha foi selecionada");
            return false;
        }
        try {
            const result = await delegateTasks(tasks);

            if (!result || result.erro) {
                message.error(result.erro || "Ocorreu um erro");
                return false;
            } else {
                await this.props.getGroupsToDelegate();
                message.success("Sucesso");
                this.setState({
                    isDelegateModalVisible: false,
                });
                return true;
            }
        } catch (err) {
            message.error("Ocorreu um erro");
            return false;
        }
    };

    handleDelegateCancel = (e) => {
        this.setState({
            isDelegateModalVisible: false,
        });
    };

    showProcessAnnulmentModal = async () => {
        this.setState({
            isAnnulmentModalVisible: true,
        });
    };

    handleProcessAnnulment = async (ids, accepted) => {
        if (ids.length === 0) {
            message.warn("Nenhuma linha foi selecionada");
            return false;
        }
        try {
            const result = await annulmentProcess(ids, accepted);
            if (!result || result.erro) {
                message.error(result.erro || "Ocorreu um erro");
                return false;
            } else {
                await this.props.getProcessesToCancel();

                if (this.state.establishment) {
                    this.handleFilter({ ...this.state.filters, establishment: this.state.establishment });
                }

                message.success("Sucesso");
                this.setState({
                    isAnnulmentModalVisible: false,
                });
                return true;
            }
        } catch (err) {
            message.error("Ocorreu um erro");
            return false;
        }
    };

    handleProcessAnnulmentCancel = (e) => {
        this.setState({
            isAnnulmentModalVisible: false,
        });
    };

    goTo = ({ pathname, queryParams }) => {
        let search = "";

        if (queryParams !== undefined) {
            search = Object.keys(queryParams).reduce((acumulator, current) => {
                acumulator +=
                    acumulator.length === 0
                        ? `?${current}=${queryParams[current]}`
                        : `&${current}=${queryParams[current]}`;
                return acumulator;
            }, "");
        }

        // ;
        this.props.history.push({
            pathname: pathname,
            search,
        });
    };

    handleCancel = (e) => {
        this.setState({
            isReassignModalVisible: false,
        });
    };

    handleTabChange = (tabKey) => {
        const filterParams = { ...this.state.filters };

        if (this.state.establishment && !filterParams.establishment) {
            filterParams.establishment = this.state.establishment;
        }

        if (filterParams.processoDecision) {
            filterParams.processName = filterParams.processoDecision;
        }

        if (Utilitaries.isEmpty(filterParams.establishment)) {
            message.warning("Selecione um estabelecimento");
            return;
        }

        this.setState({ activeTabKey: tabKey, isLoading: true, currentPage: 1 });

        if (tabKey === "em_curso") {
            this.props
                .getDoingTasks({ accao: tabKey, pagina: 1, resultados_pagina: this.state.resultPerPage, filterParams })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        } else if (tabKey === "terminadas") {
            this.props
                .getDoneTasks({ accao: tabKey, pagina: 1, resultados_pagina: this.state.resultPerPage, filterParams })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        } else if (tabKey === "nao_atribuidas") {
            this.props
                .getTodoTasks({ accao: tabKey, pagina: 1, resultados_pagina: this.state.resultPerPage, filterParams })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        }
    };

    handlePaginationChange = (tabKey, page, resultados_pagina = 12) => {
        const filterParams = { ...this.state.filters };

        if (this.state.establishment && !filterParams.establishment) {
            filterParams.establishment = this.state.establishment;
        }

        if (filterParams.processoDecision) {
            filterParams.processName = filterParams.processoDecision;
        }

        if (Utilitaries.isEmpty(filterParams.establishment)) {
            message.warning("Selecione um estabelecimento");
            return;
        }

        this.setState({ isLoading: true });
        if (tabKey === "em_curso") {
            this.props.getDoingTasks({ accao: tabKey, pagina: page, resultados_pagina, filterParams }).then(() => {
                this.setState({ isLoading: false });
            });
        } else if (tabKey === "terminadas") {
            this.props.getDoneTasks({ accao: tabKey, pagina: page, resultados_pagina, filterParams }).then(() => {
                this.setState({ isLoading: false });
            });
        } else if (tabKey === "nao_atribuidas") {
            this.props.getTodoTasks({ accao: tabKey, pagina: page, resultados_pagina, filterParams }).then(() => {
                this.setState({ isLoading: false });
            });
        }
    };

    setFilters = (filters) => {
        this.setState({ filters });
    };

    handleFilter = (filterParams) => {
        if (this.state.establishment && !filterParams.establishment) {
            filterParams.establishment = this.state.establishment;
        }

        if (filterParams.processoDecision) {
            filterParams.processName = filterParams.processoDecision;
        }

        if (Utilitaries.isEmpty(filterParams.establishment)) {
            message.warning("Selecione um estabelecimento");
            return;
        }

        const pagina = 1;
        this.setState({ isLoading: true, currentPage: pagina });

        if (this.state.activeTabKey === "em_curso") {
            this.props
                .getDoingTasks({
                    accao: this.state.activeTabKey,
                    pagina,
                    resultados_pagina: this.state.resultPerPage,
                    filterParams,
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        }

        if (this.state.activeTabKey === "nao_atribuidas") {
            this.props
                .getTodoTasks({
                    accao: this.state.activeTabKey,
                    pagina,
                    resultados_pagina: this.state.resultPerPage,
                    filterParams,
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        }
        if (this.state.activeTabKey === "terminadas") {
            this.props
                .getDoneTasks({
                    accao: this.state.activeTabKey,
                    pagina,
                    resultados_pagina: this.state.resultPerPage,
                    filterParams,
                })
                .then(() => {
                    this.setState({ isLoading: false });
                });
        }
    };

    handleReassignTasks = async (tasks) => {
        if (tasks.length === 0) {
            message.warn("Nenhuma linha foi selecionada");
            return false;
        }
        try {
            const result = await reassignTasks(tasks);

            if (!result || result.erro) {
                message.error(result.erro || "Ocorreu um erro");
                return false;
            } else {
                await this.props.getTasksToReassign();
                await this.props.getGroupsToDelegate();
                message.success("Sucesso");
                this.setState({
                    isReassignModalVisible: false,
                });
                return true;
            }
        } catch (err) {
            message.error("Ocorreu um erro");
            return false;
        }
    };

    handleGetUserByGroup = ({ group, task }) => {
        this.props.getUserByGroup({ group, task });
    };

    handleGroupUserToDelegateChange = ({ owner, id, task }) => {
        this.props.setGroupUserToDelegate({ owner, id, task });
    };

    handleGetTask = async ({ processId, taskId, processParentId, taskFormParams }) => {
        // const processes = this.props.processExecution.domain.byId;
        // if (!processes?.[processId] || !processes?.[processId]?.tasks?.[taskId]) {
        //     await this.props.getTask({ processId, taskId, isInit: true });
        // }

        let urlParams = {};

        if (!Utilitaries.isEmpty(taskFormParams) && taskFormParams !== "undefined") {
            urlParams = JSON.parse(taskFormParams);
        }

        this.goTo({
            pathname: `/task/execution/map/${processId}`,
            queryParams: {
                taskId,
                routeContext: "panel",
                pageAction: "panel",
                processParentId: Utilitaries.toString(processParentId),
                ...urlParams,
            },
        });
    };

    getTaskReassignmentHistory = ({ tarefa, tab }) => {
        this.props.getTaskReassignmentHistory({ tarefa, tab });
    };
}

const mapStateToProps = (state) => {
    return {
        tasks: state.taskPanel,
        processExecution: state.processExecution,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ ...actionCreators, ...acPanel, ...acExecution }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskPanel);
