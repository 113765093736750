export const toPascalCase = (text, space = false) => {
    // if (this.match(/\d*/gi)) {
    // 	return this;
    // }
    return text
        .match(/[a-z]+/gi)
        ?.map(function (word) {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        })
        .join(space ? " " : "");
};

export const isNumeric = (str) => {
    try {
        if (typeof str === "number") return true;
        if (typeof str !== "string") return false; // we only process strings!
        return !isNaN(str) && !isNaN(parseFloat(str));
    } catch (error) {
        return false;
    }
};

export const toString = (value, joinBy = " ") => {
    if (typeof value === "object" || typeof value === "undefined" || value === null) {
        if (Array.isArray(value)) {
            return value.join(joinBy);
        }
        return "";
    }

    if (typeof value === "number") {
        return value.toString();
    }

    if (typeof value === "string") {
        if (value.match(/^\{\$param\.(.*)\}$/)) {
            return value.replace(/^\{\$param\.(.*)\}$/, "");
        }
        return value.trim();
    }

    return value;
};

export const toNumber = (value, undefinedForEmpty = false) => {
    try {
        if (undefinedForEmpty && (value === undefined || value === null)) {
            return undefined;
        }

        if (typeof value === "number") {
            return value;
        }

        if (typeof value === "string") {
            return parseFloat(value);
        }

        return undefinedForEmpty ? undefined : 0;
    } catch (error) {
        return undefinedForEmpty ? undefined : 0;
    }
};

export const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true;
    } else if (typeof value === "boolean" || typeof value === "number") {
        return false;
    } else if (
        typeof value === "object" &&
        ((Array.isArray(value) && value.length === 0) || Object.keys(value).length === 0)
    ) {
        return true;
    } else if (!value) {
        return true;
    } else if (typeof value === "string" && !value.trim()) {
        return true;
    }

    return false;
};

export const toArray = (data, splitStringIn = " ") => {
    if (data) {
        if (typeof data === "object") {
            if (!Array.isArray(data)) {
                if (Object.keys(data).length === 0) {
                    return [];
                }

                return [data];
            }
        } else if (typeof data === "string") {
            return data.split(splitStringIn);
        }
        return data;
    }

    return [];
};

export const orderByDate = (a, b, order) => {
    if (isEmpty(a)) {
        return -1;
    }

    if (isEmpty(b)) {
        return 1;
    }

    const dateA = new Date(a);
    const dateB = new Date(b);

    if (dateA < dateB) {
        return order === "asc" ? -1 : 1;
    }

    if (dateA > dateB) {
        return order === "asc" ? 1 : -1;
    }

    return 0;
};

export const orderBy = ({ list, key, order = "asc", byDate = false }) => {
    list = toArray(list);

    return list.sort((a, b) => {
        if (byDate) {
            return orderByDate(a[key], b[key], order);
        }
        if (a[key] < b[key]) {
            return order === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return order === "asc" ? 1 : -1;
        }
        return 0;
    });
};

export const toBoolean = (data) => {
    if (typeof data === "object" && !Array.isArray(data)) {
        data = data?.["$"];
    }
    if (typeof data === "boolean") {
        return data;
    }

    if (data === "true" || data === "t") {
        return true;
    }

    return false;
};

export const isEmptyArrayItems = (value) => {
    if (
        value === null ||
        value === undefined ||
        typeof value !== "object" ||
        !Array.isArray(value) ||
        Object.keys(value).length === 0
    ) {
        return true;
    } else {
        const realItens = value?.filter((it) => !isEmpty(it));

        if (isEmpty(realItens)) {
            return true;
        }
    }

    return false;
};

export const isValidateNIF_PT = (nif) => {
    // algoritmo de validação do NIF de acordo com
    // http://pt.wikipedia.org/wiki/N%C3%BAmero_de_identifica%C3%A7%C3%A3o_fiscal
    nif = toString(nif);

    if (!nif || nif === "" || nif === "999999990") {
        return true;
    }

    if (nif.startsWith("$")) {
        return true;
    }

    if (nif.length !== 9) {
        return false;
    }

    if (
        nif.substr(0, 1) !== "1" && // pessoa singular
        nif.substr(0, 1) !== "2" && // pessoa singular
        nif.substr(0, 1) !== "3" && // pessoa singular
        nif.substr(0, 2) !== "45" && // pessoa singular não residente
        nif.substr(0, 1) !== "5" && // pessoa colectiva
        nif.substr(0, 1) !== "6" && // administração pública
        nif.substr(0, 2) !== "70" && // herança indivisa
        nif.substr(0, 2) !== "71" && // pessoa colectiva não residente
        nif.substr(0, 2) !== "72" && // fundos de investimento
        nif.substr(0, 2) !== "77" && // atribuição oficiosa
        nif.substr(0, 2) !== "79" && // regime excepcional
        nif.substr(0, 1) !== "8" && // empresário em nome individual (extinto)
        nif.substr(0, 2) !== "90" && // condominios e sociedades irregulares
        nif.substr(0, 2) !== "91" && // condominios e sociedades irregulares
        nif.substr(0, 2) !== "98" && // não residentes
        nif.substr(0, 2) !== "99" // sociedades civis
    ) {
        return false;
    }

    var check1 = nif.substr(0, 1) * 9;
    var check2 = nif.substr(1, 1) * 8;
    var check3 = nif.substr(2, 1) * 7;
    var check4 = nif.substr(3, 1) * 6;
    var check5 = nif.substr(4, 1) * 5;
    var check6 = nif.substr(5, 1) * 4;
    var check7 = nif.substr(6, 1) * 3;
    var check8 = nif.substr(7, 1) * 2;

    let comparador;

    var total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
    var divisao = total / 11;
    var modulo11 = total - parseInt(divisao) * 11;

    if (modulo11 === 1 || modulo11 === 0) {
        comparador = 0;
    } // excepção
    else {
        comparador = 11 - modulo11;
    }

    var ultimoDigito = nif.substr(8, 1) * 1;
    if (ultimoDigito !== comparador) {
        return false;
    }

    return true;
};

export const isValidateNISS_PT = (niss) => {
    // algoritmo de validação do NISS de acordo com
    // https://gist.github.com/diogoalexsmachado/581d6aab90acb2164a072bfd215d5534
    niss = toString(niss);

    if (niss === "" || niss === "00000000000") {
        return true;
    }

    if (niss.length !== 11) {
        return false;
    } else {
        var FACTORS = [29, 23, 19, 17, 13, 11, 7, 5, 3, 2];
        var nissArray = [];
        for (var i = 0; i < niss.length; i++) {
            nissArray[i] = niss.charAt(i);
        }

        var sum = 0;

        //faz a soma do digito [j] x o dígito [j] do array dos fatores
        for (var j = 0; j < FACTORS.length; j++) {
            sum += nissArray[j] * FACTORS[j];
        }

        //verifica se dá resto 0
        if (nissArray[nissArray.length - 1] == 9 - (sum % 10)) {
            return true;
        } else {
            return false;
        }
    }
};

export const compareArrays = (array1, array2) => {
    if (!array1 || !array2) {
        return false;
    }

    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0, l = array1.length; i < l; i++) {
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            if (!compareArrays(array1[i], array2[i])) {
                return false;
            }
        } else if (array1[i] != array2[i]?.value) {
            return false;
        }
    }
    return true;
};

export const handleValues = (values) => {
    if (typeof values === "number") {
        return values;
    } else if (typeof values === "string") {
        return values;
    } else if (typeof values === "object") {
        return "";
    }

    return values;
};

export const removeDuplicates = (list, key) => {
    const listToClean = toArray(list);

    const cleanList = [];

    listToClean.forEach((item) => {
        if (key) {
            if (!cleanList.find((i) => i[key] === item[key])) {
                cleanList.push(item);
            }
        } else {
            if (!cleanList.includes(item)) {
                cleanList.push(item);
            }
        }
    });

    return cleanList;
};

export const nullSafe = (value) => {
    if (typeof value === "object") {
        return value;
    }

    if (typeof value === "number") {
        return value;
    }

    if (
        value === null ||
        value === undefined ||
        value === "null" ||
        (typeof value === "object" && Array.isArray(value) && value.length === 0) ||
        value?.startsWith("$dados_") ||
        value?.startsWith("$dados.") ||
        value?.startsWith("$destin.") ||
        value?.startsWith("$param.")
    ) {
        return "";
    }

    if (value === "undefined" || value === "NaN") {
        return "";
    }

    if (typeof value === "number" && isNaN(value)) {
        return "";
    }

    return value;
};
