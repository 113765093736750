import { actionType, lista } from "../constants/time.registration";
import { sendHttpRequest } from "../../../components/http.request";
import { tableActions } from "./../constants/time.registration";
import { Session } from "../../../business";

const formatDateTime = "DD-MM-YYYY HH:mm";

export const select = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "lista");
    formData.append("lista", lista);
    formData.append("username", Session.getUsername());
    Object.entries(payload).forEach(([key, val]) => {
        formData.append(key, val);
    });
    // formData.append("resultados_pagina", 0);
    // formData.append("mostra_lista_eliminados", payload.mostra_lista_eliminados);
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        if (jsonResponse)
            dispatch({
                type: actionType.select,
                payload: jsonResponse["dados"],
            });
    });
};

export const getForm = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista);
    await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({ type: actionType.getForm, payload: jsonResponse });
    });
};

export const insert = (payload) => async (dispatch) => {
    const formData = new FormData();
    const obj = {};
    const hora_entrada = payload.values.hora_entrada.format(formatDateTime);
    const system_data_entrada = payload.values.system_data_entrada.format(formatDateTime);

    obj[payload.formulario.elemento_raiz] = {
        username: Session.getUsername(),
        system_data_entrada: system_data_entrada,
        data_registo: payload.values.system_data_entrada.format("YYYY-MM-DD"),
        hora_entrada: hora_entrada,
        observacoes: payload.values.observacoes,
        motivo_alteracao_data_entrada:
            hora_entrada !== system_data_entrada ? payload.values.motivo_alteracao_data : null,
    };
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        if (jsonResponse.sucesso) {
            dispatch({ type: actionType.insert });
            return true;
        } else {
            return false;
        }
    });
};

export const setField = (payload) => (dispatch) => {
    //todo: update in api, then store
    dispatch({ type: actionType.setField, payload });
};

export const getById = (payload) => async (dispatch) => {
    const formData = new FormData();
    formData.append("accao", "dados");
    formData.append("lista", lista);
    formData.append("chave1", payload.id);
    return await sendHttpRequest("POST", "/Gestor/gerelistas.php", formData).then((jsonResponse) => {
        dispatch({
            type: actionType.getById,
            payload: { ...payload, response: jsonResponse },
        });
    });
};

export const update = (payload) => async (dispatch) => {
    const formData = new FormData();
    const obj = {};

    const hora_entrada = payload.values.hora_entrada.format(formatDateTime);
    const system_data_entrada = payload.values.system_data_entrada.format(formatDateTime);

    let hora_saida = null;
    let system_data_saida = null;

    if (payload.tableAction === tableActions.editar && payload.values.hora_saida) {
        hora_saida = payload.values.hora_saida.format(formatDateTime);
        system_data_saida = payload.values.system_data_saida.format(formatDateTime);
    } else if (payload.tableAction === tableActions.marcarSaida) {
        hora_saida = payload.values.hora_saida.format(formatDateTime);
        system_data_saida = payload.values.system_data_saida.format(formatDateTime);
    }

    const values = {
        ...payload.values,
        hora_entrada,
        hora_saida,
        system_data_entrada,
        system_data_saida,
    };
    obj[payload.formulario.elemento_raiz] = values;
    formData.append("accao", "guardar");
    formData.append("formulario", payload.formulario.nome);
    formData.append("dados", JSON.stringify(obj));

    return await sendHttpRequest("POST", "/Gestor/gereprocesso.php", formData).then((jsonResponse) => {
        return jsonResponse.sucesso ? true : false;
    });
};
