import React from "react";

const LayoutQM = ({ children, defaultStyle = {} }) => {
    return (
        <div id="login-root-page">
            <div id="login-root-content">{children}</div>
        </div>
    );
};

export default LayoutQM;
