import { Utilitaries } from "../../business";
import { FormInputsObjectProps, FormInputsProps, FormRootElementAttributesProps } from "../types/formTypes";
import { COMPONENT_TYPE } from "./FormMapper/XMLInputType";

// export const getTable = ({ xpath, formData }: SumConversionProps) => {
//     let table: FormInputsProps = {} as FormInputsProps;
//     for (const iterator in formData) {
//         if (formData[iterator].key === "zonas_despesa_condom") {
//             table = formData[iterator];
//             break;
//         }
//     }
//     return table;
// };

// expression to get table
// sum(//valor_zona)
// count(//cald_pontual[permilagem != '65'])
// x_lista://zona_despesa_condom/uo[../uo != '']

export const ValidateEmail = (value?: string) => {
    if (!value) {
        return false;
    }
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(validRegex)) {
        return true;
    } else {
        return false;
    }
};

interface getElementByRootPahtProps {
    rootPath: String;
    form: FormInputsObjectProps;
}

export const getElementByRootPath = ({ rootPath, form }: getElementByRootPahtProps) => {
    for (const iterator in form) {
        const element = form[iterator];
        if (iterator === "DATA_SOURCE" || !element || typeof element !== "object") {
            continue;
        }

        if (
            [
                COMPONENT_TYPE.DATAGRID,
                COMPONENT_TYPE.DYNAMIC_DATAGRID,
                COMPONENT_TYPE.DYNAMIC_TABLE,
                COMPONENT_TYPE.SELECTION_TABLE,
            ].includes(Utilitaries.toString(element.type))
        ) {
            if (element.key === rootPath) {
                return element;
            } else {
                if (element[rootPath.toString()]) {
                    return element;
                } else {
                    //get element in deep
                    const checkInDeep = getElementByRootPath({ rootPath, form: element });
                    if (checkInDeep) {
                        return element;
                    }
                }
            }
        } else {
            if (element.key === rootPath) {
                return element;
            } else {
                const hasDeepObject = Object.values(element).find((f) => typeof f === "object");
                if (typeof element === "object" && !hasDeepObject) {
                    continue;
                } else {
                    const table: any = getElementByRootPath({ rootPath, form: element });
                    if (table) {
                        return table;
                    }
                }
            }
        }
    }
    return null;
};

interface GetTableByRootPathProps {
    xpath: String;
    formState: FormInputsObjectProps;
}

export const getTableByRootPath = ({ xpath, formState }: GetTableByRootPathProps) => {
    const rootPath = xpath.match(/\w+\s*(?=)/g)?.[1];
    if (!rootPath) {
        return null;
    }
    const element = getElementByRootPath({ rootPath, form: formState });
    if (
        [
            COMPONENT_TYPE.DATAGRID,
            COMPONENT_TYPE.DYNAMIC_DATAGRID,
            COMPONENT_TYPE.DYNAMIC_TABLE,
            COMPONENT_TYPE.SELECTION_TABLE,
        ].includes(Utilitaries.toString(element.type))
    ) {
        return element;
    }
    return null;
};

interface dataGridDependenciesProps {
    state?: FormInputsObjectProps;
    componentProperties: FormInputsProps;
}

export const dataGridDependenciesValues = ({ componentProperties, state }: dataGridDependenciesProps) => {
    let dependencies: string[] = [];

    if (
        componentProperties.type === COMPONENT_TYPE.DATAGRID ||
        componentProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID
    ) {
        if (!state) return [];
        if (componentProperties?.["@carregar_accao"] && componentProperties?.["@carregar_accao"] === "carrega")
            return [];

        const list = componentProperties.popup_param?.split(";").filter((f) => f) || [];
        dependencies = list.map((el) => state[el]?.value);
        return dependencies;
    }
    return dependencies;
};

export const dataGridParamsDependencies = ({ componentProperties, state }: dataGridDependenciesProps) => {
    let dependencies: any = {};

    if (
        componentProperties.type === COMPONENT_TYPE.DATAGRID ||
        componentProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID
    ) {
        if (!state) return [];
        if (componentProperties?.["@carregar_accao"] && componentProperties?.["@carregar_accao"] === "carrega")
            return [];

        const list = componentProperties.popup_param?.split(";").filter((f) => f) || [];
        dependencies = list.reduce((acumulator: any, current: any) => {
            if (state[current]?.value) {
                acumulator[current] = state[current]?.value;
            }
            return acumulator;
        }, {});

        dependencies.lista = componentProperties.data_query;
        dependencies.resultados_pagina = componentProperties.result_per_page;
        dependencies.accao = "lista";
        return dependencies;
    }
    return dependencies;
};

interface SumConversionProps {
    xpath: String;
    formState?: FormInputsObjectProps;
}

export const sumConvertion = ({ xpath, formState }: SumConversionProps) => {
    if (Utilitaries.isEmpty(xpath) || !formState) {
        return 0;
    }
    const table = getTableByRootPath({ xpath, formState });

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

        const xpathMatch = xpath.match(regex);

        if (!xpathMatch) {
            return 0;
        }

        let conditionXpath: any = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let fieldToSum: any = xpath.match(/\w*\s*(?=\[)/g);

            if (!fieldToSum) {
                fieldToSum = conditionXpath;
            } else {
                fieldToSum = fieldToSum[0];
            }

            let allFieldNames = xpathMatch.filter((item) => item).map((it) => it.trim());
            const tableValues: any[] = Object.values(table.DATA_SOURCE || {});

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    const fieldValue =
                        typeof current[fieldName] === "object"
                            ? `current["${fieldName}"]?.id`
                            : `current["${fieldName}"]`;
                    condition = condition.replace(fieldName, fieldValue);
                });

                const check = eval(condition);
                if (check) {
                    let value = current[fieldToSum];
                    if (value && typeof value === "object") {
                        value = value?.id;
                    }
                    accumulator += Number(value);
                }
                return accumulator;
            }, 0);
            return countValue;
        }
    }
    return 0;
};
/** Teste */
/**
 * Garantir que funcione
 * count(//perfil_geral_indicador[../grau_freq_apoio != ../grau_nao_ap])
 * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
 * count(//requisito_forn/avaliacao = 'NC')
 * count(//requisito_forn/id_requisito_aval_forn)
 * count(//requisito_forn/avaliacao = 'NA')
 * count(//requisito_forn/avaliacao != 'NA')
 * count(//requisito_forn/avaliacao > 'NA')
 * count(//requisito_forn/avaliacao < 'NA')
 * count(//requisito_forn/avaliacao)
 */

interface CountConversionProps {
    xpath: String;
    formState?: FormInputsObjectProps;
}

export const countConvertion = ({ xpath, formState }: CountConversionProps) => {
    if (Utilitaries.isEmpty(xpath) || !formState) {
        return 0;
    }
    const table = getTableByRootPath({ xpath, formState });

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

        const xpathMatch = xpath.match(regex);

        if (!xpathMatch) {
            return 0;
        }

        let conditionXpath: any = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let allFieldNames = xpathMatch.filter((item) => item).map((it) => it.trim());
            const tableValues: any[] = Object.values(table.DATA_SOURCE || {});

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    const fieldValue =
                        typeof current[fieldName] === "object"
                            ? `current["${fieldName}"]?.id`
                            : `current["${fieldName}"]`;
                    condition = condition.replace(fieldName, fieldValue);
                });
                const check = eval(condition);
                if (check) {
                    accumulator += 1;
                }
                return accumulator;
            }, 0);
            return countValue;
        }
    }
    return 0;
};

interface getxListProps {
    xpath: String;
    formState?: FormInputsObjectProps;
}

export const getXList = ({ xpath, formState }: getxListProps) => {
    // garantir que funcione
    // calcular="x_lista://periodos_med/id_periodo[../seleciona = ''true'']"
    // calcular="x_lista://periodos_med/id_periodo"
    if (Utilitaries.isEmpty(xpath) || !formState) {
        return 0;
    }
    const regex = /\w*\s*(?=(=|\)|!|>|<|$))/g;
    const xpathMatch = xpath.match(regex);

    if (!xpathMatch) {
        return 0;
    }

    let expression = Utilitaries.toString(xpath).substr(8);

    const [tableSecondKey, tableField] = expression.match(/\w+\s*(?=)/g);

    // let table: FormInputsProps = {} as FormInputsProps;
    const table = getTableByRootPath({ xpath, formState });

    if (table) {
        const tableValues: any[] = Object.values(table.DATA_SOURCE || {});

        let conditionXpath: any = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let fieldToAdd: any = xpath.match(/\w*\s*(?=\[)/g);

            if (!fieldToAdd) {
                fieldToAdd = conditionXpath;
            } else {
                fieldToAdd = fieldToAdd[0];
            }

            let allFieldNames = xpathMatch.filter((item) => item).map((it) => it.trim());

            let result = tableValues.reduce((accumulatorValue, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    const fieldValue =
                        typeof current[fieldName] === "object"
                            ? `current["${fieldName}"]?.id`
                            : `current["${fieldName}"]`;
                    condition = condition.replace(fieldName, fieldValue);
                });

                const check = eval(condition);
                if (check) {
                    const valueToAppend =
                        typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField];
                    if (!Utilitaries.isEmpty(valueToAppend)) {
                        accumulatorValue = `${accumulatorValue}${accumulatorValue.length === 0 ? "" : ","}${
                            typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField]
                        }`;
                    }
                }
                return accumulatorValue;
            }, "");
            return result;
        } else {
            let result = tableValues.reduce((accumulatorValue, current) => {
                const valueToAppend =
                    typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField];
                if (!Utilitaries.isEmpty(valueToAppend)) {
                    // accumulatorValue = `${accumulatorValue}${accumulatorValue.length === 0 ? "" : ", "}${
                    //     typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField]
                    // }`;
                    let value = typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField];
                    //if (typeof value === "string") {
                    if (Utilitaries.isNumeric(value) === false) {
                        value = `'${value}'`;
                    }
                    accumulatorValue = `${accumulatorValue}${accumulatorValue.length === 0 ? "" : ","}${value}`;
                }
                return accumulatorValue;
            }, "");
            return result;
        }
    }
    return "";
};

export const round = (x: number) => {
    return Math.round(x * 100) / 100;
    // return Math.round(x * 100 / 100);
};
export const round3 = (x: number) => {
    return Math.round(x * 1000) / 1000;
    // return Math.round(x * 100 / 100);
};

export const roundCustom = (x: number, decimalPlaces = 2) => {
    if (decimalPlaces < 0) {
        return x;
    }
    const totalDecimalPlaces = Math.pow(10, decimalPlaces);
    return Math.round(x * totalDecimalPlaces) / totalDecimalPlaces;
};
// export const round3 = (x: number) => Number(x).toFixed(3);

export const contains = (value: string, validator: string) => {
    // if (value?.includes("state.form")) {
    //     value = eval(`${value}`);
    // }

    // if (value === validator) {
    //     return true;
    // }

    if (value) {
        return value?.includes(validator);
    }

    return false;
};

interface getActionToCallProps {
    formState?: FormInputsObjectProps;
    componentProperties: FormInputsProps;
}

export const actionToCallInFirstRender = ({ componentProperties, formState }: getActionToCallProps) => {
    if (componentProperties.data_query) return null;

    if (componentProperties.type === COMPONENT_TYPE.SELECT) {
        //check action for select (datasource)
        const keys = Object.keys(componentProperties).filter((f) => f.startsWith("@accao_"));
        const action = keys.find((f) => componentProperties[f]?.startsWith("auto_preenche("));
        return action?.replace("@accao_", "");
    } else {
        if (componentProperties.persist === "nao") {
            //check value for datadisplay that has action
            const keys = Object.keys(componentProperties).filter((f) => f.startsWith("@accao_"));
            const action = keys.find(
                (f) =>
                    componentProperties[f]?.startsWith("auto_preenche(") ||
                    componentProperties[f]?.startsWith("auto_preenche_valor(")
            );
            return action?.replace("@accao_", "");
        }
        if (
            [COMPONENT_TYPE.STATIC_LIST].includes(componentProperties.type ?? "") &&
            !formState?.[`id_${componentProperties.form_key}`]
        ) {
            const action = Object.keys(componentProperties).find((f) => f.startsWith("@accao_"));
            if (action && componentProperties.value && componentProperties[action]?.startsWith("auto_preenche_valor")) {
                return null;
            }
            return action ? action?.replace("@accao_", "") : null;
        }

        return null;
    }
};

export const checkCanExecuteActionData = (
    componentProperties: FormInputsProps,
    formState: FormInputsObjectProps,
    triggerDataGridData: number,
    rootElementAttribute: FormRootElementAttributesProps
) => {
    if (!componentProperties.data_query) return false;

    if (
        componentProperties.type === COMPONENT_TYPE.SELECT ||
        componentProperties.persist === "nao" ||
        rootElementAttribute.ignore_data
    ) {
        return true;
    }

    const formId = formState?.[`id_${componentProperties.form_key}`]?.value;

    if (triggerDataGridData || rootElementAttribute.consult_form || !formId) {
        return true;
    } else return false;
    // }

    // if (formState?.[`id_${componentProperties.form_key}`]) {
    //     return false;
    // }

    // return true;
};
interface defaultValueResultProps {
    result: any[];
    componentProperties: FormInputsProps;
    exec_when_value_changed_is_null: boolean;
}
export const getDefaultValueResult = ({
    componentProperties,
    result,
    exec_when_value_changed_is_null,
}: defaultValueResultProps) => {
    if (Utilitaries.isEmpty(result)) return null;
    if (!exec_when_value_changed_is_null && componentProperties.type === COMPONENT_TYPE.SELECT) {
        const defaultValue = result.find((f) => f._por_defeito === "t");
        return defaultValue;
    }
    return null;
};

export const isToSetValueInWhenValueChanged = (expression: string, componentProperties: FormInputsProps) => {
    if (expression.includes("auto_preenche_valor(")) {
        return true;
    }
    if (!expression.includes("auto_preenche(")) {
        return true;
    }

    if (
        componentProperties.type &&
        [
            COMPONENT_TYPE.DATA_DISPLAY,
            COMPONENT_TYPE.STATIC_LIST,
            COMPONENT_TYPE.INPUT,
            COMPONENT_TYPE.INPUT_AREA,
            COMPONENT_TYPE.BOOLEAN,
            COMPONENT_TYPE.DATE,
        ].includes(componentProperties.type)
    ) {
        return true;
    }
    // [
    //     COMPONENT_TYPE.DATAGRID,
    //     COMPONENT_TYPE.DYNAMIC_DATAGRID,
    //     COMPONENT_TYPE.DYNAMIC_TABLE,
    //     COMPONENT_TYPE.SELECTION_TABLE,
    //     COMPONENT_TYPE.SELECT,
    // ];

    return false;
};

interface HighlightsErrorsProps {
    value?: string;
    required: boolean;
    restriction: boolean;
}

export const highlightsErrors = ({ value, required, restriction }: HighlightsErrorsProps) => {
    const styleError = {
        backgroundColor: "#e78080",
        border: "1px solid #e78080",
    };

    if (Utilitaries.isEmpty(value) && required) {
        return styleError;
    }

    if (!restriction) {
        return styleError;
    }
    return {};
};

interface GetErrorTextProp {
    value?: string;
    required: boolean;
    restriction: boolean;
    error_message?: string;
}

export const getErrorText = ({ value, required, restriction, error_message }: GetErrorTextProp) => {
    if (required && Utilitaries.isEmpty(value)) {
        return "Campo obrigatório";
    } else if (!restriction) {
        return error_message || "valor inválido";
    } else return null;
};

interface getRowSpanProps {
    data: any[];
    dataIndex: string;
    text: any;
    currentRowIndex: number;
    rowSpanValue?: string
}

export const getRowSpan = ({ currentRowIndex, data, dataIndex, text, rowSpanValue }: getRowSpanProps): number => {
    if (!rowSpanValue || rowSpanValue !== "true" || Utilitaries.isEmpty(data)) {
        return 1;
    }

    if (currentRowIndex > 0) {
        const prevRow = data[currentRowIndex - 1];
        const currentRow = data[currentRowIndex];
        if (prevRow && currentRow && prevRow[dataIndex] === currentRow[dataIndex]) {
            return 0;
        }
    }
    let rowSpan = 1;
    for (let i = currentRowIndex + 1; i < data.length; i++) {
        if (data[i][dataIndex] === text) {
            rowSpan++;
        } else {
            break;
        }
    }
    return rowSpan;
};
