import { Button, Icon } from "antd";
import React from "react";
import { Utilitaries } from "../../../../business";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { CommunComponentsProps, InputInTableProps } from "../../../types/common";
import { FormInputsProps } from "../../../types/formTypes";
import { FormLabel } from "../Components/FormLabel";
import { Group } from "../Components/Group";
import { getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";
// import { componentType, xmlProps } from "../../constants";
// import { xmlProps, componentType as ComponentType, componentType } from "../../constants";

interface ComponentsPropertiesProps extends CommunComponentsProps {
    changeModalState: (key: FormInputsProps, modalState: boolean, tablwRow?: InputInTableProps) => void;
    buttonClick: (input: FormInputsProps, rowTable?: InputInTableProps) => void;
}

const QAButtonComponent: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    formState,
    inputCalcTrigger,
    changeModalState,
    tableRow,
    buttonClick,
    justSee,
}) => {
    print_when_component_is_rendered(componentsProperties);

    // const { label } = getLabel({ componentsProperties, renderContext, required: false });
    // const { buttonClick } = useFormState();

    const readonly = false;

    const getIcon = () => {
        if (componentsProperties.image?.endsWith("calendar-icon.gif")) {
            return "schedule";
        }
        if (componentsProperties.popupform) {
            return "form";
        }
        return "reload";
    };

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);

    const {
        // data: whenValueChangeData,
        // status: whenValueChangeStatus,
        // componentValue,
        // hasUpdatedValue,
        required,
        visible,
        relevant,
        readOnly,
    } = useInputHandle({
        componentProperties: componentsProperties,
        // when_value_changed_dependence: formInputDependenceState?.[componentsProperties?.full_path || ""],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
    });

    if (!visible || !relevant) {
        return null;
    }

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    if (componentsProperties.popupform) {
        return (
            <>
                <Group componentsProperties={componentsProperties} renderContext={renderContext}>
                    <FormLabel
                        componentsProperties={componentsProperties}
                        required={required}
                        renderContext={renderContext}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                disabled={componentsProperties.readonly ? readonly : false}
                                icon={getIcon()}
                                onClick={() => changeModalState(componentsProperties, true, tableRow)}
                            />
                            {!Utilitaries.isEmpty(value) && (
                                <Icon
                                    style={{
                                        color: "green",
                                        marginLeft: 10,
                                    }}
                                    type="check"
                                />
                            )}
                        </div>
                        {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
                    </FormLabel>
                </Group>
            </>
        );
    }
    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                <Button
                    icon="reload"
                    disabled={justSee || readOnly}
                    // disabled={currentTaskStatus || readonly}
                    onClick={() => {
                        buttonClick(componentsProperties, tableRow);
                    }}
                />
                {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
            </FormLabel>
        </Group>
    );
};

// export const QAButton = memo(QAButtonComponent, compareProps);
// export const QAButton = QAButtonComponent;
export const QAButton = QAButtonComponent; //memo(QAButtonComponent, (prev, next) => compareProps<ComponentsPropertiesProps>(prev, next));
