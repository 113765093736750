import { Utilitaries } from "../../../../business";
import { InputInTableProps } from "../../../types/common";
import {
    FormInputCalcTriggerProps,
    FormInputDependenceStateProps,
    FormInputsObjectProps,
    FormInputsProps,
    RenderContextType,
} from "../../../types/formTypes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    contains as functionContains,
    round as roundNumber,
    roundCustom,
    ValidateEmail,
} from "../../../utils/functions";
import { regexValidate } from "./regexConversion";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { expressao_lenght as expressao_lenght_function } from "./regularExpressionHandle";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isValidateNIF_PT = Utilitaries.isValidateNIF_PT;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isValidateNISS_PT = Utilitaries.isValidateNISS_PT;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const round = (x: number) => roundNumber(x);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const round5 = (x: number) => roundCustom(x, -1);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const contains = functionContains;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const expressao_lenght = expressao_lenght_function;

interface InputValidateProps {
    componentProperties: FormInputsProps;
    formState?: FormInputsObjectProps;
    rowTable?: InputInTableProps;
    value?: string;
}
export function checkInputRequired({ componentProperties, formState, rowTable }: InputValidateProps): boolean {
    if (!componentProperties.required) {
        return false;
    }

    if (componentProperties.required === "true" || componentProperties.required === "true()") {
        return true;
    }

    const result = regexValidate({
        expression: componentProperties.required,
        componentProperties,
        formState,
        rowTable,
    });

    console.log("regex", { result, componentProperties });
    try {
        const value = eval(String(result));

        return value;
    } catch (error) {
        console.log("error", error);
        return false;
    }
}

export function checkInputRelevant({ componentProperties, formState, rowTable }: InputValidateProps): boolean {
    if (!componentProperties.relevant) {
        return true;
    }

    const result = regexValidate({
        expression: componentProperties.relevant,
        componentProperties,
        formState,
        rowTable,
    });

    console.log("regex", { result, componentProperties });
    try {
        const value = eval(String(result));

        return value === "f" ? false : value;
        // return value;
    } catch (error) {
        console.log("error", error);
        return true;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const comparar_datas = (dates: string) => {
    const formatedDate = dates.split(/([<|>|=|!]+)/);
    let firstDateText = Utilitaries.toString(formatedDate[0]).replace(/\s/g, "").replace(/'/g, "");
    const comparator = Utilitaries.toString(formatedDate[1]).replace(/\s/g, "");
    let secondDateText = Utilitaries.toString(formatedDate[2]).replace(/\s/g, "").replace(/'/g, "");

    if (comparator === "<") {
        return new Date(firstDateText) < new Date(secondDateText);
    } else if (comparator === "<=") {
        return new Date(firstDateText) <= new Date(secondDateText);
    } else if (comparator === ">") {
        return new Date(firstDateText) > new Date(secondDateText);
    } else if (comparator === ">=") {
        return new Date(firstDateText) >= new Date(secondDateText);
    } else if (comparator === "=") {
        return new Date(firstDateText) === new Date(secondDateText);
    } else if (comparator === "!=") {
        return new Date(firstDateText) !== new Date(secondDateText);
    }

    return true;
};

export function checkInputRestriction({
    componentProperties,
    formState,
    rowTable,
    value,
}: InputValidateProps): boolean {
    if (!componentProperties.restriction && (!componentProperties.mask || componentProperties.mask !== "email")) {
        return true;
    }

    let isValid: any;

    if (componentProperties.restriction) {
        let result = regexValidate({
            expression: componentProperties.restriction,
            componentProperties,
            formState,
            rowTable,
        });

        // replace nif  to function to validate nif
        result = result.replace(/(?<=(\s|^))nif(?=($|\s))/, ` isValidateNIF_PT('${value}') `);
        result = result.replace(/(?<=(\s|^))niss(?=($|\s))/, ` isValidateNISS_PT('${value}') `);

        // prepare the function to compare date
        const formatedExpression = String(Utilitaries.toString(result)).replace(
            /(?<=comparar_datas\()(.+?)(?=\))/g,
            '"$&"'
        );
        // "comparar_datas('' >= '') && '' != ''"

        try {
            isValid = eval(String(formatedExpression));

            if (!isValid) {
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return true;
        }
    }

    if (componentProperties.mask === "email") {
        //validate email
        if (Utilitaries.isEmpty(value)) {
            return true;
        }
        return ValidateEmail(value);
    }

    return isValid;
}

export function inputCalculate({ componentProperties, formState, rowTable }: InputValidateProps): any {
    if (!componentProperties.calculate) {
        return 0;
    }

    const resultExpression = regexValidate({
        expression: componentProperties.calculate,
        componentProperties,
        formState,
        rowTable,
    });

    console.log("regex", { resultExpression });
    let result = null;
    try {
        if (componentProperties.calculate.startsWith("x_lista:")) {
            result = resultExpression;
        } else {
            result = typeof resultExpression === "string" ? eval(resultExpression) : resultExpression;
        }
    } catch (error) {
        console.log("error -- ", error);
    }
    result = result === true ? "t" : result === false ? "f" : result;
    return result;
}

export function checkInputVisibility({ componentProperties, formState, rowTable }: InputValidateProps): boolean {
    if (!componentProperties.visible) {
        return true;
    }

    if (componentProperties.visible === "nao" || componentProperties.required === "nao()") {
        return false;
    }

    const result = regexValidate({
        expression: componentProperties.visible,
        componentProperties,
        formState,
        rowTable,
    });

    console.log("regex", { result, componentProperties });
    try {
        const value = eval(String(result));

        return value;
    } catch (error) {
        console.log("error", error);
        return true;
    }
}

export function checkInputReadOnly({ componentProperties, formState, rowTable }: InputValidateProps): boolean {
    if (!componentProperties.read_only) {
        return false;
    }

    if (
        componentProperties.read_only === "true" ||
        componentProperties.read_only === "true()" ||
        componentProperties.read_only === "sim"
    ) {
        return true;
    }

    const result = regexValidate({
        expression: componentProperties.read_only,
        componentProperties,
        formState,
        rowTable,
    });

    console.log("regex", { result, componentProperties });
    try {
        const value = eval(String(result));

        return value;
    } catch (error) {
        console.log("error", error);
        return false;
    }
}

const checkIsTheSameDependence = <T extends GenericProps>(
    prevProps: T,
    nextProps: T,
    full_path: string | undefined
) => {
    if (full_path) {
        const inputDependence = nextProps.formInputDependenceState?.[full_path];
        if (inputDependence)
            if (
                inputDependence &&
                prevProps.formInputDependenceState?.[full_path]?.trigger !== inputDependence?.trigger
            ) {
                return false;
            }

        const inputCalcDependence = nextProps.inputCalcTrigger?.[full_path];

        if (inputCalcDependence) {
            const prevInputCalcDependence = prevProps.inputCalcTrigger?.[full_path];
            if (prevInputCalcDependence?.calculateTrigger !== inputCalcDependence?.calculateTrigger) {
                return false;
            }
            if (prevInputCalcDependence?.relevantTrigger !== inputCalcDependence?.relevantTrigger) {
                return false;
            }
            if (prevInputCalcDependence?.visibleTrigger !== inputCalcDependence?.visibleTrigger) {
                return false;
            }
            if (prevInputCalcDependence?.restrictionTrigger !== inputCalcDependence?.restrictionTrigger) {
                return false;
            }

            if (prevInputCalcDependence?.requiredTrigger !== inputCalcDependence?.requiredTrigger) {
                return false;
            }
            if (prevInputCalcDependence?.readOnlyTrigger !== inputCalcDependence?.readOnlyTrigger) {
                return false;
            }
        }

        //TODO: verify for others case here
        // return false;
    }
    return true;
};

export const getFullPathRow = (componentProperties: FormInputsProps, rowKey?: string) => {
    if (rowKey) {
        return `${componentProperties.full_path}_${rowKey}`;
    }
    return "";
};

interface GenericProps {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
    formState: FormInputsObjectProps;
    formInputDependenceState?: FormInputDependenceStateProps;
    inputCalcTrigger: FormInputCalcTriggerProps;
    tableRow?: InputInTableProps;
    triedSave: boolean;
}
export const compareProps = <T extends GenericProps>(prevProps: T, nextProps: T) => {
    if (
        prevProps.componentsProperties.key === "arruamento" &&
        nextProps.componentsProperties.root === "morada_correspondencia"
    ) {
        return false;
    }

    if (prevProps.triedSave !== nextProps.triedSave) {
        return false;
    }

    const full_path = prevProps.componentsProperties.full_path;

    let result = checkIsTheSameDependence(prevProps, nextProps, full_path);
    if (!result) return false;

    const fullpathRow = getFullPathRow(prevProps.componentsProperties, nextProps.tableRow?.row_key);

    if (fullpathRow) {
        result = checkIsTheSameDependence(prevProps, nextProps, fullpathRow);
        if (!result) return false;
    }

    if (nextProps.renderContext === "column") {
        return (
            prevProps.tableRow?.row_data?.[prevProps.componentsProperties.key] ===
            nextProps.tableRow?.row_data?.[nextProps.componentsProperties.key]
        );
    }

    return prevProps.componentsProperties.value === nextProps.componentsProperties.value;
};

interface MakeObjectFromPathProps {
    object?: any;
    path: string[];
    key: string;
    value: any;
    dataSource: any;
    forceValue: boolean;
}
export const makeObjectFromPath = ({
    object = {},
    path = [],
    key,
    value,
    dataSource = null,
    forceValue = false,
}: MakeObjectFromPathProps) => {
    if (path.length === 0) {
        const item: any = {};

        if (forceValue) {
            object.value = value || dataSource;
        } else {
            if (value) {
                item.value = value;
            }

            if (dataSource) {
                item.dataSource = dataSource;
            }
        }

        object = {
            [key]: {
                ...item,
            },
        };
    } else {
        let el = path.shift();
        if (el) {
            object[el] = makeObjectFromPath({ object: {}, path, key, value, dataSource, forceValue });
        }
    }
    return object;
};
