/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { openDB } from "idb";
import { message } from "antd";
import { xmlProps, componentType as ComponentType, componentType } from "../../constants";
import { Session, Utilitaries } from "./../../../../business";
import {
    getFieldInInputGroup,
    mergeRecursive,
    removeDuplicatedData,
    mathConversion,
    set$DadosInForm,
} from "../../constants/utils";
import { getItemByKey, nullSafe } from "../../utils/fields";
import { getParamNameToValue } from "../../utils/input_attributes";
import { transformParams } from "../../utils/request_params";
import { toArray } from "../../../../components/util";
import FieldEditorModal from "../qa_components/modelEditField";
import { useQACustomState } from "../../../../business/customState";
import { formsToGetAoCarregarSameItSaved } from "../../utils/form";
import { getAoCarregar } from "../../actions";
export const MyContext = React.createContext();

const urlAndParamsSplit = (requestParamsToSplit, dependencies) => {
    // para este caso nbao houver ,virgula entre parametros
    //EX: "verificacao_higienizacoes_lin,{data_i,../data_inicio}{data_f,../data_conclusao},{espaco,//espaco},{area,//area_higienizacao},{executante,//executante})"

    if (!requestParamsToSplit) {
        return {};
    }

    requestParamsToSplit = requestParamsToSplit.replace(/}{/g, "},{");

    requestParamsToSplit = requestParamsToSplit.replace(/\s/g, "").replace(/{\$param\./g, "param.");
    const dataSourceBeginingIndex = requestParamsToSplit.indexOf("(") + 1;
    const splitResult = requestParamsToSplit.substring(dataSourceBeginingIndex).split(",{");
    const [dataSource, ...params] = splitResult;
    return { dataSource, params };
};

const getItemByAction = ({
    action,
    field,
    rowKey,
    tree,
    parent,
    path = [],
    isChild,
    rootItem = null,
    isColumn = false,
}) => {
    for (const propertie in tree) {
        if (propertie === "@accao_" + action) {
            if (
                [ComponentType._dynamicDatagrid, ComponentType._dynamicTable, ComponentType._selectionTable].includes(
                    parent?.["@tipo"]
                ) &&
                (tree["@tipo"] === ComponentType._inputGroup || (!tree["@tipo"] && !tree["@elemento_grafico"]))
            ) {
                parent["@accao_" + action] = tree["@accao_" + action];
                field.push(parent);
            } else {
                if (isColumn && tree.isColumn) {
                    //if (tree.root === rootItem) {
                    field.push({ ...tree, isChild, path });
                    //}
                } else {
                    tree.path = path;
                    field.push(tree);
                }
            }
        } else if (propertie === "dataSource" && typeof tree[propertie] === "object") {
            const hasPathItem = path.find((item) => item === tree.key);
            if (!hasPathItem && isChild) {
                path = [...path, tree.key];
            }
            getItemByAction({
                action,
                path,
                field,
                tree: tree?.[propertie]?.[rowKey],
                parent: tree,
                isChild: true,
                rootItem,
                isColumn,
            });
        } else if (typeof tree[propertie] === "object") {
            const hasPathItem = path.find((item) => item === tree.key);
            if (tree.key === "entidade" && propertie === "nome") {
            }
            if (!hasPathItem && isChild) {
                path = [...path, tree.key];
            }
            getItemByAction({
                action,
                path,
                field,
                tree: tree[propertie],
                parent: tree,
                isChild: true,
                rootItem,
                isColumn,
            });
        } else {
            continue;
        }
    }
    return field;
};

const getDependencies = ({ tree, action, dependencies, path = [], isChild, rootItem = null, isColumn = false }) => {
    for (const key in tree) {
        const hasDependencie = dependencies.find((dep) => dep.key === tree.key);
        if (!hasDependencie) {
            if (key === "@quando_valor_alterado" && action) {
                const actions = tree[key].split(";");
                if (actions.find((item) => item === action)) {
                    // dependencies.push({ ...tree, isChild, path });

                    if (isColumn && tree.isColumn) {
                        if (tree.root === rootItem) {
                            dependencies.push({ ...tree, isChild, path });
                        }
                    } else {
                        dependencies.push({ ...tree, isChild, path });
                    }
                }
            } else if (key === "@quando_valor_alterado" && !action) {
                dependencies.push({ ...tree, isChild, path });
            } else if (
                !Array.isArray(tree[key]) &&
                typeof tree[key] === "object" &&
                !["title", "dataSource"].includes(key)
            ) {
                const hasPathItem = path.find((item) => item === tree.key);
                if (!hasPathItem && isChild) path = [...path, tree.key];
                getDependencies({
                    tree: tree[key],
                    path,
                    dependencies,
                    action,
                    isChild: true,
                    rootItem,
                    isColumn,
                });
            } else {
                continue;
            }
        } else {
            break;
        }
    }
    return dependencies;
};

const convertNullToEmpty = (data) => {
    if (typeof data === "object" && !Array.isArray(data)) {
        for (const key in data) {
            if (data[key] === null || data[key] === undefined) {
                data[key] = "";
            }
        }

        return data;
    } else if (Array.isArray(data)) {
        return data.map((item) => {
            for (const key in item) {
                if (item[key] === null || item[key] === undefined) {
                    item[key] = "";
                } else if (item[key] === "false") item[key] = "f";
                else if (item[key] === "true") item[key] = "t";
            }
            return item;
        });
    }

    return data;
};

const TaskExecutionFormProvider = ({
    toggleModal,
    getFormData,
    form,
    saveForm,
    getFieldDataSource,
    selectById,
    // setTemporaryRelId,
    getForm,
    currentTaskStatus,
    formName,
    children,
    isFormReady,
    // setTemporaryRelRowKey,
    // setTemporaryRelName,
    processId,
    taskId,
    dispatch,
    history,
    setIsRestorable,
    isRestorable,
    isRestored,
    appState,
    formMetaData,
    processParentId,
    getReporterByName,
    paramsFromStartProcess,
    modalParams, // params for this modal - current
    correctForm,
    formWithoutValues = {},
    formAttributes,
    taskMetaData,
    // removeDuplicatedData,
}) => {
    // const [state, setState] = useState({ form });
    const [state, setNewStateWithStack, setState] = useQACustomState({ form });
    const [lastActionType, , setLastActionType] = useQACustomState("init");

    const [changeContext, setChangeContext] = useState("init");
    const [triggerModalId, setTriggerModalId] = useState("");
    const [triedSave, setTriedSave] = useState(false);
    // const [popupParams, setPopupParams] = useState([]);
    /**
     * store alls catched params for sobform popup
     */
    // Save params for next modal - modal inside this form or modal
    const [allPopupParams, setAllPopupParams] = useState({});
    const [formOpened, setFormOpened] = useState(null);
    const [formOpenedHistory, setFormOpenedHistory] = useState([]);
    const [triggerModalHistory, setTriggerModalHistory] = useState({});
    const [restrinctionErrors, setRestrinctionErrors] = useState({});
    const formData = state?.form ? Object.values(state.form) : [];
    const [recordTableAdded, setRecordTableAdded] = useState({
        isAdded: false,
        tableKey: null,
    });
    const [fieldEditor, setFieldEditor] = useState({ name: undefined, onChange: undefined });

    const [dataCache, setDataCache] = useState({});
    const [dataSelectAll, setDataSelectAll] = useState({});

    const [tableDataSourceLength, setTableDataSourceLength] = useState(0);
    const [loadingQuandoValorAlterado, setLoadingQuandoValorAlterado] = useState(false);
    const [tableAddButtonLoad, setTableAddButtonLoad] = useState(false);

    const [triggerWhenValueChangedPopup, setTriggerWhenValueChangedPopup] = useState(1);

    const [inactiveValueError, setInactivedValueError] = useState({});

    const addInactivedValueError = (input, record, message) => {
        const key = `${input.key}-${record ? record.key : ""}`;

        setInactivedValueError((prev) => ({
            ...prev,
            [key]: message,
        }));
    };

    const getInactivedValueError = () => {
        const currentErrors = { ...inactiveValueError };

        if (Utilitaries.isEmpty(currentErrors)) {
            return false;
        }

        return Object.values(currentErrors);
    };

    const removeInactivedValueError = (input, record, recordKey) => {
        const currentErrors = { ...inactiveValueError };
        if (recordKey) {
            Object.keys(record).forEach((key) => {
                delete currentErrors?.[`${key}-${recordKey}`];
            });
        } else {
            const key = `${input.key}-${record ? record.key : ""}`;

            delete currentErrors[key];
        }

        setInactivedValueError({
            ...currentErrors,
        });
    };

    // useEffect(() => {
    //     setState({ form });
    // }, [form]);

    const addToCache = (key, value, params, itemData = null) => {
        key = `${key}${Object.keys(params).reduce((acc, curr) => {
            acc = `${acc}_${curr}_${params[curr]}`;
            return acc;
        }, "")}`;

        setDataCache((prev) => ({
            ...prev,
            [key]: {
                value,
                receivedAt: new Date().getTime(),
            },
        }));
        if (itemData?.["@seleccionar_todos"] === "sim" && itemData?.["@dados"] && !dataSelectAll[itemData.key]) {
            setDataSelectAll((prev) => ({
                ...prev,
                [itemData.key]: key,
            }));
        }
    };

    const getFromCache = (key, params) => {
        key = `${key}${Object.keys(params).reduce((acc, curr) => {
            acc = `${acc}_${curr}_${params[curr]}`;
            return acc;
        }, "")}`;

        const item = dataCache[key];

        if (!item) {
            return null;
        }

        // valid for 5 min => 300 000 milliseconds
        // if (new Date().getTime() - item.receivedAt >= 300000) {
        //     return null;
        // }

        return item.value;
    };

    const addRestrinctionError = (key, message, item) => {
        setRestrinctionErrors((prev) => ({ ...prev, [key]: { message, item } }));
    };
    const removeRestrinctionError = (key) => {
        if (restrinctionErrors[key]) {
            setRestrinctionErrors((prev) => {
                let newErros = {};
                Object.keys(prev).forEach((errorKey) => {
                    if (errorKey !== key) {
                        newErros[errorKey] = restrinctionErrors[errorKey];
                    }
                });

                return newErros;
            });
        }
    };

    const validateFormData = () => {
        const numberOfPossibleErrors = Object.keys(restrinctionErrors).length;
        let valid = true;
        if (numberOfPossibleErrors >= 1) {
            Object.keys(restrinctionErrors).forEach((errorKey) => {
                if (restrinctionErrors[errorKey]) {
                    const rootPath = toArray(restrinctionErrors[errorKey]?.item?.inputGroupPath)[0];
                    if (
                        rootPath &&
                        state.form[rootPath]?.["@tipo"] === componentType._inputGroup &&
                        state.form[rootPath]?.visible === false
                    ) {
                    } else {
                        const root = restrinctionErrors[errorKey]?.item?.root;
                        if (
                            root &&
                            state.form[root]?.["@tipo"] === componentType._dynamicTable &&
                            state.form[root]?.visible === false
                        ) {
                        } else {
                            message.error(restrinctionErrors[errorKey]?.message);
                            valid = false;
                        }
                    }
                }
            });
        }
        return valid;
    };

    useEffect(() => {
        const formKey = `${processId}-${taskId}`;
        let tempSaveData = localStorage.getItem(formKey);
        if (tempSaveData && formKey) {
            try {
                tempSaveData = JSON.parse(atob(tempSaveData));

                setState({ form: tempSaveData });
                message.success("Foi caregados dados guardados temporariamente");
            } catch (error) {}
        }
    }, [processId, taskId]);

    const hangleResetFormData = () => {
        const forceGetAoCarregar = formsToGetAoCarregarSameItSaved(formAttributes?.rootProps?.["@nome"]);
        return getAoCarregar({
            form: formWithoutValues,
            metaInfo: formAttributes?.rootElementProps,
            forceGetAoCarregar,
        }).then((form) => {
            setState({
                form,
            });
            setChangeContext("init");
        });
    };

    const addPropertyToObject = (obj, root = [], key, objectToAdd = {}) => {
        if (root.length === 0) {
            if (obj) {
                obj[key] = {
                    ...obj[key],
                    ...objectToAdd,
                };
            }
        } else {
            let el = root.shift();
            obj[el] = addPropertyToObject(obj[el], root, key, objectToAdd);
        }
        return obj;
    };

    const mergeStateWithDataFetched = ({ dataTransformed, componentType, newSate, root, rowKey, fullPath, field }) => {
        const getValueRow = (row, newData) => {
            if (field?.["@selecionar_todos"] === "sim") {
                newData = {
                    ...newData,
                    id: row["id_" + field?.dbTableName]
                        ? newData.id
                        : Utilitaries.toString(
                              Utilitaries.toArray(dataWithoutKey?.dataSource).map((i) => i?.[field?.["@chave_lista"]])
                          ),
                };
            }
            return newData;
        };

        const { key, ...dataWithoutKey } = dataTransformed;

        if (!key) {
            return newSate;
        }

        // Added rowKey to avoid entring if rowKey is null
        if (root && (rowKey || rowKey === 0)) {
            const dataSourceItem = fullPath
                ? newSate?.form?.[fullPath[0]]?.[root]?.dataSource?.[rowKey]?.[key]
                : newSate?.form?.[root]?.dataSource?.[rowKey]?.[key];

            let newData = null;

            if (
                typeof dataWithoutKey === "object" &&
                (dataWithoutKey?.value || dataWithoutKey?.value === "" || dataWithoutKey?.value == 0)
            ) {
                newData = dataWithoutKey?.value;
            } else {
                newData = {
                    id: typeof dataSourceItem === "object" ? dataSourceItem?.id : dataSourceItem,
                    dataSource: dataWithoutKey?.dataSource,
                };
            }

            if (fullPath) {
                newSate = {
                    ...newSate,
                    form: {
                        ...newSate.form,
                        [fullPath[0]]: {
                            ...newSate.form?.[fullPath[0]],
                            [root]: {
                                ...newSate.form?.[fullPath[0]]?.[root],
                                dataSource: {
                                    ...newSate.form?.[fullPath[0]]?.[root].dataSource,
                                    [rowKey]: {
                                        ...newSate.form?.[fullPath[0]]?.[root]?.dataSource[rowKey],
                                        [key]: newData,
                                    },
                                },
                            },
                        },
                    },
                };
            } else {
                newSate = {
                    ...newSate,
                    form: {
                        ...newSate?.form,
                        [root]: {
                            ...newSate?.form?.[root],
                            dataSource: {
                                ...newSate?.form?.[root]?.dataSource,
                                [rowKey]: {
                                    ...newSate?.form?.[root]?.dataSource?.[rowKey],
                                    [key]: getValueRow(newSate?.form?.[root]?.dataSource?.[rowKey], newData),
                                },
                            },
                        },
                    },
                };
            }
        } else if (fullPath && fullPath.length >= 1 && Array.isArray(fullPath)) {
            newSate = {
                ...newSate,
                form: {
                    ...newSate.form,
                    ...addPropertyToObject(newSate.form, [...fullPath], key, { ...dataWithoutKey }),
                },
            };
        } else {
            newSate = {
                ...newSate,
                form: {
                    ...newSate.form,
                    [key]: {
                        ...newSate.form[key],
                        ...dataWithoutKey,
                    },
                },
            };
        }
        return { ...newSate };
    };

    // todo: split code in is allDependecies valued
    const fetchData = (formData) => {
        formData.forEach((item) => {
            if (item["@visivel"] === "nao") {
                if (item["@dados"]) {
                    dispatch(
                        getFieldDataSource({
                            dataSource: item["@dados"],
                            hack: true,
                            componentType: item["@tipo"],
                            field: item.key,
                            fieldToPopulateObj: item,
                            params: {
                                idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                                idProcesso: state?.form?.processo?.value,
                                idActividade: state?.form?.actividade?.value,
                                idEstabelecimento: state?.form?.estabelecimento?.value,
                                idModelo: state?.form?.modelo?.value,
                                idValencia: state?.form?.valencia?.value,
                                idProjeto: state?.form?.id_projeto?.value,
                            },
                        })
                    ).then((resp) => {
                        if (resp) {
                            const data = removeDuplicatedData(resp.result);
                            // * async state update

                            setState((state) => {
                                const newSate = Object.assign({}, state);

                                const dataTransformed = prepareFieldToPopulate({
                                    data: data,
                                    key: item["@chave_lista"],
                                    value: item["@valor_lista"],
                                    // formActionType: field.formActionType,
                                    componentType,
                                    fieldToPopulate: item.key,
                                    visible: false,
                                });

                                const dataMerged = mergeStateWithDataFetched({
                                    newSate,
                                    dataTransformed,
                                    componentType,
                                    root: "",
                                    rowKey: "",
                                });
                                return dataMerged;
                            });
                        }
                    });
                }

                const dependencies = getDependencies({
                    tree: item,
                    dependencies: [],
                });
                dependencies.forEach((dependencie) => {
                    const actions = dependencie["@quando_valor_alterado"].split(";");
                    for (const action of actions) {
                        let actionDependencies = [];

                        formData.forEach((formItem) => {
                            actionDependencies = [
                                ...actionDependencies,
                                ...getDependencies({
                                    action,
                                    tree: item,
                                    dependencies: [],
                                }).map((depItem) => ({
                                    ...depItem,
                                    root: formItem.key,
                                })),
                            ];
                        });

                        const isAllDependenciesValued = actionDependencies
                            .map((item) => item.value || item["$"])
                            .find((item) => !item)
                            ? false
                            : true;

                        if (isAllDependenciesValued) {
                            const field = getItemByAction({
                                action,
                                field: [],
                                path: [],
                                tree: state.form,
                            });
                            dispatch(
                                getFieldDataSource({
                                    dataSource: field.dataSource,
                                    hack: true,
                                    componentType: field.componentType,
                                    processId,
                                    field: field.key,
                                    taskId,
                                    params: {
                                        ...field.paramsTransformed,
                                        idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                                        idProcesso: state?.form?.processo?.value,
                                        idActividade: state?.form?.actividade?.value,
                                        idEstabelecimento: state?.form?.estabelecimento?.value,
                                        idModelo: state?.form?.modelo?.value,
                                        idValencia: state?.form?.valencia?.value,
                                        idProjeto: state?.form?.id_projeto?.value,
                                    },
                                    fieldToPopulateObj: formData.find((formItem) => formItem.key === field),
                                })
                            ).then((data) => {
                                if (data) {
                                    // data = removeDuplicatedData(data);
                                    // * async state update

                                    setState((state, props) => {
                                        const newSate = Object.assign({}, state);
                                        const dataTransformed = prepareFieldToPopulate({
                                            data: data.result,
                                            key: item["@chave_lista"],
                                            value: item["@valor_lista"],
                                            formActionType: field.formActionType,
                                            componentType,
                                            fieldToPopulate: field.key,
                                        });
                                        const dataMerged = mergeStateWithDataFetched({
                                            newSate,
                                            dataTransformed,
                                            componentType,
                                            root: "",
                                            rowKey: "",
                                        });

                                        return dataMerged;
                                    });
                                }
                            });
                        }
                    }
                });
            }
        });
    };

    const [modalOpened, setModalOpened] = useState("");

    useEffect(() => {
        const { isAdded, tableKey, inputGroupPath } = recordTableAdded;
        setRecordTableAdded({ isAdded: false });
        if (isAdded) {
            let currentForm, dataSource;
            if (inputGroupPath) {
                currentForm = getFieldInInputGroup({ ...state.form }, [...inputGroupPath], tableKey);
                dataSource = currentForm?.dataSource ? Object.values(currentForm.dataSource) : [];
            } else {
                currentForm = state.form[tableKey];
                dataSource = currentForm?.dataSource ? Object.values(currentForm.dataSource) : [];

                // ----
                // const currentForm = state.form;
                // const dataSource = Object.values(currentForm[tableKey].dataSource);

                // const lastRecordAdded = dataSource[dataSource.length - 1];

                // let tableColumns = null;
                // const tableObj = currentForm[tableKey];
            }
            const lastRecordAdded = dataSource[dataSource.length - 1];

            let tableColumns = null;
            const tableObj = currentForm;

            for (const key in tableObj) {
                if (typeof tableObj[key] === "object" && key !== "dataSource" && key !== "value") {
                    tableColumns = tableObj[key];
                }
            }

            (async () => {
                for await (const column of Object.values(tableColumns)) {
                    if (column) {
                        const keyAction = Object.keys(column).find((item) => item.startsWith("@accao_"));

                        if (keyAction) {
                            const elemnent = tableColumns[keyAction];
                            let allDependencies = [];

                            allDependencies = getDependencies({
                                action: keyAction.replace("@accao_", ""),
                                tree: currentForm,
                                dependencies: allDependencies,
                            });
                            const isAllDependenciesValued =
                                allDependencies
                                    .map((item) => {
                                        if (item.isColumn) {
                                            return undefined;
                                        } else {
                                            return item.value || item["$"];
                                        }
                                    })
                                    .findIndex((item) => !item || (Array.isArray(item) && item.length === 0)) === -1
                                    ? true
                                    : false;

                            if (isAllDependenciesValued) {
                                const requestParamsToSplit = column?.[keyAction];
                                if (
                                    !requestParamsToSplit.startsWith("texto(") &&
                                    !requestParamsToSplit.startsWith("round") &&
                                    !requestParamsToSplit.startsWith("if(") &&
                                    requestParamsToSplit
                                ) {
                                    setTableAddButtonLoad(true);
                                    const { params, dataSource } = urlAndParamsSplit(requestParamsToSplit);

                                    // let allParams = allPopupParams.find((param) => param.popup === formOpened);
                                    // allParams = allParams ? allParams.params : {};
                                    const paramsTransformed = transformParams({
                                        params,
                                        allDependencies,
                                        formData: state.form,
                                        allParams: modalParams,
                                    });

                                    const queryParams = {
                                        idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                                        idProcesso: state?.form?.processo?.value,
                                        idActividade: state?.form?.actividade?.value,
                                        idEstabelecimento: state?.form?.estabelecimento?.value,
                                        idModelo: state?.form?.modelo?.value,
                                        idValencia: state?.form?.valencia?.value,
                                        idProjeto: state?.form?.id_projeto?.value,
                                        ...paramsTransformed,
                                    };
                                    const componentType = column["@tipo"];

                                    const cache_data = getFromCache(dataSource, queryParams);
                                    if (cache_data) {
                                        let newSate = Object.assign({}, state);
                                        newSate = mergeStateWithDataFetched({
                                            newSate,
                                            dataTransformed: cache_data,
                                            componentType,
                                            field: column,
                                            root:
                                                column?.path?.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                    ? column?.path[0]
                                                    : column?.root || null,
                                            rowKey: lastRecordAdded?.key,
                                            fullPath: column?.inputGroupPath,
                                        });

                                        setState(newSate);
                                        setTableAddButtonLoad(false);
                                        return;
                                    }

                                    const formActionType = requestParamsToSplit.split("(")[0];
                                    const fieldToPopulate = getItemByKey({
                                        key: column.key,
                                        tree: state.form,
                                    })[0];

                                    await dispatch(
                                        getFieldDataSource({
                                            dataSource,
                                            componentType,
                                            processId,
                                            field: column,
                                            hack: true,
                                            taskId,
                                            params: queryParams,
                                            fieldToPopulateObj: fieldToPopulate,
                                        })
                                        // eslint-disable-next-line no-loop-func
                                    ).then((data) => {
                                        if (
                                            data &&
                                            (column?.path?.length > 0 ||
                                                [ComponentType._dynamicTable].includes(
                                                    state.form?.[column?.root]?.["@tipo"]
                                                ))
                                        ) {
                                            const dataTransformed = prepareFieldToPopulate({
                                                data: convertNullToEmpty(data.result),
                                                key: column["@chave_lista"],
                                                value: column["@valor_lista"],
                                                formActionType,
                                                componentType,
                                                fieldToPopulate: column.key,
                                                inputGroupPath: column?.inputGroupPath,
                                            });

                                            addToCache(dataSource, dataTransformed, queryParams, column);

                                            let newSate = Object.assign({}, state);
                                            newSate = mergeStateWithDataFetched({
                                                newSate,
                                                dataTransformed,
                                                componentType,
                                                field: column,
                                                root:
                                                    column?.path?.length >= 1 // 0 && field?.path.length === 1 // > para >= PARA pegar dynamic datagrid
                                                        ? column?.path[0]
                                                        : column?.root || null,
                                                rowKey: lastRecordAdded?.key,
                                                fullPath: column?.inputGroupPath,
                                            });
                                            setTableAddButtonLoad(false);
                                            setState(newSate);
                                        }
                                    });
                                    //--------------------------- fim -------------------------------------------------------------------
                                }
                            }
                        }
                    }
                }
            })();
        }
    }, [recordTableAdded.isAdded]);
    /**
     * * Direct operations to state
     */

    const getTableFields = (formData, root, inputGroupPath = []) => {
        // ;
        let tableProperties = formData[root];

        if (Utilitaries.toArray(inputGroupPath).length > 0 && !tableProperties) {
            tableProperties = getFieldInInputGroup(formData, inputGroupPath, root);
        }

        let tableFields = {};

        if (tableProperties?.["@tipo"] === componentType._dynamicDatagrid) {
            for (const tableProperty in tableProperties) {
                if (typeof tableProperties[tableProperty] === "object" && tableProperty !== "dataSource") {
                    tableFields[tableProperty] = tableProperties?.[tableProperty];
                }
            }

            return tableFields;
        }

        for (const tableProperty in tableProperties) {
            if (typeof tableProperties[tableProperty] === "object" && tableProperty !== "dataSource") {
                for (const tableField in tableProperties[tableProperty]) {
                    if (typeof tableProperties[tableProperty][tableField] === "object") {
                        tableFields[tableField] = tableProperties?.[tableProperty]?.[tableField];
                    }
                }
            }
        }

        return tableFields;
    };

    const getInputDependence = ({ field }) => {
        if (!field) {
            return [];
        }
        let dependencie = field["@quando_valor_alterado"];
        const deepKeys = [];

        if (!dependencie) {
            Object.keys(field).forEach((key, index) => {
                if (
                    typeof field[key] === "object" &&
                    key !== "dataSource" &&
                    key !== "value" &&
                    !Utilitaries.isEmpty(field[key])
                ) {
                    deepKeys.push(key);
                    Object.keys(field[key]).forEach((deepKey, index) => {
                        if (typeof field[key][deepKey] === "object" && key !== "dataSource" && key !== "value") {
                            deepKeys.push(deepKey);
                        }
                    });
                }
            });
            dependencie = field?.[deepKeys?.[0]]?.[deepKeys?.[1]]?.["@quando_valor_alterado"];
        }

        let allDep = [];

        if (dependencie) {
            allDep = dependencie.split(";");
        }

        return allDep;
    };

    const getValueForInvisibleFields = ({ key, selectValueText, inputList, root, inputGroupPath = [] }) => {
        // for invisivle field with props @calcular": "texto(../[key])"
        if (!selectValueText) {
            selectValueText = "";
            // return {};
        }

        const allFields = { ...state.form };

        if (root) {
            inputList = getTableFields(allFields, root, inputGroupPath);
        } else if (!inputList) {
            inputList = allFields;
        }

        const changedField = inputList[key];
        const dependencesActions = getInputDependence({ field: changedField });

        const allDependecies = {};
        Object.values(inputList)
            .filter((item) => {
                let isTrue = false;

                if (item?.["@calcular"] && item["@calcular"].replace(/\s/g, "") === `texto(../${key})`) {
                    isTrue = true;
                }

                if (dependencesActions) {
                    for (const dep of dependencesActions) {
                        const action = `@accao_${dep}`;

                        if (item[action] && (item[action].startsWith("texto(") || item[action].startsWith("text("))) {
                            isTrue = true;
                            break;
                        }
                    }
                }

                return isTrue;
            })
            .forEach((item) => {
                allDependecies[item.key] = {
                    ...item,
                    value: selectValueText,
                };
            });

        return allDependecies;
    };

    const changeObjectPropertyRecursively = ({ obj, path = [], key, value, dataSource = null, forceValue = false }) => {
        if (path.length === 0) {
            if (obj) {
                if (forceValue) {
                    obj[key]["value"] = value || dataSource;
                } else {
                    if (value !== null) {
                        obj[key]["value"] = value;
                    }
                    if (dataSource) {
                        obj[key]["dataSource"] = dataSource;
                    }
                }
            } else {
                const item = {};

                if (value) {
                    item.value = value;
                }

                if (dataSource) {
                    item.dataSource = dataSource;
                }

                obj = {
                    [key]: {
                        ...item,
                    },
                };
            }
        } else {
            let el = path.shift();
            obj[el] = changeObjectPropertyRecursively({ obj: obj[el], path, key, value, dataSource, forceValue });
        }
        return obj;
    };

    const makeObjectFromPath = ({ obj = {}, path = [], key, value, dataSource = null, forceValue = false }) => {
        if (path.length === 0) {
            const item = {};

            if (forceValue) {
                obj.value = value || dataSource;
            } else {
                if (value) {
                    item.value = value;
                }

                if (dataSource) {
                    item.dataSource = dataSource;
                }
            }

            obj = {
                [key]: {
                    ...item,
                },
            };
        } else {
            let el = path.shift();
            obj[el] = makeObjectFromPath({ obj: {}, path, key, value, dataSource, forceValue });
        }
        return obj;
    };

    const changeTableRowValueWithPath = ({ obj, path = [], key, root, rowKey, value, depValues = {} }) => {
        if (path.length === 0) {
            if (obj) {
                let newValues = value;
                if (
                    obj?.[root]?.dataSource?.[rowKey]?.[key] &&
                    typeof obj?.[root]?.dataSource?.[rowKey]?.[key] === "object" &&
                    !Array.isArray(obj?.[root]?.dataSource?.[rowKey]?.[key])
                ) {
                    newValues = {
                        ...obj?.[root]?.dataSource?.[rowKey]?.[key],
                        id: value,
                    };
                }

                obj[root].dataSource = {
                    ...obj[root].dataSource,
                    [rowKey]: {
                        ...obj[root].dataSource[rowKey],
                        [key]: newValues,
                        ...depValues,
                    },
                };
            }
        } else {
            let el = path.shift();
            obj[el] = changeTableRowValueWithPath({ obj: obj[el], path, key, root, rowKey, value, depValues });
        }
        return obj;
    };

    const setFieldValue = ({ key, value, root, rowKey, inputGroupPath, childRootKey, selectValueText }) => {
        setLastActionType("onchange");
        const otherValues = getValueForInvisibleFields({
            key,
            selectValueText,
            root,
            inputGroupPath: [...Utilitaries.toArray(inputGroupPath)],
        });

        if (inputGroupPath && inputGroupPath.length > 0) {
            const depValues = Object.keys(otherValues).reduce((acumulator, current) => {
                acumulator[current] = otherValues?.[current]?.value;
                return acumulator;
            }, {});
            let newState = {};

            if (root && (rowKey || rowKey === 0)) {
                //tabele
                newState = changeTableRowValueWithPath({
                    obj: { ...state.form },
                    path: [...inputGroupPath],
                    key,
                    value,
                    rowKey,
                    root,
                    depValues,
                });
            } else {
                newState = changeObjectPropertyRecursively({
                    obj: { ...state.form },
                    path: [...inputGroupPath],
                    key,
                    value,
                });
            }

            setState({
                ...state,
                form: newState,
            });
        } else if (root) {
            if (!rowKey && rowKey !== 0) {
                setState({
                    ...state,
                    form: {
                        ...state.form,
                        [root]: {
                            ...state.form[root],
                            ...otherValues,
                            [key]: {
                                ...state.form[root][key],
                                value,
                            },
                        },
                    },
                });
            } else if (
                state.form?.[root]?.dataSource[rowKey][key] &&
                typeof state.form?.[root]?.dataSource[rowKey][key] === "object" &&
                !Array.isArray(state.form?.[root].dataSource[rowKey][key])
            ) {
                const depValues = Object.keys(otherValues).reduce((acumulator, current) => {
                    acumulator[current] = {
                        ...state.form?.[root].dataSource[rowKey][current],
                        id: otherValues[current].value,
                    };
                    return acumulator;
                }, {});

                setState({
                    ...state,
                    form: {
                        ...state.form,
                        [root]: {
                            ...state.form[root],
                            dataSource: {
                                ...state.form[root].dataSource,
                                [rowKey]: {
                                    ...state.form[root].dataSource[rowKey],
                                    ...depValues,
                                    [key]: {
                                        ...state.form[root].dataSource[rowKey][key],
                                        id: value,
                                    },
                                },
                            },
                        },
                    },
                });
            } else {
                const depValues = Object.keys(otherValues).reduce((acumulator, current) => {
                    acumulator[current] = otherValues?.[current]?.value;
                    return acumulator;
                }, {});

                setState((preState) => ({
                    ...preState,
                    form: {
                        ...preState?.form,
                        [root]: {
                            ...preState?.form?.[root],
                            dataSource: {
                                ...preState?.form?.[root]?.dataSource,
                                [rowKey]: {
                                    ...preState.form?.[root]?.dataSource?.[rowKey],
                                    ...depValues,
                                    [key]: value,
                                },
                            },
                        },
                    },
                }));
            }
        } else {
            if (childRootKey) {
                let rootChildKey = "";
                let fieldKey = "";
                Object.keys(state.form[key]).forEach((item, index) => {
                    if (item !== "dataSource" && item !== "value" && typeof state.form[key][item] === "object") {
                        rootChildKey = item;
                    }
                });

                Object.keys(state.form[key][rootChildKey]).forEach((item, index) => {
                    if (item !== "dataSource" && typeof state.form[key][rootChildKey][item] === "object") {
                        fieldKey = item;
                    }
                });

                setState({
                    ...state,
                    form: {
                        ...state.form,
                        [key]: {
                            ...state.form[key],
                            value,
                            [rootChildKey]: {
                                ...state.form[key][rootChildKey],
                                [fieldKey]: {
                                    ...state.form[key][rootChildKey][fieldKey],
                                    value,
                                },
                            },
                        },
                    },
                });
            } else {
                setState((prevState) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        ...otherValues,
                        [key]: {
                            ...prevState.form[key],
                            value,
                        },
                    },
                }));
            }
        }
    };

    const changeTableBooleanColumn = ({ status, column, table }) => {
        let dataSource = state.form[table.key].dataSource ? { ...state.form[table.key].dataSource } : {};

        let value = status;

        if (typeof status === "boolean") {
            value = status ? "t" : "f";
        }

        for (const key in dataSource) {
            dataSource = {
                ...dataSource,
                [key]: {
                    ...dataSource[key],
                    [column.key]: value,
                },
            };
        }

        setState((prevState) => ({
            ...state,
            form: {
                ...state.form,
                [table.key]: {
                    ...state.form[table.key],
                    dataSource,
                },
            },
        }));
    };

    const removeTableErrors = (recordKey, record) => {
        if (record != null && typeof record === "object") {
            const keysErrors = Object.keys(record).map((it) => {
                return `${it}${recordKey ? recordKey : ""}`;
            });

            setRestrinctionErrors((prev) => {
                let newErros = {};
                Object.keys(prev).forEach((errorKey) => {
                    if (!keysErrors.includes(errorKey)) {
                        newErros[errorKey] = restrinctionErrors[errorKey];
                    }
                });

                return newErros;
            });

            removeInactivedValueError(null, record, recordKey);
        }
    };

    const removeDataSourceRecord = ({ key, recordKey, inputGroupPath }) => {
        if (inputGroupPath) {
            const path = inputGroupPath[0];
            const { [recordKey]: value, ...withoutItem } = state.form[path][key].dataSource;

            setState({
                ...state,
                form: {
                    ...state.form,
                    [path]: {
                        ...state.form[path],
                        [key]: {
                            ...state.form[path][key],
                            dataSource: withoutItem,
                        },
                    },
                },
            });
        } else {
            const { [recordKey]: value, ...withoutItem } = state.form[key].dataSource;

            // ------ remover table errors----
            removeTableErrors(recordKey, value);

            setState({
                ...state,
                form: {
                    ...state.form,
                    ...state.form,
                    [key]: {
                        ...state.form[key],
                        dataSource: withoutItem,
                    },
                },
            });
        }
    };

    const addDataSourceRecord = ({ key, columns, schemaColumns, inputGroupPath }) => {
        let table;
        if (inputGroupPath) {
            table = getFieldInInputGroup({ ...state.form }, [...inputGroupPath], key);
            // ;
        } else {
            table = state.form[key];
        }
        let dataItemKey = 0;
        const dataSource = table.dataSource;
        const dataSourceLength = dataSource ? Object.values(dataSource).length : 0;
        if (dataSourceLength > 0) {
            const lastDataItem = Object.values(dataSource)[dataSourceLength - 1];
            dataItemKey = Number(lastDataItem?.key) + 1;
        } else {
            dataItemKey += 1;
        }

        const newDataSource = {
            [dataItemKey]: {
                key: dataItemKey,
                ...columns
                    .filter((item) => item)
                    .reduce((acumulator, current) => {
                        const currentSchema = schemaColumns.find((el) => el.key === current);
                        const defaultValue = paramsFromStartProcess?.[currentSchema.key]
                            ? paramsFromStartProcess?.[currentSchema.key]
                            : currentSchema["$"]
                            ? currentSchema["$"]
                            : "";

                        if (currentSchema["@tipo"] === ComponentType._select) {
                            const checkAccao = Object.keys(currentSchema).some((element) =>
                                element.startsWith("@accao_")
                            );
                            if (checkAccao) {
                                acumulator[current] = {
                                    id: defaultValue,
                                    dataSource: [],
                                };
                            } else {
                                acumulator[current] = defaultValue;
                            }
                        } else {
                            acumulator[current] = defaultValue;
                        }

                        return acumulator;
                    }, {}),
            },
            ...dataSource,
        };

        let newState;
        if (inputGroupPath) {
            newState = changeObjectPropertyRecursively({
                obj: { ...state.form },
                path: [...inputGroupPath],
                key,
                value: null,
                dataSource: newDataSource,
            });
        } else {
            newState = {
                [key]: {
                    ...state.form[key],
                    dataSource: newDataSource,
                },
            };
        }

        setState({
            ...state,
            form: {
                ...state.form,
                ...newState,
            },
        });
    };

    /**
     * * Api's to state
     */
    const handleRadioChange = ({ value, key, rowKey, root, inputGroupPath }) => {
        setFieldValue({
            key,
            value,
            root,
            rowKey,
            inputGroupPath,
        });
        setChangeContext("onchange");
    };

    const showModal = async ({ column, record }) => {
        const form = column[xmlProps.popupForm];
        let id = null;
        if (record) {
            id = record[column.dataIndex] ? record[column.dataIndex] : null;
        } else {
            id = column.value;
        }
        id = typeof id === "object" ? id?.id : id;

        // const { form: modalFormItems, formMeta: modalFormInfo } = await getForm({
        //     form,
        // });

        // const { form: modalFormItems, formMeta: modalFormInfo } = await getFormData({
        //     form,
        //     id,
        //     processId,
        //     taskId,
        // });

        // const { processo, actividade_origem, modelo, estabelecimento, modelo_origem, ...restItems } = modalFormItems;
        // let modal = Object.values(state.form).find(
        //     (item) => item[xmlProps.componentType] === ComponentType._popup && item.key === column[xmlProps.popupForm]
        // );
        // ! changing properties by refernce

        // modal["childKeys"] = Object.values(modalFormItems).map((item) => item.key);
        // modal["@titulo"] = modalFormInfo["@titulo"];

        // * Adding parent propertie
        // const restItemsWithParentKey = Object.values(restItems).reduce((acumulator, current) => {
        //     acumulator[current.key] = { ...current, parent: form };
        //     return acumulator;
        // }, {});

        // await setState((prevState) => ({
        //     form: { ...prevState.form, ...restItemsWithParentKey },
        // }));

        //todo: send popup params to popup
        // edit use case
        const params = column["@param_popup"]?.split(";") || [];

        const paramsKeyValue = params
            .filter((item) => item)
            .map((item) => {
                let [key, valueName] = item.split(",");
                key = key.replace(/\W/g, "").trim();
                return { key, valueName };
            });

        if (paramsKeyValue && !paramsKeyValue.find((it) => it.key === "idModelo")) {
            paramsKeyValue.push({ key: "idModelo", valueName: "../modelo" });
        }

        if (paramsKeyValue && !paramsKeyValue.find((it) => it.key === "idEstabelecimento")) {
            paramsKeyValue.push({ key: "idEstabelecimento", valueName: "../estabelecimento" });
        }

        if (paramsKeyValue && !paramsKeyValue.find((it) => it.key === "idValencia")) {
            paramsKeyValue.push({ key: "idValencia", valueName: "../valencia" });
        }

        let allParams = paramsKeyValue
            .filter((it) => it.key && it.valueName)
            .reduce((acumulator, current) => {
                if (Utilitaries.toString(current.valueName)?.startsWith("$param.")) {
                    const key = current.valueName.replace("$param.", "").replace(/\W/g, "");
                    let __value = "";
                    if (modalParams && key && Object.keys(modalParams).length > 0) {
                        if (key in modalParams) {
                            __value = modalParams[key];
                        }
                    }

                    acumulator[current?.key] = __value;
                } else if (Utilitaries.toString(current.valueName)?.startsWith("x_lista:")) {
                    // " x_lista://participante_sessao/cliente_sessao]"
                    let xpath = Utilitaries.toString(current.valueName).substr(8);

                    const [tableSecondKey, tableField] = xpath.match(/\w+\s*(?=)/g);

                    let table = Object.values(formData).find(
                        (item) =>
                            item[tableSecondKey] &&
                            (item["@tipo"] === componentType._dynamicTable ||
                                item["@tipo"] === componentType.conjunto ||
                                item["@tipo"] === componentType._selectionTable)
                    );

                    if (table) {
                        const tableValues = Object.values(table.dataSource);

                        acumulator[current?.key] = tableValues.reduce((accumulatorValue, current) => {
                            accumulatorValue = `${accumulatorValue}${accumulatorValue.length === 0 ? "" : ", "}'${
                                typeof current[tableField] === "object" ? current[tableField]?.id : current[tableField]
                            }'`;

                            return accumulatorValue;
                        }, "");
                    }

                    //
                } else if (/^[[A-zÀ-ú]|\s]+$/.test(Utilitaries.toString(current.valueName)?.trim())) {
                    //Frequência de apoio
                    acumulator[current?.key] = current.valueName.replace(/]/g, "");
                } else {
                    if (/\/\/\w+\/\w+/g.test(current.valueName)) {
                        const _value = current.valueName.split("/");
                        current.valueName = _value[_value.length - 1];
                    }

                    const itemKeyValue = getItemByKey({
                        key: current.valueName.replace(/\W/g, ""),
                        tree: state.form,
                        rowKey: record?.key,
                        // parent: column.parent || null,
                        // checkParent: true,
                    });

                    if (record) {
                        if (itemKeyValue[0]?.key in record) {
                            const itemValue = record[itemKeyValue[0]?.key];
                            if (typeof itemValue === "object") {
                                acumulator[current?.key] = itemValue?.id;
                            } else {
                                acumulator[current?.key] = itemValue;
                            }
                            return acumulator;
                        }
                    } else {
                        if (itemKeyValue[0]?.key in state.form) {
                            acumulator[current?.key] = state.form[itemKeyValue[0]?.key].value;
                            return acumulator;
                        }
                    }

                    let __value = itemKeyValue[0]?.value;

                    if (modalParams && !__value && Object.keys(modalParams).length > 0) {
                        // check that the param don't exist in the previous pop-up
                        // const previousPopupIndex = allPopupParams.length - 1;

                        // const previousPopup = allPopupParams.find((popup) => popup.key === previousPopupIndex);

                        if (current?.key in modalParams) {
                            __value = modalParams[current?.key];
                        }
                    }

                    // if field belong a table and don't have value chech if root don't have
                    if (record && !__value && itemKeyValue[0]?.key in state.form) {
                        acumulator[current?.key] = state.form[itemKeyValue[0]?.key].value;
                        return acumulator;
                    }

                    acumulator[current?.key] = __value; //itemKeyValue[0]?.value;
                }
                return acumulator;
            }, {});

        if (!allParams.processo) {
            allParams = {
                ...allParams,
                processo: processId,
            };
        }

        if (allParams?.idModelo === "{$param.idModelo}") {
            allParams.idModelo = modalParams["idModelo"]; //|| 1;
        }

        /** store all params */
        // setAllParams(allParams);
        setAllPopupParams(allParams);
        setFormOpened(form);

        // const isToLoadAdditionalData = modalFormInfo["@aoCarregar"];
        // if (isToLoadAdditionalData) {
        //     const aditionalDatas = isToLoadAdditionalData.split(";");
        //     aditionalDatas.forEach((item) => {
        //         if (!item || item === "") {
        //             return;
        //         }

        //         const currentAditionalData = item
        //             .replace(/\s/g, "")
        //             .match(/(?<=\()'.*'/)[0]
        //             ?.split(",");
        //         const keyData = currentAditionalData[0].replace(/'/g, "");
        //         const query = currentAditionalData[1].replace(/'/g, "");
        //         dispatch(
        //             getFieldDataSource({
        //                 dataSource: query,
        //                 params: {
        //                     ...allParams,
        //                 },
        //             })
        //         ).then(({ result }) => {
        //             if (result) {
        //                 // setState((prevState) => {
        //                 //     let newForm = { ...prevState.form };
        //                 //     newForm = set$DadosInForm({
        //                 //         tree: newForm,
        //                 //         values: result[0],
        //                 //         keyData,
        //                 //     });
        //                 //     return {
        //                 //         ...prevState,
        //                 //         form: { newForm },
        //                 //     };
        //                 // });
        //                 // Object.assign({}, prevState, { form: newForm })
        //                 // setState((state, props) => {
        //                 // });
        //                 // for (const fieldKey in result[0]) {
        //                 //     // * async state update
        //                 //     setState((state, props) => {
        //                 //         const newSate = Object.assign({}, state);
        //                 //         const item = getItemByKey({
        //                 //             key: fieldKey,
        //                 //             tree: state.form,
        //                 //             rowKey: record?.key,
        //                 //         });
        //                 //         const dataTransformed = prepareFieldToPopulate({
        //                 //             data: result,
        //                 //             key: item[0]?.["@chave_lista"],
        //                 //             value: item[0]?.["@valor_lista"],
        //                 //             componentType: item[0]?.["@tipo"],
        //                 //             fieldToPopulate: fieldKey,
        //                 //         });
        //                 //         const dataMerged = mergeStateWithDataFetched({
        //                 //             newSate,
        //                 //             dataTransformed,
        //                 //             componentType,
        //                 //             root: "",
        //                 //             rowKey: "",
        //                 //         });
        //                 //
        //                 //
        //                 //         return dataMerged;
        //                 //     });
        //                 // }
        //             }
        //         });
        //     });
        // }

        // const arrayOfParams = Object.keys(allParams).map((param) => ({
        //     key: param,
        //     value: allParams[param],
        // }));

        setTriggerModalHistory({
            ...triggerModalHistory,
            [form]: {
                id: id || null,
                column,
                rowKey: record?.key,
            },
        });

        // * edit
        if (id) {
            // setPopupParams([]);
            // let __allParams = [];
            // for (const key in paramsKeyValue) {
            //     const valueName = paramsKeyValue[key].valueName?.trim();
            //     if (valueName && (valueName.includes("../") || valueName.includes("//"))) {
            //         __allParams.push({
            //             key: paramsKeyValue[key].key,
            //             value: record
            //                 ? record[valueName.replace(/\W/g, "")]
            //                 : state.form[valueName.replace(/\W/g, "")]?.value,
            //         });
            //     }
            // }
            // setPopupParams([...__allParams, ...arrayOfParams]);
            setTriggerModalId(id);
        }
        // * new
        else {
            //     const formItemName = paramsKeyValue[0].valueName;
            //     const value = getItemByKey({
            //         key: formItemName.replace(/\W/g, "").trim(),
            //         tree: state.form,
            //     })[0]?.value;
            //     if (value) {
            //         setTriggerModalId(value);
            //     }
            // setPopupParams(arrayOfParams);
        }
        setModalOpened(form);
        setFormOpenedHistory([...formOpenedHistory, form]);
        toggleModal({ key: form, isVisible: true });
        return true;
    };

    const handleModalCancel = (key) => {
        toggleModal({ key, isVisible: false });
        setModalOpened("");
        setTriggerModalId("");
        // setAllPopupParams(allPopupParams.filter((popupParam) => popupParam.popup !== key));
        // setAllPopupParams({});
        const newTriggerModalHistory = { ...triggerModalHistory };
        if (newTriggerModalHistory[key]) {
            delete newTriggerModalHistory[key];
        }
        setTriggerModalHistory(newTriggerModalHistory);
        const newHistory = formOpenedHistory.filter((it) => it !== key);
        setFormOpenedHistory(newHistory);
        setFormOpened(newHistory[newHistory.length - 1]);
    };

    const handleDateChange = (date, dateString) => {
        //
    };

    const handleModalOk = async (resp) => {
        const formName = modalOpened;
        // setAllPopupParams({});
        // setAllPopupParams(allPopupParams.filter((popupParam) => popupParam.popup !== formName));
        const newTriggerModalHistory = { ...triggerModalHistory };
        if (newTriggerModalHistory[formName]) {
            const {
                rowKey,
                column: { root, key, tablePath },
            } = { ...newTriggerModalHistory[formName] };
            setFieldValue({ value: resp, key, root, rowKey, formName, inputGroupPath: tablePath });
            delete newTriggerModalHistory[formName];
        }
        setTriggerModalHistory(newTriggerModalHistory);
        const newHistory = formOpenedHistory.filter((it) => it !== formName);
        setFormOpenedHistory(newHistory);
        setFormOpened(newHistory[newHistory.length - 1]);
        setModalOpened("");
        setTriggerModalId("");
        setTriggerWhenValueChangedPopup(triggerWhenValueChangedPopup + 1);
        toggleModal({ key: formName, isVisible: false });
    };

    const handleTableAdd = ({ key, columns, schemaColumns, inputGroupPath }) => {
        addDataSourceRecord({ key, columns, schemaColumns, inputGroupPath });
    };

    const handleTableRemove = ({ key, recordKey, inputGroupPath }) => {
        removeDataSourceRecord({
            key,
            recordKey,
            inputGroupPath,
        });
    };

    const handleInputChange = ({ value, key, root, contextInputChange, ...restParams }) => {
        setFieldValue({
            root,
            key,
            value,
            ...restParams,
        });
        if (contextInputChange !== "calculate") {
            setChangeContext("onchange");
        }
    };

    const handleTableInputChange = ({ value, key, columnKey, recordKey, ...rest }) => {
        setFieldValue({
            root: key,
            value,
            key: columnKey,
            rowKey: recordKey,
            ...rest,
        });

        setChangeContext("onchange");
    };

    const handleInputGroupChange = ({ value, key, ...restParams }) => {
        setFieldValue({
            value,
            key,
            ...restParams,
        });
        setChangeContext("onchange");
    };

    const handleSelectChange = async ({ value, key, childRootKey, rowKey, root, ...rest }) => {
        setFieldValue({
            value,
            key,
            childRootKey,
            rowKey,
            root,
            ...rest,
        });
        setChangeContext("onchange");
    };

    const isEmptyObject = (obj) => {
        for (const prop in obj) return false;
        return true;
    };

    const handleGetFieldDataSource = async ({
        dataSource,
        taskId,
        params,
        formActionType,
        rowKey,
        processId,
        fieldToPopulate,
        fieldToPopulateObj,
        componentType,
        root,
        controller,
        callback,
    }) => {
        let paramsForModal = modalParams; // allPopupParams.find((it) => it.popup === formOpened);
        if (componentType === ComponentType.datagrid || componentType === ComponentType._dynamicDatagrid) {
            controller = "geredatagrid";
        }

        // if (paramsForModal) {
        //     paramsForModal = paramsForModal.params;
        // } else {
        //     paramsForModal = {};
        // }

        dispatch(
            getFieldDataSource({
                dataSource,
                params: {
                    idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                    idProcesso: state?.form?.processo?.value || params?.idProcesso,
                    idActividade: state?.form?.actividade?.value,
                    idEstabelecimento: state?.form?.estabelecimento?.value,
                    idModelo: state?.form?.modelo?.value,
                    idValencia: state?.form?.valencia?.value,
                    idProjeto: state?.form?.id_projeto?.value,
                    ...paramsForModal,
                    ...params,
                },
                formActionType,
                componentType,
                processId,
                root,
                field: fieldToPopulate,
                fieldToPopulateObj,
                taskId,
                controller,
            })
        ).then((dataSource) => {
            const prevState = Object.assign({}, state);

            let data = null;

            setTableDataSourceLength(dataSource?.result?.length);

            if (
                fieldToPopulateObj?.["@tipo"] === ComponentType._dynamicTable ||
                fieldToPopulateObj?.["@tipo"] === ComponentType._selectionTable
            ) {
                if (Array.isArray(dataSource?.result)) {
                    const preparedDataSource = convertNullToEmpty(dataSource?.result);
                    data = preparedDataSource.reduce((acumulator, current, index) => {
                        current.key = index + 1;
                        acumulator[index + 1] = current;
                        return acumulator;
                    }, {});
                } else if (typeof dataSource.result === "object" && !Array.isArray(dataSource.result)) {
                    data = { 1: { key: 1, ...convertNullToEmpty(dataSource.result) } };
                }
            } else if (
                fieldToPopulateObj?.["@tipo"] === ComponentType.datagrid ||
                fieldToPopulateObj?.["@tipo"] === ComponentType._dynamicDatagrid
            ) {
                if (Array.isArray(dataSource.linha)) {
                    const preparedDataSource = convertNullToEmpty(dataSource.linha);
                    data = preparedDataSource.reduce((acumulator, current, index) => {
                        current.key = index + 1;
                        acumulator[index + 1] = current;
                        return acumulator;
                    }, {});
                } else if (typeof dataSource?.linha === "object" && !Array.isArray(dataSource.linha)) {
                    data = isEmptyObject(dataSource.linha)
                        ? []
                        : { 1: { key: 1, ...convertNullToEmpty(dataSource.linha) } };
                }
            } else {
                data = dataSource.result || [];
            }

            let updateFieldValueOrData = {};
            if (
                fieldToPopulateObj?.["@tipo"] === "etiqueta" &&
                data[0] &&
                Utilitaries.isEmpty(fieldToPopulateObj?.inputGroupPath)
            ) {
                const getKeyList = Object.keys(data[0]);

                if (
                    fieldToPopulateObj?.["@chave_lista"] ||
                    data[0]?.[fieldToPopulateObj?.["key"]] ||
                    fieldToPopulateObj["@dados"] === getKeyList[0] ||
                    data[0]?.hasOwnProperty(getKeyList[0])
                ) {
                    const __val =
                        data[0]?.[fieldToPopulateObj["@chave_lista"]] ||
                        data[0]?.[fieldToPopulateObj?.["key"]] ||
                        data[0]?.[getKeyList[0]];
                    updateFieldValueOrData = {
                        form: {
                            [fieldToPopulateObj.key]: {
                                value: __val,
                            },
                        },
                    };
                } else {
                    if (prevState.form[fieldToPopulateObj?.key]) {
                        updateFieldValueOrData = {
                            form: {
                                [fieldToPopulateObj.key]: {
                                    value: data,
                                },
                            },
                        };
                    }
                }
            } else if (fieldToPopulateObj?.["@elemento_grafico"] === "etiqueta") {
                if (prevState.form[fieldToPopulateObj.key]) {
                    updateFieldValueOrData = {
                        form: {
                            [fieldToPopulateObj.key]: {
                                value: data?.[0]?.[fieldToPopulateObj.key],
                            },
                        },
                    };
                }
            } else if (fieldToPopulateObj?.["@tipo"] === ComponentType._dynamicDatagrid) {
                updateFieldValueOrData = {
                    form: {
                        [fieldToPopulateObj.key]: {
                            dataSource: data,
                        },
                    },
                };
            } else {
                if (fieldToPopulateObj?.inputGroupPath) {
                    let _value = null;
                    let _dataSource = data;

                    if (fieldToPopulateObj?.["@tipo"] === "etiqueta") {
                        const getKeyList = Object.keys(data?.[0] || {});

                        _value =
                            data[0]?.[fieldToPopulateObj["@chave_lista"]] ||
                            data[0]?.[fieldToPopulateObj?.["key"]] ||
                            data[0]?.[getKeyList[0]];
                    }

                    // updateFieldValueOrData = {
                    //     form: changeObjectPropertyRecursively({
                    //         obj: { ...prevState.form },
                    //         path: [...fieldToPopulateObj.inputGroupPath],
                    //         key: fieldToPopulateObj.key,
                    //         value: _value,
                    //         dataSource: _dataSource,
                    //         forceValue: fieldToPopulateObj?.["@tipo"] === "etiqueta",
                    //     }),
                    // };

                    updateFieldValueOrData = {
                        form: makeObjectFromPath({
                            obj: {},
                            path: [...fieldToPopulateObj.inputGroupPath],
                            key: fieldToPopulateObj.key,
                            value: _value,
                            dataSource: _dataSource,
                            forceValue: fieldToPopulateObj?.["@tipo"] === "etiqueta",
                        }),
                    };
                } else if (
                    ![ComponentType._dynamicTable, ComponentType._dynamicDatagrid].includes(
                        prevState?.form?.[root]?.["@tipo"]
                    )
                ) {
                    try {
                        updateFieldValueOrData = {
                            form: {
                                [fieldToPopulateObj.key]: {
                                    dataSource: data,
                                },
                            },
                        };
                        if (
                            fieldToPopulateObj.value &&
                            !data.find(
                                (item) => item?.[fieldToPopulateObj["@chave_lista"]] === fieldToPopulateObj.value
                            ) &&
                            typeof fieldToPopulateObj.value !== "object" &&
                            fieldToPopulateObj?.["@tipo"] !== ComponentType._checkBox
                        ) {
                            updateFieldValueOrData.form[fieldToPopulateObj.key].value = "";
                        }
                    } catch (error) {}
                    // setState((preState) => Object.assign({}, preState, prevState));
                } else if (
                    state.form?.[fieldToPopulateObj?.root]?.["@tipo"] === ComponentType._dynamicTable &&
                    fieldToPopulateObj?.isColumn
                ) {
                    updateFieldValueOrData = {
                        form: {
                            [fieldToPopulateObj.root]: {
                                dataSource: {
                                    ...state.form[fieldToPopulateObj.root]?.dataSource,
                                    [rowKey]: {
                                        ...state.form[fieldToPopulateObj.root]?.dataSource[rowKey],
                                        [fieldToPopulateObj.key]: {
                                            key: fieldToPopulateObj.key,
                                            dataSource: data,
                                        },
                                    },
                                },
                            },
                        },
                    };
                }
            }
            // const odb = Object.assign({}, preState, updateFieldValueOrData)
            setNewStateWithStack((preState) => {
                if (callback) {
                    callback(data);
                }

                const mergedData = mergeRecursive({ ...preState }, { ...updateFieldValueOrData });
                return JSON.parse(JSON.stringify(mergedData));
                // return Object.assign({}, et);
            });

            // setState((preState) => {
            //     if (callback) {
            //         callback();
            //     }
            //     return mergeRecursive({ ...preState }, { ...updateFieldValueOrData });
            // });
        });
    };

    const getFieldRequestValueKey = (input, data) => {
        if (data && Object.keys(data).length === 1) {
            return Object.keys(data)[0];
        }

        let value = input;

        if (input === "necessita_lote") {
            value = "lotes";
        }

        return value;
    };

    const prepareFieldToPopulate = ({
        key,
        value,
        componentType: cType,
        formActionType,
        fieldToPopulate,
        data,
        startIndex = 0,
        item,
    }) => {
        let fieldData = {};

        if (cType === componentType._dataDisplay) {
            if (Array.isArray(data) && data[0] && data[0]?.valor) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data[0]?.valor,
                };
            } else if (item?.["@chave_lista"] && Array.isArray(data) && data[0]) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data[0]?.[item["@chave_lista"]],
                };
            } else if (Array.isArray(data) && data[0] && data[0]?.nome) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data[0]?.nome,
                };
            } else if (Array.isArray(data) && data[0] && data[0]?.[value]) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data[0]?.[value],
                };
            } else if (
                Array.isArray(data) &&
                data[0] &&
                data[0]?.[key]
                // &&
                // (item?.["@visivel"] === "nao" || item.isColumn)
            ) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data[0]?.[key],
                };
            } else if (Array.isArray(data) && data.length === 1) {
                try {
                    const keys = Object.keys(data[0]);
                    if (keys.length === 1) {
                        fieldData = {
                            key: fieldToPopulate,
                            value: data[0][keys[0]],
                        };
                    }
                } catch (error) {}
            }
        } else if (
            formActionType === xmlProps.autoSetValue &&
            (cType !== ComponentType._dynamicTable ||
                cType !== componentType.datagrid ||
                cType !== componentType._dynamicDatagrid ||
                cType !== componentType._selectionTable)
        ) {
            const value = key ? !data[0] || data[0][key] : data[0];
            fieldData = {
                key: fieldToPopulate,
                value: value === "true" ? "t" : value === "false" ? "f" : value,
            };
        } else if (!formActionType && data && data.length === 1) {
            let _value = data[0]?.[fieldToPopulate];

            if (!_value) {
                _value = data[0]?.valor;
            }
            fieldData = {
                key: fieldToPopulate,
                value: _value === "true" ? "t" : _value === "false" ? "f" : _value,
            };
        } else if (
            formActionType === xmlProps.autoSet &&
            cType !== ComponentType._dynamicTable &&
            cType !== componentType.datagrid &&
            cType !== componentType._selectionTable &&
            cType !== componentType._dynamicDatagrid
        ) {
            if (
                data &&
                data.length >= 1 &&
                (cType === ComponentType._select ||
                    cType === ComponentType.sugestion ||
                    cType === ComponentType._selectionButtons)
            ) {
                fieldData = {
                    key: fieldToPopulate,
                    dataSource: data,
                };
            } else if (cType === ComponentType._select && formActionType === "auto_preenche" && data?.length === 0) {
                fieldData = {
                    key: fieldToPopulate,
                    dataSource: data,
                };
            } else if (cType === ComponentType.fixedList) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data,
                };
            } else if (cType === ComponentType._checkBox) {
                fieldData = {
                    key: fieldToPopulate,
                    dataSource: data,
                };
            } else if (data && data.length === 1) {
                const _value = value
                    ? !data[0] || data[0][value]
                    : data[0][[getFieldRequestValueKey(fieldToPopulate, data[0])]] || "";

                fieldData = {
                    key: fieldToPopulate,
                    value: _value === "true" ? "t" : _value === "false" ? "f" : _value,
                };
            }
        } else if (
            (cType === ComponentType._dynamicTable ||
                cType === componentType.datagrid ||
                cType === componentType._selectionTable ||
                cType === componentType._dynamicDatagrid) &&
            data
        ) {
            fieldData = {
                key: fieldToPopulate,
                dataSource: Array.isArray(data)
                    ? data.reduce((acumulator, current, index) => {
                          const key = Math.floor(index + startIndex + Math.random() * 9999);
                          current = {
                              ...current,
                              key,
                              _key_: index,
                          };
                          acumulator[key] = current;
                          return acumulator;
                      }, {})
                    : data,
            };
        } else if (cType === ComponentType.dynamicList) {
            if (formActionType === xmlProps.autoSetValue) {
                fieldData = {
                    key: fieldToPopulate,
                    value: data[0]?.[item["@chave_lista"]],
                };
            } else {
                fieldData = {
                    key: fieldToPopulate,
                    dataSource: toArray(data),
                };
            }
        }
        return fieldData;
    };

    // eslint-disable-next-line no-unused-vars
    const round = (x) => {
        try {
            return x.toFixed(2);
        } catch (error) {
            return Math.round(x * 10) / 10;
        }
    };

    const handleButtonComponentClick = async ({ item }) => {
        const hasKeyWhenValueChange = Object.keys(item).find((it) => it === "@quando_valor_alterado");
        if (hasKeyWhenValueChange) {
            const actions = item["@quando_valor_alterado"].split(";");
            (async () => {
                let newSate = Object.assign({}, state);

                setLoadingQuandoValorAlterado(true);
                for await (const action of actions) {
                    const key = `@accao_${action}`;
                    // let auxFormData = formData;
                    // if (item.recordKey || item.recordKey === 0) {
                    //     auxFormData = getTableFields({ ...state.form }, item.root);
                    //     auxFormData = Object.values(auxFormData);
                    // }

                    const fields = getItemByAction({
                        action,
                        tree: state?.form,
                        parent: state?.form,
                        field: [],
                        rowKey: undefined,
                        rootItem: item.rootItem,
                        isColumn: item.isColumn,
                    });

                    for await (const formItem of fields) {
                        let currentFormAction = formItem?.[key];
                        let currentForm = formItem;

                        let tableForm = null;

                        if (formItem.root) {
                            tableForm = formData.find(
                                (field) => typeof field === "object" && field?.key === formItem.root
                            );
                        }
                        // if (tableForm) {
                        //     currentFormAction = tableForm[formItem.key][key];
                        //     currentForm = tableForm[formItem.key];
                        // }

                        if (typeof currentForm === "object" && currentFormAction) {
                            if (currentFormAction.startsWith("auto_copia_dados")) {
                                const { dataSource } = urlAndParamsSplit(currentFormAction);
                                let copyField = dataSource.replace(/\W/g, "").trim();

                                if (copyField) {
                                    const dataTransformed = prepareFieldToPopulate({
                                        data: state.form[copyField]?.dataSource,
                                        key: currentForm["@chave_lista"],
                                        value: currentForm["@valor_lista"],
                                        formActionType: "auto_copia_dados",
                                        componentType: currentForm["@tipo"],
                                        fieldToPopulate: currentForm.key,
                                        startIndex: 1,
                                        item: currentForm,
                                    });

                                    newSate = mergeStateWithDataFetched({
                                        newSate,
                                        dataTransformed,
                                        componentType: currentForm["@type"],
                                        root:
                                            formItem?.path && currentForm?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                                ? currentForm?.path[0]
                                                : null,
                                        rowKey: currentForm.isColumn ? item?.key : null,
                                    });
                                }
                            } else if (
                                (currentFormAction.startsWith("round") ||
                                    currentFormAction.startsWith("sum") ||
                                    (/^(\/\/|\.\.\/)\w+|if\((\.\.\/|\/\/)\w+||if\(.*\)/.test(currentFormAction) &&
                                        !currentFormAction.includes(xmlProps.autoSet + "("))) &&
                                !currentFormAction?.startsWith("auto_preenche_valor")
                            ) {
                                try {
                                    if (tableForm) {
                                        const dataSource = tableForm?.dataSource;

                                        for (const tableRow of Object.values(tableForm?.dataSource)) {
                                            // eslint-disable-next-line no-unused-vars
                                            let { jsExp, jsExpTest } = mathConversion({
                                                state: newSate,
                                                xpath: currentFormAction,
                                                item: currentForm,
                                                formData,
                                                root: currentForm.root,
                                                rowKey: tableRow.key,
                                                isTest: true,
                                            });

                                            // eslint-disable-next-line no-unused-vars
                                            const state = newSate;

                                            jsExp = jsExp.replace("state.form", "newSate.form");

                                            let result = eval(jsExpTest);

                                            console.log("prodoction", action, {
                                                jsExp,
                                                jsExpTest,
                                                state,
                                                result,
                                                dataSource,
                                                linha: tableRow.key,
                                                newSate,
                                            });

                                            /* if (isNaN(result)) {
                                                result = 0;
                                            } */

                                            dataSource[tableRow.key][currentForm.key] = result;

                                            console.log("jsExp", tableRow.key, result);
                                        }

                                        newSate = mergeStateWithDataFetched({
                                            newSate,
                                            dataTransformed: {
                                                key: currentForm.key,
                                                dataSource,
                                            },
                                            componentType: currentForm["@type"],
                                            root:
                                                formItem?.path && currentForm?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                                    ? currentForm?.path[0]
                                                    : null,
                                            rowKey: null,
                                        });

                                        console.log(currentForm, dataSource, tableForm);
                                    } else {
                                        let jsExp = mathConversion({
                                            state: newSate,
                                            xpath: currentFormAction,
                                            item: currentForm,
                                            formData,
                                            root: currentForm.root,
                                            // rowKey: item && item.key,
                                        });

                                        // eslint-disable-next-line no-unused-vars
                                        const state = newSate;

                                        const result = eval(jsExp);

                                        newSate = mergeStateWithDataFetched({
                                            newSate,
                                            dataTransformed: {
                                                key: currentForm.key,
                                                value: result,
                                            },
                                            componentType: currentForm["@type"],
                                            root:
                                                formItem?.path && currentForm?.path.length >= 1 // > para >= PARA pegar dynamic datagrid
                                                    ? currentForm?.path[0]
                                                    : null,
                                            rowKey: currentForm.isColumn ? item?.key : null,
                                        });
                                    }
                                } catch (error) {
                                    console.error(error);
                                }
                            } else {
                                const { params, dataSource } = urlAndParamsSplit(currentFormAction);
                                const paramsTransformed = transformParams({
                                    params,
                                    allDependencies: {},
                                    formData: state.form,
                                    root: item.root,
                                    rowKey: item.recordKey,
                                });
                                const formActionType = currentFormAction.split("(")[0];
                                const fieldToPopulate = getItemByKey({
                                    key: item.key,
                                    tree: state.form,
                                })[0];

                                if (fieldToPopulate?.["@botoes_transacao"]) {
                                    paramsTransformed.idProcesso = processId;
                                }

                                if (triggerModalHistory[item.formName]) {
                                    paramsTransformed.id = triggerModalHistory[item.formName]?.id;
                                }

                                await dispatch(
                                    getFieldDataSource({
                                        dataSource,
                                        componentType: currentForm["@tipo"],
                                        processId,
                                        field: currentForm,
                                        hack: true,
                                        taskId,
                                        params: {
                                            ...paramsTransformed,
                                            idProcPai: processParentId, //TODO: check if have possibility to create it dynamic
                                            idProcesso: state?.form?.processo?.value,
                                            idActividade: state?.form?.actividade?.value,
                                            idEstabelecimento: state?.form?.estabelecimento?.value,
                                            idModelo: state?.form?.modelo?.value,
                                            idProjeto: state?.form?.id_projeto?.value,
                                        },
                                        fieldToPopulateObj: fieldToPopulate,
                                    })
                                    // eslint-disable-next-line no-loop-func
                                ).then((data) => {
                                    const list = convertNullToEmpty(data?.result);

                                    const dataTransformed = prepareFieldToPopulate({
                                        data: list,
                                        key: currentForm["@chave_lista"],
                                        value: currentForm["@valor_lista"],
                                        formActionType,
                                        componentType: currentForm["@tipo"],
                                        fieldToPopulate: currentForm.key,
                                        startIndex: 1,
                                        item: currentForm,
                                    });

                                    newSate = mergeStateWithDataFetched({
                                        newSate,
                                        dataTransformed,
                                        componentType: currentForm["@tipo"],
                                        root:
                                            currentForm?.path?.length >= 1 // > para >= PARA pegar dynamic datagrid
                                                ? currentForm?.path[0]
                                                : currentForm.root,
                                        rowKey: currentForm.isColumn ? item.recordKey : null,
                                    });
                                });
                            }
                        }
                    }
                }
                setState(newSate);
                setLoadingQuandoValorAlterado(false);
            })();
        }
    };

    return (
        <MyContext.Provider
            value={{
                tableAddButtonLoad,
                state,
                appState,
                setFieldValue,
                formItems: state.form,
                processId,
                taskId,
                isFormReady,
                handleRadioChange,
                showModal,
                changeContext,
                handleModalCancel,
                getItemByKey,
                modalOpened,
                selectById,
                handleModalOk,
                handleDateChange,
                handleTableAdd,
                handleTableRemove,
                triggerModalId,
                handleInputChange,
                handleTableInputChange,
                handleInputGroupChange,
                dispatch,
                getFieldDataSource,
                currentTaskStatus,
                handleGetFieldDataSource,
                convertNullToEmpty,
                handleSelectChange,
                prepareFieldToPopulate,
                saveForm,
                getItemByAction,
                setState,
                setNewStateWithStack,
                formData,
                getDependencies,
                transformParams,
                mergeStateWithDataFetched,
                urlAndParamsSplit,
                handleButtonComponentClick,
                processParentId,
                allPopupParams,
                modalParams,
                toggleModal,
                formOpened,
                getReporterByName,
                removeDuplicatedData,
                setRecordTableAdded,
                paramsFromStartProcess,
                changeTableBooleanColumn,
                triedSave,
                setTriedSave,
                openEditorFieldModal: setFieldEditor,
                correctForm,
                hangleResetFormData,
                addRestrinctionError,
                removeRestrinctionError,
                restrinctionErrors,
                validateFormData,
                addToCache,
                getFromCache,
                dataSelectAll,
                tableDataSourceLength,
                setLoadingQuandoValorAlterado,
                loadingQuandoValorAlterado,
                lastActionType,
                setLastActionType,
                addInactivedValueError,
                removeInactivedValueError,
                getInactivedValueError,
                taskMetaData,
                triggerWhenValueChangedPopup,
            }}
        >
            {children}
            <FieldEditorModal {...fieldEditor} onCloseEditor={() => setFieldEditor(undefined)} />
        </MyContext.Provider>
    );
};

export default TaskExecutionFormProvider;
