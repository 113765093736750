/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Radio, Input, Tooltip, Icon, Button, message } from "antd";
import moment from "moment";

import { Session, Utilitaries } from "../../../business";
import { baseUrl } from "../../../constants/enviroment";

const { Option } = Select;
const ButtonGroup = Button.Group;

const formStyle = {
    border: "1px solid silver",
    borderRadius: "4px",
    borderStyle: "dashed",
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 2,
    marginRight: 22,
};

const customLabel = ({ help, label }) => (
    <span>
        {label}&nbsp;
        <Tooltip title={help}>
            <Icon type="question-circle-o" />
        </Tooltip>
    </span>
);

const ReportDynamicFilter = ({
    data,
    getFilter,
    getFieldDataSource,
    selectedStablishment,
    setDepartments,
    departments,
    form,
    setDepartamento,
    setEstado,
    setClients,
    setParam1,
    setParam2,
    setParam3,
    setParam4,
    setSelectedSubRelatorioOpcoes,
    selectedDepartamento,
    selectedEstado,
    clients,
    selectedSubRelatorioOpcoes,
    param1,
    param2,
    param3,
    param4,
}) => {
    const {
        id_relatorio,
        titulo,
        visivel_departamento,
        visivel_estado_clientes,
        visivel_clientes,
        visivel_sub_relatorio_opcoes,
        visivel_param1,
        visivel_param2,
        visivel_param3,
        visivel_param4,
        visivel_ass_respons,
        visivel_ass_cliente,
        visivel_ass_entida,
        visivel_data_inicio,
        visivel_data_fim,
        visivel_ordenacao,
        ajuda_departamento,
        ajuda_estado_clientes,
        ajuda_clientes,
        ajuda_param1,
        ajuda_param2,
        ajuda_param3,
        ajuda_param4,
        ajuda_ass_respons,
        ajuda_ass_cliente,
        ajuda_ass_entida,
        ajuda_data_inicio,
        ajuda_data_fim,
        ajuda_ordenacao,
        obrig_departamento,
        obrig_estado_cliente,
        obrig_cliente,
        obrig_param1,
        obrig_param2,
        obrig_param3,
        obrig_param4,
        obrig_ass_respons,
        obrig_ass_cliente,
        obrig_ass_entida,
        obrig_data_inicio,
        obrig_data_fim,
        obrig_ordenacao,
        label_param1,
        label_param2,
        label_param3,
        label_param4,
        query1,
        query2,
        query3,
        query4,
        param1DataSource,
        param2DataSource,
        param3DataSource,
        param4DataSource,
        ordenacaoDataSource,
        subRelatorioOpcoes,
        sub_relatorio_opcoes,
    } = data;

    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState("");

    useEffect(() => {
        if (visivel_departamento == "t") {
            getFieldDataSource({
                // dataSource: "busca_departamentos_qa",
                dataSource: "valencias_actuais",
                params: {
                    // estabelecimento: selectedStablishment,
                    idEstabelecimento: selectedStablishment,
                },
            }).then((data) => {
                setDepartments(data.result);
            });
        }

        if (visivel_sub_relatorio_opcoes == "t") {
            getFilter({
                dataSource: sub_relatorio_opcoes,
                dataSourceName: "subRelatorioOpcoes",
                reportId: id_relatorio,
            });
        }

        if (visivel_clientes == "t") {
            getFieldDataSource({
                dataSource: "cliente_relatorio",
                params: {
                    estabelecimento: selectedStablishment,
                    id_relatorio: id_relatorio,
                    departamento: 0,
                    estado_clientes: selectedEstado,
                },
            }).then((data) => {
                setClients(data.result);
            });
        }

        if (visivel_ordenacao == "t") {
            getFilter({
                dataSource: "ordenacao_relatorio",
                dataSourceName: "ordenacaoDataSource",
                ...getReportsParamsFilters(1),
            });
        }
    }, [id_relatorio]);

    const getReportsParamsFilters = (paramNumber = 1) => {
        return {
            reportId: id_relatorio,
            estado_clientes: Utilitaries.toString(form.getFieldValue("estado_clientes")),
            cliente: Utilitaries.toString(form.getFieldValue("clientes")),
            param1: Utilitaries.toString(form.getFieldValue("param1")),
            param2: Utilitaries.toString(form.getFieldValue("param2")),
            param3: Utilitaries.toString(form.getFieldValue("param3")),
            param4: Utilitaries.toString(form.getFieldValue("param4")),
            estabelecimento: selectedStablishment,
            departamento: selectedDepartamento ? selectedDepartamento : 0,
            // data_inicio: form.getFieldValue("data_inicio"),
            // data_fim: form.getFieldValue("data_fim"),
        };
    };

    useEffect(() => {
        if (visivel_param1 == "t") {
            getFilter({
                dataSource: query1 || "parametros_relatorio",
                dataSourceName: "param1DataSource",
                ...getReportsParamsFilters(1),
            });
        }
    }, [id_relatorio]);

    useEffect(() => {
        if (visivel_param2 == "t") {
            getFilter({
                dataSource: query2 || "parametros2_relatorio",
                dataSourceName: "param2DataSource",
                ...getReportsParamsFilters(2),
            });
        }
    }, [id_relatorio, param1]);

    useEffect(() => {
        if (visivel_param3 == "t") {
            getFilter({
                dataSource: query3 || "parametros3_relatorio",
                dataSourceName: "param3DataSource",
                ...getReportsParamsFilters(3),
            });
        }
    }, [id_relatorio, param1, param2]);

    useEffect(() => {
        if (visivel_param4 == "t") {
            getFilter({
                dataSource: query4 || "parametros4_relatorio",
                dataSourceName: "param4DataSource",
                ...getReportsParamsFilters(4),
            });
        }
    }, [id_relatorio, param1, param2, param3]);
    // }, [id_relatorio, param1, param2, param3, param4]);

    useEffect(() => {
        if (visivel_clientes == "t") {
            getFieldDataSource({
                dataSource: "cliente_relatorio",
                params: {
                    estabelecimento: selectedStablishment,
                    id_relatorio: id_relatorio,
                    departamento: selectedDepartamento ? selectedDepartamento : 0,
                    estado_clientes: selectedEstado,
                },
            }).then((data) => {
                setClients(data.result);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartamento, selectedEstado]);

    const { getFieldDecorator } = form;

    const exportList = (type) => {
        if (Utilitaries.isEmpty(selectedStablishment)) {
            message.warning("Selecione um estabelecimento");
            return;
        }

        form.validateFields((err, values) => {
            if (!err) {
                try {
                    setLoading(true);
                    setSelectedType(type);

                    const queryParams = Object.entries({
                        accao: "geras03",
                        saida: type,
                        ESTABELECIMENTO: selectedStablishment,
                        ID: id_relatorio,
                        DEPARTAMENTO: values.departamento || "",
                        ESTADO_CLIENTES: values.estado_clientes || "",
                        CLIENTE: values.clientes || "",
                        PARAM1: values.param1 || "",
                        PARAM2: values.param2 || "",
                        PARAM3: values.param3 || "",
                        PARAM4: values.param4 || "",
                        ORDENACAO: values.ordenacao || "",
                        SUB_RELATORIO_OPCOES: values.sub_relatorio_opcoes || "",
                        LABEL_PARAM1: label_param1,
                        // LABEL_PARAM2: label_param2,
                        // LABEL_PARAM3: label_param3,
                        // LABEL_PARAM4: label_param4,
                        ASS_RESPONSAVEL: values.ass_respons || "",
                        ASS_CLIENTE: values.ass_cliente || "",
                        ASS_ENTIDADE: values.ass_entida || "",
                        DATA_INI: values.data_inicio ? moment(values.data_inicio).format("YYYY-MM-DD") : "",
                        DATA_FIM: values.data_fim ? moment(values.data_fim).format("YYYY-MM-DD") : "",
                    }).reduce((accumulator, [key, value]) => {
                        accumulator = `${accumulator}${key}=${value}&`;
                        return accumulator;
                    }, "");

                    let endpoint = "/Gestor/gererelatorios.php";
                    /**
                     * CHECK IF WANT TO EXPORT NORMA
                     */
                    if (label_param1 === "Norma") {
                        endpoint = "/Gestor/gereRelatorioNorma.php";
                    }

                    window.open(`${baseUrl}${endpoint}?${queryParams}token=Bearer ${Session.getToken()}`, "_blank");

                    setSelectedType("");
                    setLoading(false);
                    // const formData = new FormData();
                    // formData.append("accao", "geras03");
                    // formData.append("saida", type);
                    // formData.append("ESTABELECIMENTO", selectedStablishment);
                    // formData.append("ID", id_relatorio);
                    // formData.append("DEPARTAMENTO", values.departamento || "");
                    // formData.append("ESTADO_CLIENTES", values.estado_clientes || "");
                    // formData.append("CLIENTE", values.clientes || "");
                    // formData.append("PARAM1", values.param1 || "");
                    // formData.append("PARAM2", values.param2 || "");
                    // formData.append("PARAM3", values.param3 || "");
                    // formData.append("PARAM4", values.param4 || "");
                    // formData.append("ORDENACAO", values.ordenacao || "");
                    // formData.append("SUB_RELATORIO_OPCOES", values.sub_relatorio_opcoes || "");
                    // formData.append("LABEL_PARAM1", label_param1);
                    // // formData.append("LABEL_PARAM2", label_param2);
                    // // formData.append("LABEL_PARAM3", label_param3);
                    // // formData.append("LABEL_PARAM4", label_param4);
                    // formData.append("ASS_RESPONSAVEL", values.ass_respons || "");
                    // formData.append("ASS_CLIENTE", values.ass_cliente || "");
                    // formData.append("ASS_ENTIDADE", values.ass_entida || "");
                    // formData.append(
                    //     "DATA_INI",
                    //     values.data_inicio ? moment(values.data_inicio).format("YYYY-MM-DD") : ""
                    // );
                    // formData.append("DATA_FIM", values.data_fim ? moment(values.data_fim).format("YYYY-MM-DD") : "");

                    // let endpoint = "/Gestor/gererelatorios.php";
                    // /**
                    //  * CHECK IF WANT TO EXPORT NORMA
                    //  */
                    // if (label_param1 === "Norma") {
                    //     endpoint = "/Gestor/gereRelatorioNorma.php";
                    // }

                    // var request = new XMLHttpRequest();
                    // request.open("POST", `${baseUrl}${endpoint}`, true);

                    // request.setRequestHeader("Authorization", `Bearer ${Session.getToken()}`);
                    // request.responseType = "blob";

                    // request.onload = function () {
                    //     if (request.status === 200) {
                    //         var disposition = request.getResponseHeader("content-disposition");
                    //         var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);

                    //         var filename = matches != null && matches[1] ? matches[1] : titulo;

                    //         var blob = new Blob([request.response], {
                    //             type: request.getResponseHeader("content-type"),
                    //         });

                    //         var link = document.createElement("a");
                    //         link.href = window.URL.createObjectURL(blob);
                    //         link.download = filename;

                    //         document.body.appendChild(link);

                    //         link.click();

                    //         document.body.removeChild(link);
                    //         setSelectedType("");
                    //         setLoading(false);
                    //     }
                    // };

                    // request.send(formData);
                } catch (error) {
                } finally {
                }
            }
        });
    };

    const filterByUnidadeOrganica = (item) => {
        if (!item?.filtro_unidade_organica) {
            return true;
        } else if (!selectedDepartamento) {
            return true;
        }

        return item?.filtro_unidade_organica === selectedDepartamento;
    };

    return (
        <div>
            <Form layout="vertical">
                <h3>Filtros</h3>
                <Row gutter={18} style={formStyle}>
                    {visivel_sub_relatorio_opcoes === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item label={"Conteúdo"}>
                                {getFieldDecorator("sub_relatorio_opcoes", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        placeholder="Selecione uma opção"
                                        style={{ width: "100%" }}
                                        allowClear
                                        onChange={setSelectedSubRelatorioOpcoes}
                                    >
                                        {subRelatorioOpcoes &&
                                            subRelatorioOpcoes.map((item) => (
                                                <Select.Option key={`reloptions-${item.id_parametro}`} value={item.id}>
                                                    {item.designacao}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_departamento === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_departamento
                                        ? customLabel({
                                              help: ajuda_departamento,
                                              label: "Unidade Orgânica",
                                          })
                                        : "Unidade Orgânica"
                                }
                            >
                                {getFieldDecorator("departamento", {
                                    rules: [
                                        {
                                            required: obrig_departamento === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        onChange={setDepartamento}
                                        notFoundContent={null}
                                        name="unidadeOrganica"
                                        placeholder="Selecione a unidade orgânica"
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {departments &&
                                            departments.map((item) => (
                                                <Select.Option
                                                    key={`departaments-${item.id_parametro}`}
                                                    value={item.id_tipo_valencia}
                                                >
                                                    {item.designacao}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_estado_clientes === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_estado_clientes
                                        ? customLabel({
                                              help: ajuda_estado_clientes,
                                              label: "Estado",
                                          })
                                        : "Estado"
                                }
                            >
                                {getFieldDecorator("estado_clientes", {
                                    rules: [
                                        {
                                            required: obrig_estado_cliente === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        onChange={setEstado}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        name="estado"
                                        style={{ width: "100%" }}
                                        allowClear
                                        placeholder="Selecione o estado"
                                    >
                                        <Option value="0">Inscritos incluídos</Option>
                                        <Option value="2">Só inscritos</Option>
                                        <Option value="5">Só admitidos</Option>
                                        <Option value="6">Suspensos incluídos</Option>
                                        <Option value="-6">Só suspensos</Option>
                                        {/* <Option value="5">Só admitidos</Option> */}
                                        <Option value="-5">Rescindidos incluídos</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_clientes === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_clientes
                                        ? customLabel({
                                              help: ajuda_clientes,
                                              label: "Nome do cliente",
                                          })
                                        : "Nome do cliente"
                                }
                            >
                                {getFieldDecorator("clientes", {
                                    rules: [
                                        {
                                            required: obrig_cliente === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        name="nomeCliente"
                                        placeholder="Selecione uma opção"
                                        style={{ width: "100%" }}
                                        allowClear
                                    >
                                        {clients &&
                                            clients.map((item) => (
                                                <Select.Option
                                                    key={`client-${item.id_parametro}`}
                                                    value={item.id_cliente_relatorio}
                                                >
                                                    {item.cliente_relatorio}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_param1 === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_param1
                                        ? customLabel({ help: ajuda_param1, label: label_param1 })
                                        : label_param1
                                }
                            >
                                {getFieldDecorator("param1", {
                                    rules: [
                                        {
                                            required: obrig_param1 === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        onChange={setParam1}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.props?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        name="nomeCliente"
                                        placeholder={"Selecione uma opção"}
                                        style={{ width: "100%" }}
                                        allowClear
                                    >
                                        {param1DataSource &&
                                            param1DataSource.filter(filterByUnidadeOrganica).map((item, index) => (
                                                <Select.Option
                                                    key={`param1-${index}-${item.id_parametro}`}
                                                    value={item.id_parametro}
                                                >
                                                    {item.valor}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_param2 === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_param2
                                        ? customLabel({ help: ajuda_param2, label: label_param2 })
                                        : label_param2
                                }
                            >
                                {getFieldDecorator("param2", {
                                    rules: [
                                        {
                                            required: obrig_param2 === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        onChange={setParam2}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        name="nomeCliente"
                                        placeholder={"Selecione uma opção"}
                                        style={{ width: "100%" }}
                                        allowClear
                                    >
                                        {Utilitaries.toArray(param2DataSource)
                                            .filter(filterByUnidadeOrganica)
                                            .map((item, index) => (
                                                <Select.Option
                                                    key={`param2-${index}-${item.id_parametro}`}
                                                    value={item.id_parametro}
                                                >
                                                    {item.valor}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_param3 === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_param3
                                        ? customLabel({ help: ajuda_param3, label: label_param3 })
                                        : label_param3
                                }
                            >
                                {getFieldDecorator("param3", {
                                    rules: [
                                        {
                                            required: obrig_param3 === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        onChange={setParam3}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        name="nomeCliente"
                                        placeholder={"Selecione uma opção"}
                                        style={{ width: "100%" }}
                                        allowClear
                                    >
                                        {param3DataSource &&
                                            param3DataSource.filter(filterByUnidadeOrganica).map((item, index) => (
                                                <Select.Option
                                                    key={`param3-${index}-${item.id_parametro}`}
                                                    value={item.id_parametro}
                                                >
                                                    {item.valor}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_param4 === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_param4
                                        ? customLabel({ help: ajuda_param4, label: label_param4 })
                                        : label_param4
                                }
                            >
                                {getFieldDecorator("param4", {
                                    rules: [
                                        {
                                            required: obrig_param4 === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        dropdownMatchSelectWidth={false}
                                        showArrow={false}
                                        onChange={setParam4}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        notFoundContent={null}
                                        name="nomeCliente"
                                        placeholder={"Selecione uma opção"}
                                        style={{ width: "100%" }}
                                        allowClear
                                    >
                                        {param4DataSource &&
                                            param4DataSource.filter(filterByUnidadeOrganica).map((item, index) => (
                                                <Select.Option
                                                    key={`param4-${index}-${item.id_parametro}`}
                                                    value={item.id_parametro}
                                                >
                                                    {item.valor}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    )}
                </Row>

                {visivel_ordenacao === "t" && (
                    <>
                        <br /> <h3>Ordenação</h3>
                        <Row gutter={18} style={formStyle}>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    label={
                                        ajuda_ordenacao
                                            ? customLabel({
                                                  help: ajuda_ordenacao,
                                                  label: "Ordenar por",
                                              })
                                            : "Ordenar por"
                                    }
                                >
                                    {getFieldDecorator("ordenacao", {
                                        rules: [
                                            {
                                                required: obrig_ordenacao === "t",
                                                message: "Campo obrigatório",
                                            },
                                        ],
                                    })(
                                        <Select
                                            showSearch
                                            dropdownMatchSelectWidth={false}
                                            showArrow={false}
                                            // onChange={setDepartamento}
                                            notFoundContent={null}
                                            name="ordenacao"
                                            placeholder="Selecione uma opção"
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {ordenacaoDataSource &&
                                                ordenacaoDataSource.map((item) => (
                                                    <Select.Option
                                                        key={`orders-${item.id_parametro}`}
                                                        value={item.id_parametro}
                                                    >
                                                        {item.valor}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                <br />
                {(visivel_ass_respons === "t" || visivel_ass_cliente === "t" || visivel_ass_entida === "t") && (
                    <>
                        <h3>Assinaturas</h3>
                        <Row>
                            {visivel_ass_respons === "t" && (
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            ajuda_ass_respons
                                                ? customLabel({
                                                      help: ajuda_ass_respons,
                                                      label: "Assinatura do responsável",
                                                  })
                                                : "Assinatura do responsável"
                                        }
                                    >
                                        {getFieldDecorator("ass_respons", {
                                            rules: [
                                                {
                                                    required: obrig_ass_respons === "t",
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <Radio.Group>
                                                <Radio value={"true"}>Sim</Radio>
                                                <Radio value={"false"}>Não</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                            {visivel_ass_cliente === "t" && (
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            ajuda_ass_cliente
                                                ? customLabel({
                                                      label: "Assinatura do cliente",
                                                      help: ajuda_ass_cliente,
                                                  })
                                                : "Assinatura do cliente"
                                        }
                                    >
                                        {getFieldDecorator("ass_cliente", {
                                            rules: [
                                                {
                                                    required: obrig_ass_cliente === "t",
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <Radio.Group>
                                                <Radio value={"true"}>Sim</Radio>
                                                <Radio value={"false"}>Não</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                            {visivel_ass_entida === "t" && (
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            ajuda_ass_entida
                                                ? customLabel({
                                                      label: "Assinatura da instituição",
                                                      help: ajuda_ass_entida,
                                                  })
                                                : "Assinatura da instituição"
                                        }
                                    >
                                        {getFieldDecorator("ass_entida", {
                                            rules: [
                                                {
                                                    required: obrig_ass_entida === "t",
                                                    message: "Campo obrigatório",
                                                },
                                            ],
                                        })(
                                            <Radio.Group>
                                                <Radio value={"true"}>Sim</Radio>
                                                <Radio value={"false"}>Não</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </>
                )}

                <Row gutter={18}>
                    {visivel_data_inicio === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_data_inicio
                                        ? customLabel({
                                              label: "Data Início",
                                              help: ajuda_data_inicio,
                                          })
                                        : "Data Início"
                                }
                            >
                                {getFieldDecorator("data_inicio", {
                                    rules: [
                                        {
                                            required: obrig_data_inicio === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Input
                                        type="date"
                                        style={{ width: "100%" }}
                                        placeholder="Introduza ou selecione uma data"
                                        allowClear
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    )}

                    {visivel_data_fim === "t" && (
                        <Col xs={24} md={6}>
                            <Form.Item
                                label={
                                    ajuda_data_fim
                                        ? customLabel({
                                              label: "Data Fim",
                                              help: ajuda_data_fim,
                                          })
                                        : "Data Fim"
                                }
                            >
                                {getFieldDecorator("data_fim", {
                                    rules: [
                                        {
                                            required: obrig_data_fim === "t",
                                            message: "Campo obrigatório",
                                        },
                                    ],
                                })(
                                    <Input
                                        type="date"
                                        style={{ width: "100%" }}
                                        placeholder="Introduza ou selecione uma data"
                                        allowClear
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>
            {/* && selectedType === "pdf" ? true : false */}
            <ButtonGroup>
                <Button type="default" onClick={() => exportList("pdf")} disabled={loading}>
                    <Icon style={{ color: "red" }} type={loading && selectedType === "pdf" ? "loading" : "file-pdf"} />
                    Relatório PDF
                </Button>
                <Button type="default" onClick={() => exportList("doc")} disabled={loading}>
                    <Icon
                        style={{ color: "#1a0dab" }}
                        type={loading && selectedType === "doc" ? "loading" : "file-word"}
                    />
                    Relatório Word
                </Button>
                <Button type="default" onClick={() => exportList("xls")} disabled={loading}>
                    <Icon
                        style={{ color: "green" }}
                        type={loading && selectedType === "xls" ? "loading" : "file-excel"}
                    />
                    Relatório Excel
                </Button>
            </ButtonGroup>
        </div>
    );
};

const WrappedPersonalInfoTabPane = Form.create({ name: "dynamic.filter" })(ReportDynamicFilter);

export default WrappedPersonalInfoTabPane;
