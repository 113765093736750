import React from "react";
import LayoutSplit from "./LayoutSplit";
import LayoutQM from "./LayoutQM";
import { IS_UNICV } from "../../../constants/enviroment";

const Layout = ({ children, defaultStyle = {} }) => {
    const MainLayout = IS_UNICV ? LayoutSplit : LayoutQM;
    return <MainLayout defaultStyle={defaultStyle}>{children}</MainLayout>;
};

export default Layout;
