import { Button, Col, message, PageHeader, Row, Steps, Switch, Icon, Modal, Skeleton } from "antd";
import queryString from "query-string";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
    getTaskDetails,
    setTaskDetails,
    processOperationRequest,
    addDocument,
    changeDocDescription,
    deleteDocument,
    removeDocument,
    saveDocument,
    downloadDocument,
    xFormatDownloadDocument,
    addUploadedDoc,
    setTemporaryRelRowKey,
    finishTask,
    setTemporaryRelName,
    setTemporaryRelId,
    getHistoryOperation,
    getNextTask,
    setSubProcessToBase,
    getNextTaskDetails,
    getPreviousTask,
    getPreviousTaskDetails,
    downloadDocumentByName,
    getTaskDetailsMap,
    setProcessToStep,
} from "../../actions/execution";
import { getTodoTasks } from "../../actions/panel";
import {
    getTaskData,
    getFieldDataSource,
    getForm,
    getFormData,
    getTask,
    toggleModal,
    setSubProcess,
    toggleIsFormReady,
    saveForm,
    getTaskDataByRollback,
    getAoCarregar,
    changeViewerSelected,
} from "../../actions/index";
import "../../styles/map.css";
import TaskDetails from "./details";
import ExecutionForm from "./execution.form";
import TaskExecutionFormProvider from "./execution.form.provider";
import { selectById } from "../../../dynamic_list/actions/index";
import { componentType } from "../../constants";
import CorrectTask from "./correct.task";
import { isEmpty } from "../../utils/fields";

import FlowChart from "../../../../components/FlowChart";
import { Utilitaries } from "../../../../business";
import { toArray } from "../../../../components/util";
import { formsToGetAoCarregarSameItSaved } from "../../utils/form";
import { taskOperation } from "../../constants/execution";

const { Step } = Steps;

const TaskExecutionMap = ({ location, match }) => {
    const history = useHistory();
    const urlParams = useParams();
    const dispatch = useDispatch();

    const queryParams = queryString.parse(location.search);

    const paramsFromStartProcess = queryParams;

    const processState = useSelector((state) => state.processExecution.domain.byId);
    const appState = useSelector((state) => state.processExecution.app);

    const [current, setCurrent] = useState(0);
    const [isFlowchartOpen, setIsFlowchartOpen] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const [processId, setProcessId] = useState(null);
    const [parentProcessId, setParentProcessId] = useState(queryParams.processParentId);
    const [isLoading, setIsLoading] = useState(false);
    const [tasks, setTasks] = useState({});
    const [stepsTasks, setStepsTasks] = useState([]);
    const [view, setView] = useState("details");
    const [isRestorable, setIsRestorable] = useState(false);
    const [isRestored, setIsRestored] = useState(false);
    const [correctForm, setCorrectForm] = useState(false);
    const [formAttributes, setFormAttributes] = useState({});
    const [isVisibleModalSeeCorrection, setIsVisibleModalSeeCorrection] = useState(null);
    const [isToDisableConsultar, setIsToDisableConsultar] = useState(false);
    const [isToDisableSave, setIsToDisableSave] = useState(false);
    const [isToDisableFinish, setIsToDisableFinish] = useState(false);
    const [formWithoutValues, setFormWithoutValues] = useState({});
    const [isToDisableNext, setIsToDisableNext] = useState(
        false
        // (currentTask?.["@estado"]?.toLowerCase() !== "terminada" || !hasBeenSaved) && currentTask?.formMeta?.[0]?.form
    );
    const [form, setForm] = useState({});

    const load = async ({ action, process, task, forceView }) => {
        try {
            if (!process || !task) {
                return;
            }

            setIsLoading(true);
            // clear old form

            // get task details
            const taskDetail = await dispatch(getTaskDetailsMap({ taskId: task, processId: process, action: action }));

            if (taskDetail?.erro) {
                setIsLoading(false);
                return;
            }

            setForm({});
            setFormAttributes({});
            setFormWithoutValues({});
            disableCorrectTask();
            setTaskId(taskDetail.taskId);
            setProcessId(taskDetail.processId);

            // set all task include subprocess
            await dispatch(
                setProcessToStep({
                    fluxograma: taskDetail.fluxograma,
                    processId: taskDetail.processId,
                    taskId: taskDetail.taskId,
                })
            );

            // get form if in execution view
            if (view === "execution" || forceView === "execution") {
                await handleConsultar(taskDetail.taskId, taskDetail.processId);
            }

            // put process father
            let parentId = parentProcessId || process;

            if (!parentProcessId || parentProcessId?.startsWith("[")) {
                setParentProcessId(parentId);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            message.error(error?.erro || "Ocorreu um erro");
        }
    };

    useEffect(() => {
        const _processID = urlParams.id;
        const _taskId = queryParams.taskId;
        const pageAction = queryParams.pageAction;

        dispatch(changeViewerSelected("details"));

        load({
            action: pageAction,
            process: _processID,
            task: _taskId,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // }, [urlParams.id, queryParams.taskId, queryParams.pageAction]);

    useEffect(() => {
        let updatedTasks = processState[processId]?.tasks || {};
        setTasks(updatedTasks);

        const _currentTask = updatedTasks[taskId];

        const currentTaskStatus = _currentTask?.["@estado"]?.toLowerCase();

        setIsToDisableFinish(
            currentTaskStatus !== "terminada" && currentTaskStatus !== "anulada"
                ? (!_currentTask ? true : false) && _currentTask?.formMeta?.[0]?.form
                : true
        );

        const _stepsTasks = Object.values(updatedTasks).filter((item) => item.status !== "todo");
        // ;
        setStepsTasks(_stepsTasks);

        const _current = _stepsTasks.findIndex((it) => !it?.processId && it.key === taskId);

        setCurrent(_current >= 0 ? _current : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processState?.[processId], processId]);

    useEffect(() => {
        if (!tasks[taskId]) {
            return;
        }
        const currentTaskStatus = tasks[taskId]?.["@estado"]?.toLowerCase();

        if (currentTaskStatus !== "terminada" && currentTaskStatus !== "anulada") {
            // if (!tasks[taskId]?.["@id_tabela"] && !Utilitaries.isEmpty(steps[current]?.formMeta?.[0]?.form)) {
            //     if (currentTaskStatus === "por iniciar" && tasks[taskId]?.["@info"]) {
            //         setIsToDisableConsultar(true);
            //         setIsToDisableFinish(true);
            //         setIsToDisableNext(true);
            //         setIsToDisableSave(false);
            //     } else {
            //         setIsToDisableConsultar(false);
            //     }
            // } else {
            if (Utilitaries.isEmpty(tasks[taskId]?.["@id_tabela"])) {
                if (Utilitaries.isEmpty(tasks[taskId]?.formMeta?.[0]?.form)) {
                    setIsToDisableNext(false);
                } else {
                    setIsToDisableNext(true);
                }
                setIsToDisableFinish(true);
            }
            setIsToDisableSave(false);
            setIsToDisableConsultar(false);
            // }
        } else if (currentTaskStatus === "terminada") {
            setIsToDisableFinish(true);
            setIsToDisableSave(true);
            setIsToDisableNext(false);
            setIsToDisableConsultar(false);
        } else if (currentTaskStatus === "anulada") {
            setIsToDisableFinish(true);
            setIsToDisableSave(true);
            setIsToDisableNext(true);
            setIsToDisableConsultar(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, tasks[taskId]]);

    // const uptateProcessParent = (newTaskProcessId, currentTaskProcessId) => {
    //     ;
    //     if (newTaskProcessId !== currentTaskProcessId) {
    //         setProcessParent({
    //             process: currentTaskProcessId,
    //             task: taskId,
    //         });
    //     }
    // };

    // const formItemsRender = useMemo(
    //     () =>
    //         // !Utilitaries.isEmpty(form) &&
    //         Object.values(form || {}).filter(
    //             (item) =>
    //                 (!item.parent || item["@tipo"] === componentType._popup) &&
    //                 (item["@tipo"] || item["@elemento_grafico"]) &&
    //                 item.key !== "controlo_documento" &&
    //                 !item["@tab"]
    //         ),
    //     [form]
    // );

    const formItemsRender = useMemo(
        () =>
            Object.values(form || {}).filter((item) => {
                if (
                    (!item.parent || item["@tipo"] === componentType._popup) &&
                    (item["@tipo"] || item["@elemento_grafico"]) &&
                    item.key !== "controlo_documento" &&
                    !item["@tab"]
                ) {
                    return true;
                }

                return false;
            }),
        [form]
    );

    const handleBack = () => {
        const routeContext = queryParams.routeContext;
        if (routeContext === "panel") {
            history.push({
                pathname: "/task/panel",
            });
        } else if (routeContext === "processInit") {
            history.push({
                pathname: "/process/execution/index",
            });
        } else if (routeContext === "documents_records") {
            history.push({
                pathname: "/document_record_consult",
            });
        } else if (routeContext === "customer_dossier") {
            history.push({
                pathname: "/client_dossier",
            });
        }
    };

    const prev = () => {
        load({
            action: "prev",
            process: processId,
            task: taskId,
        });
    };

    const next = () => {
        load({
            action: "next",
            process: processId,
            task: taskId,
        });
    };

    const onChangeProcessStep = (current) => {
        const currentTask = stepsTasks[current];

        let taskIdToOpen = currentTask.key;
        if (!taskIdToOpen && currentTask?.isProcess) {
            const allTasksOfProcess = toArray(Object.values(processState?.[currentTask.processId]?.tasks || {})).filter(
                (task) => task?.["@processo"] === currentTask.processId
            );

            taskIdToOpen = allTasksOfProcess?.[allTasksOfProcess.length - 1]?.["@id"];
        }

        load({
            action: "consult",
            process: currentTask?.isProcess ? currentTask?.processId : processId,
            task: taskIdToOpen,
        });
    };

    const handleProcessOperationRequest = async ({ operation, reason }) => {
        const result = await processOperationRequest({
            operation,
            reason,
            process: processId,
            tarefa: taskId,
        });

        if (!result || !Utilitaries.isEmpty(result.erro)) {
            message.error(result.erro || "Ocorreu um erro");
            return false;
        } else {
            message.success("Guardado com sucesso");

            if (operation === taskOperation.saveObservation) {
                const objTask = {
                    ...tasks,
                    [taskId]: {
                        ...tasks?.[taskId],
                        "@observacoes": reason,
                    },
                };
                setTasks(objTask);
            } else {
                history.push({
                    pathname: "/task/panel",
                });
            }
            return true;
        }
    };

    const handleAddDocument = ({ processId, taskId }) => {
        dispatch(
            addDocument({
                processId,
                taskId,
                newDoc: { date: new Date(), description: "", file: "" },
            })
        );
    };

    const handleChangeDocDescription = ({ value, docKey }) => {
        dispatch(
            changeDocDescription({
                processId,
                taskId: taskId,
                docKey,
                value,
            })
        );
    };

    const handleRemove = (docKey) => {
        return removeDocument({
            processId,
            taskId: taskId,
            docKey,
        });
    };

    const handleDeleteDocument = (key) => {
        return dispatch(
            deleteDocument({
                processId,
                taskId: taskId,
                docKey: key,
            })
        );
    };

    const handleSaveDocument = (key) => {
        return dispatch(
            saveDocument({
                processId,
                taskId: taskId,
                key,
            })
        );
    };

    const handleAddUploadedDoc = (payload) => {
        return dispatch(
            addUploadedDoc({
                processId,
                taskId: taskId,
                ...payload,
            })
        );
    };

    const handleDownloadDocument = (id) => {
        dispatch(downloadDocument({ id }));
    };

    const handleXFormatDownloadDocument = (payload) => {
        // const estabelecimento = forms[taskId]?.estabelecimento?.value;
        // const areas = forms[taskId]?.area_atual?.value;
        // const valencia = forms[taskId]?.valencia?.value;
        const optionalParameters = {};
        if (tasks[taskId]?.["@estabelecimento"]) {
            optionalParameters.estabelecimento = tasks[taskId]?.["@estabelecimento"];
        }

        dispatch(
            xFormatDownloadDocument({
                ...payload,
                processo: processId,
                tarefa: taskId,
                ...optionalParameters,
                // areas,
                // valencia,
                // departamento: department,
            })
        );
    };

    const handleGetHistoryOperation = ({ taskId, historyOperation }) => {
        dispatch(getHistoryOperation({ taskId, historyOperation }));
    };

    const handleFinishTask = () => {
        setIsLoading(true);
        dispatch(finishTask({ processId: processId, taskId })).then(({ tarefa, erro }) => {
            if (erro) {
                setIsLoading(false);
                message.error(erro);
                return;
            }
            // todo: handle custom erros in backend
            const { estado, fim, ultima_tarefa } = tarefa["@attributes"];
            if (estado.toLowerCase() === "terminada") {
                message.success("Tarefa terminada com sucesso");
                dispatch(
                    setTaskDetails({
                        "@estado": estado,
                        "@fim": fim,
                        "@ultima_tarefa": ultima_tarefa,
                        processId: processId,
                        taskId,
                    })
                );
                // handleDetails({ taskId, processId });
                // setIsToDisableFinish(true);
                // setIsToDisableSave(true);
            } else {
                message.error("Erro!");
            }
            setIsLoading(false);
        });
    };

    const handleDetails = async ({ taskId = "", processId = "", isOnChange, isSaved } = {}) => {
        if (taskId && processId) {
            await dispatch(
                getTaskDetails({
                    taskId,
                    processId,
                    isOnChange,
                })
            );
        }
        if (isSaved) {
            setView("details");
            const currentTaskStatus = tasks[queryParams.taskId]?.["@estado"]?.toLowerCase();
            if (currentTaskStatus !== "terminada" && currentTaskStatus !== "anulada") {
                setIsToDisableFinish(false);
            }
            setIsToDisableNext(false);
            setIsToDisableFinish(false);

            disableCorrectTask();
        }
    };

    const enableCorrectTask = () => {
        // setIsToDisableSave(false);
        setCorrectForm(true);
    };

    const disableCorrectTask = () => {
        // setIsToDisableSave(false);
        setCorrectForm(false);
    };

    const handleVersionRollback = (id, callback) => {
        dispatch(getTaskDataByRollback({ id })).then(({ taskData, formAttributes: formAttrib }) => {
            // set form with new data
            setForm({ taskData });

            // Enable fieds for edit
            enableCorrectTask();
            callback();
        });
    };

    const handleConsultar = (taskId, processId) => {
        setIsLoading(true);
        dispatch(toggleIsFormReady());
        return new Promise((resolve, reject) => {
            dispatch(getTaskData({ processId, taskId })).then(
                ({
                    taskData,
                    values,
                    formAttributes: formAttrib,
                    metaInfoformFields,
                    formWithoutValues: cleanForm,
                    taskAtributes,
                } = {}) => {
                    const forceGetAoCarregar = formsToGetAoCarregarSameItSaved(formAttrib["@nome"]);
                    return getAoCarregar({
                        form: taskData,
                        metaInfo: metaInfoformFields,
                        values,
                        forceGetAoCarregar,
                    }).then((form) => {
                        setForm(form);
                        setFormWithoutValues(cleanForm);
                        setFormAttributes({ rootProps: formAttrib, rootElementProps: metaInfoformFields });
                        setIsLoading(false);
                        resolve();
                    });
                }
            );
        });
    };

    const getReporterByName = (data) => {
        return dispatch(downloadDocumentByName(data));
    };

    const handleGetForm = ({ form }) => {
        return dispatch(
            getForm({
                form,
                processId: processId,
                taskId: taskId,
            })
        );
    };

    const handleGetFormData = ({ form, id, formStruct }) => {
        return dispatch(
            getFormData({
                form,
                id,
                formStruct,
                processId: processId,
                taskId: taskId,
            })
        );
    };

    const handleToggleModal = ({ ...props }) => {
        return dispatch(toggleModal({ ...props }));
    };

    let steps = stepsTasks.map((item, index) => {
        if (item.isProcess) return item;

        return {
            ...item,
            status: item.status,
            id: item.key,
            title: item.name,
            firstTask: item["@primeira_tarefa"],
            lastTask: item["@ultima_tarefa"],
            details: (
                <TaskDetails
                    processData={processState[processId]}
                    taskData={tasks[item.key]}
                    details={tasks[item.key]?.details}
                    handleProcessOperationRequest={handleProcessOperationRequest}
                    taskId={taskId}
                    // handleOnChangeReason={handleOnChangeReason}
                    addDocument={() => handleAddDocument({ processId, taskId: item.key })}
                    changeDocDescription={handleChangeDocDescription}
                    removeDocument={handleRemove}
                    deleteDocument={handleDeleteDocument}
                    saveDocument={handleSaveDocument}
                    downloadDocument={handleDownloadDocument}
                    xFormatDownloadDocument={handleXFormatDownloadDocument}
                    // addUploadedDoc={(payload) => handleAddUploadedDoc}
                    addUploadedDoc={handleAddUploadedDoc}
                    getHistoryOperation={handleGetHistoryOperation}
                />
            ),
            execution: (
                <TaskExecutionFormProvider
                    setTemporaryRelId={({ ...props }) => dispatch(setTemporaryRelId({ ...props }))}
                    setTemporaryRelRowKey={({ ...props }) => dispatch(setTemporaryRelRowKey({ ...props }))}
                    setTemporaryRelName={({ ...props }) => dispatch(setTemporaryRelName({ ...props }))}
                    toggleIsFormReady={toggleIsFormReady}
                    setIsRestorable={setIsRestorable}
                    getReporterByName={getReporterByName}
                    isRestorable={isRestorable}
                    currentTaskStatus={isToDisableSave}
                    isRestored={isRestored}
                    isFormReady={appState.isFormReady}
                    selectById={({ ...props }) => selectById({ ...props })}
                    history={history}
                    getForm={handleGetForm}
                    saveForm={saveForm}
                    getFormData={handleGetFormData}
                    taskId={taskId}
                    processId={processId}
                    getFieldDataSource={getFieldDataSource}
                    dispatch={dispatch}
                    toggleModal={handleToggleModal}
                    form={form}
                    formName={processState[processId]?.tasks[taskId]?.formMeta?.[0]?.form}
                    appState={appState}
                    taskMetaData={tasks?.[taskId]}
                    processParentId={queryParams.processParentId}
                    formMetaData={item?.formMeta}
                    paramsFromStartProcess={paramsFromStartProcess}
                    correctForm={correctForm}
                    formWithoutValues={formWithoutValues}
                    formAttributes={formAttributes}
                >
                    <ExecutionForm
                        saveForm={saveForm}
                        formName={processState[processId]?.tasks[taskId]?.formMeta?.[0]?.form}
                        isRoot={true}
                        isToDisableSave={isToDisableSave}
                        dispatch={dispatch}
                        toggleIsFormReady={toggleIsFormReady}
                        formItemsRender={formItemsRender}
                        formKey={processState[processId]?.tasks[item?.key]?.["@elemento_raiz"]}
                        appState={appState}
                        handleDetails={handleDetails}
                        visibleModalSee={isVisibleModalSeeCorrection}
                        setVisibleModalSee={setIsVisibleModalSeeCorrection}
                        formAttributes={formAttributes}
                    />
                </TaskExecutionFormProvider>
            ),
        };
    });

    return (
        <>
            <PageHeader
                title="Mapa execução tarefas"
                onBack={handleBack}
                extra={[
                    <span>
                        <label>Fluxograma</label>
                        <Switch
                            size="default"
                            defaultChecked={isFlowchartOpen}
                            onChange={() => setIsFlowchartOpen(!isFlowchartOpen)}
                            style={{ marginLeft: 5 }}
                        />
                    </span>,
                ]}
            />

            <Row gutter={18}>
                <Col xs={24} md={isFlowchartOpen ? 16 : 24} id="processBodyContainer">
                    <Steps current={current} onChange={onChangeProcessStep}>
                        {steps.map((item) => {
                            const { isProcess, type, name, title, id, processId } = item;
                            if (isProcess) {
                                return (
                                    <Step
                                        title={name}
                                        icon={
                                            <Icon
                                                style={{ cursor: "pointer" }}
                                                type={type === "base" ? "step-backward" : "step-forward"}
                                            />
                                        }
                                        description={`Nº processo: ${processId}`}
                                    />
                                );
                            } else {
                                return (
                                    <Step key={`map_step_task${id}`} title={title} description={`Nº tarefa: ${id}`} />
                                );
                            }
                        })}
                    </Steps>

                    <div className="steps-content">
                        {steps?.[current] ? (
                            <Skeleton paragraph={{ rows: 12, width: 100 }} active loading={isLoading}>
                                {steps?.[current]?.[view]}
                            </Skeleton>
                        ) : (
                            <Skeleton paragraph={{ rows: 12, width: 100 }} active loading={isLoading} />
                        )}
                    </div>

                    <div className="steps-action">
                        {steps?.[current]?.firstTask !== "1" && (
                            <Button
                                type="link"
                                icon="step-backward"
                                size="default"
                                style={{ marginRight: 8 }}
                                onClick={() => prev()}
                                disabled={isLoading}
                            >
                                Anterior
                            </Button>
                        )}
                        {steps[current]?.lastTask !== "1" && (
                            <Button
                                type="link"
                                size="default"
                                disabled={isToDisableNext || isLoading}
                                icon="step-forward"
                                onClick={() => next()}
                            >
                                Próximo
                            </Button>
                        )}

                        <Button
                            onClick={handleFinishTask}
                            disabled={isToDisableFinish || isLoading}
                            size="default"
                            type="primary"
                            icon="file-done"
                            style={{ marginLeft: "8px", float: "right" }}
                        >
                            Terminar
                        </Button>

                        {isRestorable && (
                            <Button
                                onClick={() => {
                                    setIsRestored(true);
                                    setIsRestorable(false);
                                }}
                                disabled={
                                    (tasks[taskId] && tasks[taskId]?.["@estado"].toLowerCase() === "terminada"
                                        ? true
                                        : false) || isLoading
                                }
                                type="primary"
                                size="default"
                                icon="rollback"
                                style={{ marginLeft: "8px", float: "right" }}
                                // disabled={isLoading}
                            >
                                Restaurar
                            </Button>
                        )}

                        {view === "execution" && (
                            <>
                                <Button
                                    onClick={() => {
                                        handleDetails({
                                            taskId: steps?.[current]?.id,
                                            processId: processId,
                                        }).then(() => {
                                            setView("details");
                                            dispatch(changeViewerSelected("details"));
                                            setFormAttributes({});
                                            setFormWithoutValues({});
                                            setCorrectForm(false);
                                        });
                                    }}
                                    size="default"
                                    type="link"
                                    icon="solution"
                                    style={{ float: "right" }}
                                    disabled={isLoading}
                                >
                                    Detalhes
                                </Button>

                                <CorrectTask
                                    processId={processId}
                                    taskId={steps?.[current]?.id}
                                    enableCorrectTask={enableCorrectTask}
                                    formProps={formAttributes}
                                    handleVersionRollback={handleVersionRollback}
                                    setVisibleModalSee={setIsVisibleModalSeeCorrection}
                                />
                            </>
                        )}

                        {view === "details" &&
                            !isToDisableConsultar &&
                            !Utilitaries.isEmpty(steps[current]?.formMeta?.[0]?.form) && (
                                <Button
                                    onClick={() => {
                                        handleConsultar(taskId, processId).then(() => {
                                            setView("execution");
                                            dispatch(changeViewerSelected("execution"));
                                        });
                                    }}
                                    type="link"
                                    icon="search"
                                    size="default"
                                    style={{ float: "right" }}
                                    disabled={isLoading}
                                >
                                    Consultar
                                </Button>
                            )}
                    </div>
                </Col>
                {isFlowchartOpen && (
                    <Col xs={24} md={8}>
                        {!isLoading ? (
                            <FlowChart
                                processFlowChart={processState[processId]?.tasks[taskId]?.details?.flowChart}
                                load={load}
                                currentView={true}
                            />
                        ) : (
                            <Skeleton paragraph={{ rows: 12, width: 100 }} active loading={true}></Skeleton>
                        )}
                    </Col>
                )}
            </Row>
        </>
    );
};

export default TaskExecutionMap;
