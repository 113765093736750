/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { PageHeader, Form, Select, Input, Button, Row, Col, Collapse, Pagination, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getTask } from "./../../process_execution/actions/index";
import { taskStatusList } from "./../constants/index";
import { getStablishments } from "../../../actions/index";
import { getDocuments, getProcess, getTasks, getUsers, getDecision } from "./../actions/index";
import { selectDataForFixedSelectList as selectFixeList } from "./../../fixed_list/actions/index";
import { labelTooltip as LabelTooltip, downloadFile } from "./../../../components/util";
import ButtonsGroup from "./buttons.group";
import { Utilitaries } from "../../../business";
import { CDRTable } from "./table.tsx";

const { Panel } = Collapse;
const { Option } = Select;

const DocumentRecordConsult = ({ form, history }) => {
    const customerList = useSelector((state) =>
        state.fixedList.domain.byId.lista_clientes ? state.fixedList.domain.byId.lista_clientes.data : []
    );
    const dispatch = useDispatch();

    const [processList, setProcessList] = useState([]);
    const [loadingProcess, setLoadingProcess] = useState(true);
    const [processDecision, setProcessDecision] = useState([]);
    const [loadingDecision, setLoadingDecision] = useState(true);
    const [tasks, setTasks] = useState([]);
    const [loadingTasks, setLoadingTasks] = useState(false);
    const [users, setUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [filter, setfilter] = useState({});
    const [processo, setProcesso] = useState(null);
    const [processoDecision, setProcessoDecision] = useState(null);
    const [stablishments, setStablishments] = useState([]);
    const [loadingStablishments, setLoadingStablishments] = useState(false);
    const [loadingCustomers, setLoadingCustomers] = useState(true);
    const [selectedEstablishmentName, setSelectedEstablishmentName] = useState("");
    const [selectedClientNIF, setSelectedClientNIF] = useState("");
    const [selectedClientNumber, setSelectedClientNumber] = useState("");

    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
    });
    const { getFieldDecorator } = form;
    const firstRender = useRef(true);

    const getForm = () => {
        form.validateFields((_err, values) => {
            const { sorter, ...objFilter } = filter;

            // if (!values.filtro_processo_decisao && processoDecision) {
            //     values.filtro_processo_decisao = processoDecision;
            // }

            // save filter and values in localStorage
            sessionStorage.setItem("cdr_filter", JSON.stringify(filter));
            sessionStorage.setItem("cdr_values", JSON.stringify(values));

            const obj = {
                ...objFilter,
                ...values,
                pagina: pagination.page,
                resultados_pagina: pagination.perPage,
            };
            if (sorter) {
                values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
            }

            obj.filtro_nome_processo = values.filtro_processo_decisao || values.filtro_nome_processo;

            if ("filtro_processo_decisao" in obj) {
                delete obj.filtro_processo_decisao;
            }

            obj.filtro_nome_tarefa = values.filtro_nome_tarefa || filter.filtro_nome_tarefa;

            obj.filtro_processo = values.filtro_processo || filter.filtro_processo;

            obj.filtro_tarefa = values.filtro_tarefa || filter.filtro_tarefa;
            const mounted = true;

            getDocuments(obj).then((response) => {
                if (response && mounted) {
                    setDataSource(response.lista);
                    setMetadata(response.metadata);
                }
                if (mounted) setLoadingData(false);
            });
        });
    };

    // Get form from API
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setLoadingData(true);
        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, filter]);

    useEffect(() => {
        let mounted = true;
        getProcess().then((response) => {
            if (mounted) {
                if (response) setProcessList(response);
                setLoadingProcess(false);
            }
        });

        getUsers().then((response) => {
            if (mounted) {
                if (response) setUsers(response);
                setLoadingUsers(false);
            }
        });
        dispatch(getStablishments()).then((response) => {
            setStablishments(Utilitaries.toArray(response.estabelecimentos.linha));
            setLoadingStablishments(false);
        });

        dispatch(selectFixeList({ query: "lista_clientes" }, "lista_clientes")).then(() => {
            if (mounted) setLoadingCustomers(false);
        });

        // get values from sessionStorage
        const values = sessionStorage.getItem("cdr_values");

        if (values) {
            const obj = JSON.parse(values);
            if (!Utilitaries.isEmpty(obj)) {
                if (obj.filtro_nome_processo) {
                    setProcesso(obj.filtro_nome_processo);
                }

                if (obj.filtro_processo_decisao) {
                    setProcessoDecision(obj.filtro_processo_decisao);
                }
                form.setFieldsValue(obj);
            }
            // getForm();
        }

        const filter = sessionStorage.getItem("cdr_filter");
        if (filter) {
            const obj = JSON.parse(filter);
            if (!Utilitaries.isEmpty(obj)) {
                setfilter(obj);
            } else {
                setfilter({});
            }
            // getForm();
        }

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;
        setLoadingTasks(true);
        setLoadingDecision(true);

        const values = {};

        if (processo) {
            values.processo = processo;
        }

        getTasks(values).then((response) => {
            if (mounted) {
                if (response) setTasks(response);
                setLoadingTasks(false);
            }
        });

        getDecision(values).then((response) => {
            if (mounted) {
                if (response) setProcessDecision(response);
                setLoadingDecision(false);

                if (processoDecision) {
                    const obj = {
                        filtro_processo_decisao: processoDecision,
                    };
                    form.setFieldsValue(obj);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [processo]);

    useEffect(() => {
        let mounted = true;
        setLoadingTasks(true);

        const values = {};

        if (processoDecision) {
            values.processo = processoDecision;
        }

        getTasks(values).then((response) => {
            if (mounted) {
                if (response) setTasks(response);
                setLoadingTasks(false);
            }
        });

        return () => {
            mounted = false;
        };
    }, [processoDecision]);

    const processChange = (e) => {
        setProcesso(e);
        form.setFields({
            taskName: { value: null },
        });
    };

    const processDecissionChange = (e) => {
        setProcessoDecision(e);
        form.setFields({
            taskName: { value: null },
        });
    };

    const pageChange = (page, perPage) => {
        setPagination({
            page,
            perPage,
        });
    };

    const clearFormFilters = (event) => {
        event.stopPropagation();
        form.resetFields();
        setPagination({
            ...pagination,
            page: 1,
        });
        setfilter({});
        setProcessoDecision(null);
    };

    const exportList = (saida) => {
        let validate = false;
        form.validateFields((_err) => {
            validate = !_err;
        });

        if (!validate)
            return new Promise((resolve) => {
                resolve(false);
            });
        const values = form.getFieldsValue();
        const { sorter, ...objFilter } = filter;

        const obj = {
            ...objFilter,
            ...values,
            pagina: pagination.page,
            resultados_pagina: pagination.perPage,
        };
        if (sorter) {
            values["ordenacao_" + sorter.columnKey] = sorter.order === "ascend" ? "ASC" : "DESC";
        }
        obj.filtro_nome_processo = values.filtro_nome_processo || filter.filtro_nome_processo;
        obj.filtro_nome_tarefa = values.filtro_nome_tarefa || filter.filtro_nome_tarefa;
        obj.filtro_processo = values.filtro_processo || filter.filtro_processo;
        obj.filtro_tarefa = values.filtro_tarefa || filter.filtro_tarefa;

        const formExport = {};
        Object.entries(obj).forEach(([key, val]) => {
            if (val) formExport[key] = val;
        });

        formExport.saida = saida;
        formExport.accao = "consultar";
        const titulo = "Consulta de documentos, registos e tarefas";
        formExport.titulo = titulo;
        formExport.campos =
            "codigo_externo:100:Código;nome_processo:154:Processo;nome_tarefa:154:Tarefa;informacao_adicional:469:Informação adicional;processo:80:N.º proc.;tarefa:80:Nº de Tarefa;inicio:120:Inicio Execução;fim:120:Fim Execução;";
        return downloadFile("/gereconsulta.php", formExport);
    };

    const genExtra = () => (
        <>
            <span onClick={(event) => event.stopPropagation()}>
                {getFieldDecorator("anulados")(
                    <Checkbox
                        defaultChecked={
                            sessionStorage.getItem("cdr_values")
                                ? JSON.parse(sessionStorage.getItem("cdr_values"))?.anulados
                                : false
                        }
                    >
                        Consultar anulados
                    </Checkbox>
                )}
            </span>

            <Button type="link" icon="undo" onClick={clearFormFilters}>
                Limpar filtros
            </Button>
        </>
    );

    const search = () => {
        setLoadingData(true);
        setPagination({
            page: 1,
            perPage: pagination.perPage,
        });
    };

    return (
        <>
            <PageHeader title="Consulta de documentos, registos e tarefas" />
            <Form layout="vertical">
                <Collapse expandIconPosition={"right"} defaultActiveKey={["1"]}>
                    <Panel header="Filtro" key="1" extra={genExtra()}>
                        <Row gutter={16}>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    label={
                                        <LabelTooltip
                                            label="Nome do processo"
                                            description="Esta listagem mostra apenas os processos com tarefas executadas."
                                        />
                                    }
                                >
                                    {getFieldDecorator("filtro_nome_processo")(
                                        <Select
                                            allowClear
                                            showSearch
                                            loading={loadingProcess}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={processChange}
                                            style={{ width: "100%" }}
                                        >
                                            {processList.map((item) => (
                                                <Option value={item.nome} key={item.nome}>
                                                    {item.nome_externo_trad}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {!Utilitaries.isEmpty(processDecision) && (
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label={
                                            <LabelTooltip
                                                label="Decisão"
                                                description="Esta listagem mostra apenas os processos com decisão."
                                            />
                                        }
                                    >
                                        {getFieldDecorator("filtro_processo_decisao")(
                                            <Select
                                                allowClear
                                                showSearch
                                                loading={loadingDecision}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                    0
                                                }
                                                onChange={processDecissionChange}
                                                style={{ width: "100%" }}
                                            >
                                                {processDecision.map((item) => (
                                                    <Option value={item.nome} key={item.nome}>
                                                        {item.nome_externo_trad}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            )}
                            <Col xs={24} md={6}>
                                <Form.Item label="Nome da tarefa">
                                    {getFieldDecorator("filtro_nome_tarefa")(
                                        <Select
                                            allowClear
                                            showSearch
                                            loading={loadingTasks}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {tasks.map((item) => (
                                                <Option value={item.nome} key={item.nome}>
                                                    {item.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={3}>
                                <Form.Item label="Nº do processo">
                                    {getFieldDecorator("filtro_processo")(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={3}>
                                <Form.Item label="Nº da tarefa">
                                    {getFieldDecorator("filtro_tarefa")(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Executado por">
                                    {getFieldDecorator("utilizador")(
                                        <Select
                                            allowClear
                                            showSearch
                                            loading={loadingUsers}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {users.map((item) => (
                                                <Option value={item.username} key={item.username}>
                                                    {item.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={6}>
                                <Form.Item label="Estado da tarefa">
                                    {getFieldDecorator("estado")(
                                        <Select
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {taskStatusList.map((item) => (
                                                <Option value={item} key={item}>
                                                    {item}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Início da execução">
                                    {getFieldDecorator("inicio")(<Input type="date" style={{ width: "100%" }} />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Fim da execução">
                                    {getFieldDecorator("fim")(<Input type="date" style={{ width: "100%" }} />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Estabelecimento">
                                    {getFieldDecorator("filtro_estabelecimento")(
                                        <Select
                                            allowClear
                                            showSearch
                                            loading={loadingStablishments}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(value, oldValue) => {
                                                const _title = oldValue?.props?.title;
                                                // console.log("Estabelecimento", value);
                                                setSelectedEstablishmentName(_title);
                                                // console.log("oldValue", oldValue);
                                            }}
                                            style={{ width: "100%" }}
                                        >
                                            {stablishments.map((item) => (
                                                <Option
                                                    title={item.nome}
                                                    value={item.id_estabelecimento}
                                                    key={item.id_estabelecimento}
                                                >
                                                    {item.nome}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="NIF do cliente">
                                    {getFieldDecorator("nif_cliente")(
                                        <Input onChange={(value) => setSelectedClientNIF(value.target.value)} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Nº de cliente">
                                    {getFieldDecorator("n_cliente")(
                                        <Input onChange={(value) => setSelectedClientNumber(value.target.value)} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Nome do cliente">
                                    {getFieldDecorator("cliente")(
                                        <Select
                                            allowClear
                                            showSearch
                                            loading={loadingCustomers}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {Utilitaries.removeDuplicates(customerList, "nome")
                                                .filter((item) => {
                                                    // estabelecimento_nome
                                                    if (
                                                        selectedClientNumber &&
                                                        item?.id_cliente !== selectedClientNumber
                                                    ) {
                                                        return false;
                                                    }

                                                    if (selectedClientNIF && item?.nif !== selectedClientNIF) {
                                                        return false;
                                                    }

                                                    if (
                                                        selectedEstablishmentName &&
                                                        item?.estabelecimento_nome !== selectedEstablishmentName
                                                    ) {
                                                        return false;
                                                    }

                                                    return true;
                                                })
                                                .map((item) => (
                                                    // <Option value={item.cliente} key={item.cliente}>
                                                    <Option value={item.nome} key={item.nome}>
                                                        {item.nome}
                                                    </Option>
                                                ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="&nbsp;">
                                    <Button type="primary" loading={loadingData} onClick={search}>
                                        Procurar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Form>
            <br />
            <Row gutter={16}>
                <Col xs={24} md={24}>
                    <ButtonsGroup handleExport={exportList} />
                </Col>
            </Row>
            <CDRTable
                filter={filter}
                dataSource={dataSource}
                loadingData={loadingData}
                setPagination={setPagination}
                pagination={pagination}
                setFilter={setfilter}
                history={history}
            />
            {/* <Table
                size="middle"
                rowKey={(data) => data.tarefa + "-table"}
                pagination={false}
                dataSource={dataSource}
                loading={loadingData}
                columns={columns}
                onChange={handleTableChange}
            /> */}
            <Row>
                <Pagination
                    style={{ float: "right", marginTop: 16 }}
                    current={metadata.pagina}
                    defaultPageSize={metadata.resultados_pagina}
                    size="small"
                    total={metadata.resultados}
                    showSizeChanger
                    showTotal={(total) => `Total registos: ${total}`}
                    onShowSizeChange={pageChange}
                    onChange={pageChange}
                />
            </Row>
        </>
    );
};

export default Form.create({})(DocumentRecordConsult);
