import React from "react";
import { Button, Icon, Popconfirm, Popover, Radio } from "antd";
import {
    FormInputsProps,
    FormRootElementAttributesProps,
    RemoveTableRowProps,
    RenderContextType,
} from "../../../../types/formTypes";
import { getLabel } from "../../Components/FormLabel";
import { COMPONENT_TYPE } from "../../../../utils/FormMapper/XMLInputType";
import { ComponentRender } from "../../Components/ComponentRender";
import { getDataSource, isEmpty, toArray } from "../../../../utils/FormMapper/formHandle";
import { getRowSpan } from "../../../../utils/functions";

interface RenderSelectAllProps {
    currentItem: FormInputsProps;
    changeAll: (value: boolean, input: FormInputsProps) => void;
    currentTaskStatus: boolean;
    context: any;
}

const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
};

const renderSelectAll = ({ currentItem, changeAll, currentTaskStatus, context }: RenderSelectAllProps) => {
    let contentSelection = (dataSource: any, key: any) => (
        <Radio.Group onChange={(e) => changeAll(e.target.value, key)}>
            {dataSource.map((data: any) => (
                <Radio style={radioStyle} key={data.key} value={data.key}>
                    {data.value}
                </Radio>
            ))}
        </Radio.Group>
    );

    let optionsSelect = null;
    if (currentItem?.select_all === "sim") {
        if (!isEmpty(currentItem?.values)) {
            const dataSource = getDataSource(currentItem);
            optionsSelect = contentSelection(dataSource, currentItem);
            return (
                <Popover content={optionsSelect} title="Selecionar" trigger="click">
                    <Button type="link" icon="edit"></Button>
                </Popover>
            );
        } else {
            if (!isEmpty(currentItem?.data)) {
                const dataSource = toArray(context.getFromCache(context.dataSelectAll?.[currentItem.key], {})).map(
                    (item) => ({
                        key: item?.[currentItem?.key_list!!],
                        value: item?.[currentItem?.value_list!!],
                    })
                );
                optionsSelect = contentSelection(dataSource, currentItem);
                return (
                    <Popover content={optionsSelect} title="Selecionar" trigger="click">
                        <Button type="link" icon="edit"></Button>
                    </Popover>
                );
            } else {
                return (
                    <Popconfirm
                        title="Selecionar todos como:"
                        onConfirm={() => changeAll(true, currentItem)}
                        onCancel={() => changeAll(false, currentItem)}
                        okText="Sim"
                        cancelText="Não"
                        disabled={currentTaskStatus}
                    >
                        <Icon type="check-square" />
                    </Popconfirm>
                );
            }
        }
    }
};

interface GetColumnsProps {
    componentsProperties: FormInputsProps;
    renderContext: RenderContextType;
    itemData?: any;
    dataSource?: any[];
    form?: any;
    context?: any;
    setLabel?: any;
    formKey?: any;
    currentTaskStatus: any;
    changeAll: (value: boolean, input: FormInputsProps) => void;
    disabled: any;
    justSee?: boolean;
    rootElementAttribute: FormRootElementAttributesProps;
    handleTableRemoveRow: ({ table_key, columns, input_group_path }: RemoveTableRowProps) => void;
}

export const getColumns = ({
    componentsProperties,
    renderContext,
    itemData,
    dataSource,
    form,
    context,
    setLabel,
    formKey,
    currentTaskStatus,
    changeAll,
    disabled,
    justSee,
    rootElementAttribute,
    handleTableRemoveRow,
}: GetColumnsProps) => {
    let columns: any[] = [];

    for (const column in componentsProperties) {
        if (
            typeof componentsProperties[column] === "object" &&
            column !== "DATA_SOURCE" &&
            !Array.isArray(componentsProperties[column])
        ) {
            const currentItem: FormInputsProps = componentsProperties[column];
            const { label } = getLabel({ componentsProperties: currentItem, renderContext, required: false });
            columns.push({
                ...componentsProperties[column],
                dataIndex: column,
                title: (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minWidth: "80px",
                        }}
                    >
                        {label}
                        {currentItem?.select_all === "sim" &&
                            renderSelectAll({ currentItem, changeAll, currentTaskStatus, context })}
                    </div>
                ),
            });
        }
    }

    columns = columns.filter((column) => column.visible !== "nao" || !column?.visible);

    columns = columns.map((column) => {
        // --- in case column has other component in it
        if (column.type) {
            column.root = componentsProperties.key;
            // column.key = columnKey;
            return {
                // ...column,
                dataIndex: column.dataIndex,
                title: column.title,
                render: (text: any, record: any, index: number) => {
                    debugger;
                    let props: any = {};

                    const rowSpan = getRowSpan({
                        currentRowIndex: index,
                        rowSpanValue: column.row_span,
                        data: dataSource || [],
                        dataIndex: column.dataIndex,
                        text,
                    });
                    props.rowSpan = rowSpan;

                    return {
                        children: (
                            <ComponentRender
                                key={"component_input_group" + column.key}
                                renderContext="column"
                                componentsProperties={column}
                                tableRow={{ row_data: record, row_key: record.key }}
                                justSee={justSee}
                                rootElementAttribute={rootElementAttribute}
                            />
                        ),
                        props,
                    };
                },
            };
        }
        // --- in case column has no other component in it
        else return column;
    });

    if (
        componentsProperties.type === COMPONENT_TYPE.DYNAMIC_DATAGRID &&
        componentsProperties?.show_buttons_add_remove === "sim" &&
        !disabled
    ) {
        columns.push({
            dataIndex: "actions",
            title: "Ação",
            type: "deleteAction",
            width: "10%",
            render: (_text: string, record: any, index: any) => {
                return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        // eslint-disable-next-line no-script-url
                        href="javascript:;"
                        onClick={(e) => {
                            handleTableRemoveRow({
                                table_key: componentsProperties.key,
                                row_key: record.key,
                                columns,
                                input_group_path: componentsProperties.input_group_path || null,
                            });
                        }}
                        className="qa-text-del"
                    >
                        Eliminar
                    </a>
                );
            },
        });
    }

    return columns;
};
