import React, { useEffect, useState, useContext, useMemo } from "react";
import moment from "moment";
import { Select, Col, Form } from "antd";

import FormContext from "../context/FormContext";
import { QADivider, QAToolTip } from "./";
import { sendHttpRequest } from "../../http.request";
import { getAllElementAction, getElementAction, getInputLabel, hasInputCalculate } from "../utils/functions";
import { Utilitaries, FormUtilitaries } from "../../../business";
import { getAllDependencies, input_validations } from "../utils/validations";
import { LineBreak } from "./lineBreak";
import { Session } from "../../../business";
import { SelectInput } from "../../../V2/components/SelectInput";

const { Option } = Select;

const QaSelect = ({
    inputAttribute,
    // index,
    // handleInputChange,
    value,
    self,
    visible,
    disabled,
    hasError,
    renderGroup,
    objectInputs,
    isRequired,
    colSize,
    ...inputProps
}) => {
    //
    const [list, setList] = useState([]);
    const [defaultValue, setDefaultValue] = useState(undefined);
    const [loading, setLoading] = useState(false);

    // To define key for get value and key in list
    const [typeList, setTypeList] = useState({});
    // const [selected, setSelected] = useState(value);

    // const [getListDeps, setGetListDep] = useState([]);
    const [error, setError] = useState(null);

    const {
        changeInputValue,
        formAttributes,
        formData,
        params: formParams,
        justSee,
        updateError,
        isInitial,
        formErrors,
        flagAddTable,
        validateField,
    } = useContext(FormContext);

    const { inTable } = inputProps;

    const __key = inputAttribute["$"] ? inputAttribute["$"].split("$dados.")[1] : undefined;

    useEffect(() => {
        //
        const currentValue = inTable ? inputProps.items.data[self] : formData[self];
        if (!Utilitaries.isEmpty(currentValue) || formData["id_" + formAttributes?.["@elemento_raiz"]]) {
            return;
        }

        if (__key && __key === "ano") {
            changeInputValue({
                value: moment().format("YYYY"), // valor selecionado  in value
                key: self, // NOme do campo
                ...inputProps, // propriedades da tabela
                appLoading: true,
                inputAttribute, // input atributos
                callback() {
                    setDefaultValue(moment().format("YYYY"));
                },
            });
        } else if (isInitial && inputAttribute?.["$"]) {
            const _value = inputAttribute?.["$"];
            if (!Utilitaries.toString(_value).startsWith("$")) {
                changeInputValue({
                    value: _value, // valor selecionado  in value
                    key: self, // NOme do campo
                    ...inputProps, // propriedades da tabela
                    appLoading: true,
                    inputAttribute, // input atributos
                    callback() {
                        setDefaultValue(_value);
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputAttribute]);

    useEffect(() => {
        const getData = async () => {
            if (!inputAttribute["@dados"]) {
                // || toArray(list).length > 0
                //
                return;
            }
            setLoading(true);
            let dados = inputAttribute["@dados"];

            dados = dados.split(",");

            //
            const fd = new FormData();
            fd.append("accao", "query");
            fd.append("query", dados[0]);
            if (value || defaultValue) {
                fd.append("_valor_campo_", value || defaultValue);
            }

            try {
                if (dados.length > 1) {
                    dados = inputAttribute["@dados"].replace(/ /g, "").split(",{");

                    // remove first item in array
                    dados.shift();

                    dados = dados[0];

                    dados = dados.split(",");

                    fd.append(dados[0], formData[dados[1].replace(/\W/g, "")] || "");
                }
            } catch (error) {
                console.error(error);
            }

            try {
                if (Session.isCLient()) {
                    const params = Session.getAll();
                    fd.append("usr_parametro1", params.parametro1);
                    fd.append("usr_parametro2", params.parametro2);
                    fd.append("usr_parametro3", params.parametro3);
                }

                return sendHttpRequest("POST", "/Gestor/execQuery.php", fd).then((data) => {
                    if (data && typeof data === "object") {
                        setList(data.result);
                    }
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
            }
        };

        if (inputAttribute["@dados"]) {
            getData();
            setTypeList("dinamica");
        } else if (inputAttribute["@valores"]) {
            let dados = inputAttribute["@valores"].split(";").map((item) => {
                if (item.includes(",")) {
                    return Utilitaries.toString(item).replace(/\[|\]/g, "").trim().split(",");
                } else {
                    return [item, item];
                }
            });
            setTypeList("estatica");
            //
            setList(dados);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputAttribute["@dados"], inputAttribute["@valores"]]);

    //Update error
    useEffect(() => {
        if (inputProps.inTable) {
            if (flagAddTable.hasOwnProperty(inputProps?.items?.table)) {
                setError(hasError({ [self]: value }));
            }
        } else if (updateError) {
            setError(formErrors[self]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateError, flagAddTable[inputProps?.items?.table], value]);

    let _params = [];
    // const depAction = getElementAction({ item: inputAttribute });

    // if (depAction) {
    //     let [query, ...params] = depAction.valueDep.replace(/ +/g, "").split(",{");

    //     // _query = query.replace("auto_preenche(", "");
    //     _params = params.map((it) => {
    //         let prm = it.replace("}", "").replace(")", "").replace("{", "").split(",");
    //         // .split(/\,\.\.\/|,\/\//);
    //         // let prm = it.replace("}", "").replace(")", "").replace("{", "").split(",../");
    //         // .split(",//");
    //         return prm;
    //     });
    //     // const listT = depAction.match(/\/\/\w+|\.\.\/\w+/g);
    // }

    // get all actions for cases that have many actions
    const depActions = getAllElementAction({ item: inputAttribute });

    if (depActions.length > 0) {
        depActions.forEach((depAction) => {
            let [query, ...params] = depAction.valueDep.replace(/ +/g, "").split(",{");
            let _paramsAction = params.map((it) => {
                let prm = it.replace("}", "").replace(")", "").replace("{", "").split(",");
                return prm;
            });
            _params = _params.concat(_paramsAction);
        });
    }

    const selectedDefaultValue = () => {
        let _value_ = value || defaultValue;

        if (inputProps.inTable) {
            _value_ = inputProps.items.data[self];
        }

        const item = list.find((it) => selectedValue(it) === _value_);

        if (item) {
            return _value_;
        }

        return "";
    };

    // If have property @accao_preenche_*
    // Get list if parameters ore changed
    useEffect(
        () => {
            const getData = async () => {
                if (!formData) {
                    return;
                }

                const allDepsAction = getAllElementAction({ item: inputAttribute });

                if (allDepsAction.length > 0) {
                    setLoading(true);
                    for (const action of allDepsAction) {
                        if (inputAttribute && action) {
                            let [query, ...params] = action.valueDep.replace(/ +/g, "").split(",{");
                            let _paramsCurrentAction = params.map((it) => {
                                let prm = it.replace("}", "").replace(")", "").replace("{", "").split(",");
                                return prm;
                            });

                            const fillValue = query?.startsWith("auto_preenche_valor");

                            query = query.replace("auto_preenche(", "");
                            query = query.replace("auto_preenche_valor(", "");
                            query = query.replace("auto_preenche_tudo(", "");

                            const fd = new FormData();
                            fd.append("query", query);
                            fd.append("accao", "query");
                            let hasParamsNull = false;
                            _paramsCurrentAction.forEach((item) => {
                                let depValue;

                                const key = item[1].replace(/[.|/]/g, "");

                                if (item[1].includes("../") || item[1].includes("//")) {
                                    if (inTable) {
                                        if (item[1].startsWith("../")) {
                                            // const key = item[1].replace("../", "");
                                            depValue = inputProps.items.data[key];
                                        }

                                        if (!depValue) {
                                            const path = item[1].split("/").filter((it) => it && it !== "..");
                                            if (path.length === 2 && path[0] === inputProps.items.key) {
                                                depValue = inputProps.items.data[path[1]];
                                            } else {
                                                depValue = inputProps.items.data[path[0]];
                                            }

                                            if (!depValue) {
                                                depValue = formData[path[0]] || formData[path[1]];
                                            }
                                        }
                                    } else if (inputAttribute._nodes || inputProps._nodes) {
                                        let nodes = inputAttribute._nodes || inputProps._nodes;

                                        const nodeLength = nodes.length - 1;

                                        let auxData = {};
                                        for (let i = 0; i <= nodeLength; i++) {
                                            const element = nodes[i];

                                            // if it's the first interaction
                                            if (i === 0) {
                                                auxData = formData[element];
                                            } else {
                                                auxData = auxData ? auxData[element] : null;
                                            }
                                        }
                                        // const key = item[1].replace("../", "");
                                        depValue = auxData ? auxData[key] : null;
                                    } else {
                                        depValue = formData[key];
                                    }
                                } else {
                                    depValue = item[1];
                                }

                                if (!depValue && key !== self) {
                                    hasParamsNull = true;
                                    return;
                                }

                                fd.append(item[0], depValue || "");
                            });

                            if (
                                hasParamsNull &&
                                (inputAttribute?.["@ignora_parametros"] !== "true" ||
                                    !inputAttribute?.["@ignora_parametros"])
                            ) {
                                continue;
                            }
                            if (value) {
                                fd.append("_valor_campo_", value);
                            }

                            try {
                                await sendHttpRequest("POST", "/Gestor/execQuery.php", fd).then((data) => {
                                    if (fillValue) {
                                        let _val;

                                        const result = Utilitaries.toArray(data.result)[0];
                                        if (result) {
                                            if (result[inputAttribute?.["@chave_lista"]]) {
                                                _val = result?.[inputAttribute?.["@chave_lista"]];
                                            } else {
                                                const keys = Object.keys(result);
                                                if (keys.length === 1) {
                                                    _val = result[keys[0]];
                                                }
                                            }
                                        }

                                        if (_val !== value && !isInitial) {
                                            changeInputValue({
                                                value: _val,
                                                key: self,
                                                appLoading: true,
                                                ...inputProps,
                                                inputAttribute,
                                                callback() {
                                                    setDefaultValue(_val);
                                                },
                                            });
                                        }
                                    } else {
                                        setTypeList("dinamica");

                                        if (Array.isArray(data.result)) {
                                            const defaultValue = data?.result?.find((f) => f._por_defeito === "t");
                                            if (defaultValue) {
                                                const _val = defaultValue?.[inputAttribute?.["@chave_lista"]];

                                                if (_val !== value && !isInitial) {
                                                    changeInputValue({
                                                        value: _val,
                                                        key: self,
                                                        appLoading: true,
                                                        ...inputProps,
                                                        inputAttribute,
                                                        callback() {
                                                            setDefaultValue(_val);
                                                        },
                                                    });
                                                }
                                            }
                                        }

                                        if (data) {
                                            setList(data.result);
                                        }
                                    }
                                });
                            } catch (error) {
                                //
                            }
                        }
                    }
                    setLoading(false);
                }
            };
            // formulario Nota de Credito - não carregar preso porque ja vem no query que carrega os dados na tabela
            if (
                inputProps?.inTable &&
                // inputProps.items.data[self] &&
                formParams &&
                formParams?.tipodoc === "NC" &&
                self === "armazem"
            ) {
                return;
            }
            getData();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        _params.map((item) => {
            let dependenceValue;
            if (!formData) {
                return null;
            }

            if (item[1].includes("../") || item[1].includes("//")) {
                if (inTable) {
                    if (item[1].startsWith("../")) {
                        const key = item[1].replace("../", "");
                        dependenceValue = inputProps.items.data[key];
                    }
                    if (!dependenceValue) {
                        const path = item[1].split("/").filter((it) => it && it !== "..");
                        if (path.length === 2 && path[0] === inputProps.items.key) {
                            dependenceValue = inputProps.items.data[path[1]];
                        } else {
                            dependenceValue = inputProps.items.data[path[0]];
                        }

                        if (!dependenceValue) {
                            dependenceValue = formData[path[0]] || formData[path[1]];
                        }
                    }
                } else if (inputAttribute._nodes || inputProps._nodes) {
                    let nodes = inputAttribute._nodes || inputProps._nodes;

                    const nodeLength = nodes.length - 1;

                    let auxData = {};
                    for (let i = 0; i <= nodeLength; i++) {
                        const element = nodes[i];

                        // if it's the first interaction
                        if (i === 0) {
                            auxData = formData[element];
                        } else {
                            auxData = auxData ? auxData[element] : null;
                        }
                    }
                    const key = item[1].replace("../", "");
                    dependenceValue = auxData ? auxData[key] : null;
                } else {
                    // const key = item[1].replace("[.|/]", "");
                    const key = item[1].replace(/[.|/]/g, "");
                    dependenceValue = formData[key];
                }
            } else {
                dependenceValue = item[1];
            }

            //
            // if (item.length === 1) {
            //     const fieldDepName = item[0].split(",")[1].replace("//", "");
            //     dependenceValue = formData[fieldDepName];
            // } else {
            //     if (!item[1].includes("../") && !item[1].includes("//")) {
            //         dependenceValue = formData[item[1]];
            //     } else if (inTable) {
            //         // dependenceValue =
            //         // 	formData[inputProps.items.table][inputProps.items.key][
            //         // 		inputProps.items.data.__key__
            //         // 	][item[1]];
            //         const path = item[1].split("/").filter((it) => it && it !== "..");
            //         if (path.length === 2 && path[0] === inputProps.items.key) {
            //             dependenceValue = inputProps.items.data[path[1]];
            //         } else {
            //             const castToNumber = Utilitaries.toNumber(path[0], true);
            //             if (castToNumber !== undefined && !isNaN(castToNumber)) {
            //                 dependenceValue = castToNumber || "";
            //             } else {
            //                 dependenceValue = inputProps.items.data[path[0]];
            //             }
            //         }
            //     } else if (inputAttribute._nodes || inputProps._nodes) {
            //         let nodes = inputAttribute._nodes || inputProps._nodes;

            //         const nodeLength = nodes.length - 1;

            //         let auxData = {};
            //         for (let i = 0; i <= nodeLength; i++) {
            //             const element = nodes[i];

            //             // if it's the first interaction
            //             if (i === 0) {
            //                 auxData = formData[element];
            //             } else {
            //                 auxData = auxData ? auxData[element] : null;
            //             }
            //         }

            //         dependenceValue = auxData ? auxData[item[1]] : null;
            //     } else {
            //         //
            //         dependenceValue = formData[item[1]];
            //     }
            // }

            return dependenceValue;
        })
    );

    // required and restriction dependencies
    let valueDepValidation = [];
    if (inputAttribute["@obrigatorio"] && Utilitaries.toString(inputAttribute?.["@obrigatorio"])?.includes("/")) {
        valueDepValidation = getAllDependencies(inputAttribute["@obrigatorio"]) || [];
    }
    if (inputAttribute["@restricao"] && Utilitaries.toString(inputAttribute?.["@restricao"])?.includes("/")) {
        const dependences = getAllDependencies(inputAttribute["@restricao"]) || [];
        valueDepValidation = [...new Set(valueDepValidation.concat(...dependences))];
    }

    useEffect(
        () => {
            if (valueDepValidation.length === 0) {
                return;
            }

            validateField({
                value,
                key: self,
                showErrorInModal: false,
                inputAttribute,
                ...inputProps,
                visible,
            });

            if (updateError) {
                const _err = hasError({ [self]: value });
                setError(_err);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDepValidation
            .filter((it) => it !== self)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    // Calcular
    const hasCalculation = useMemo(
        () =>
            hasInputCalculate({
                input: inputAttribute,
                formData,
                options: inputProps,
                formInputs: objectInputs,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData]
    );

    const dependenciesOri = useMemo(
        () =>
            Utilitaries.toArray(hasCalculation?.params)
                .filter((it) => it.key !== self)
                .map((it) => {
                    if (it?.tableSubKey) {
                        return Utilitaries.toArray(formData[it.tableKey]?.[it.tableSubKey])[it.tableIndex][it.key];
                    }
                    if (inputProps?.items?.data[it.key]) return inputProps.items.data[it.key];

                    return formData ? formData[it.key] : null;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasCalculation]
    );

    useEffect(
        () => {
            if (!inputAttribute?.["@calcular"] || hasCalculation?.params?.length === 0 || (value && isInitial)) {
                return;
            }

            let expression = input_validations({
                item: inputAttribute,
                relKey: "@calcular",
                formData: inputProps.inTable ? inputProps.items.data : formData,
                functions: {},
                returnExpression: true,
                allInputs: objectInputs,
            });

            // expression = expression.replace(/=/g, "==");
            try {
                let _value;
                if (inputAttribute["@calcular"]?.startsWith("x_lista://")) {
                    _value = expression;
                } else {
                    _value = eval(expression);
                }

                // if (oldCalculatedValue == _value || isNaN(_value)) {
                //     return;
                // }

                // oldCalculatedValue = _value;

                if ((inputProps.inTable && inputProps.items.data[self] == _value) || formData[self] == _value) {
                    return;
                }

                changeInputValue({
                    value: typeof _value === "number" ? _value : _value || "", // valor selecionado  in value
                    key: self, // Nome do campo
                    inputAttribute, // input atributos
                    ...inputProps, // propriedades da tabela
                    appLoading: true,
                    callback() {
                        // hasError({ [field]: _value });
                    },
                });
            } catch (error) {
                console.error(error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dependenciesOri]
    );

    if (!visible) {
        return null;
    }

    const selectedValue = (item) => {
        if (typeList === "dinamica") return item[inputAttribute["@chave_lista"]];

        if (item[1]) return item[1].trim();

        return item[0];
    };

    const optionsAttributes = (option) => {
        const attr = FormUtilitaries.getSelectAutoFillField(inputAttribute).reduce((acc, item) => {
            acc[`attr-${item.key}`] = option[item.value];

            return acc;
        }, {});

        return attr;
    };

    const RenderSelect = () => (
        <SelectInput
            options={list}
            value={selectedDefaultValue()}
            disabled={justSee || disabled}
            key_list={inputAttribute["@chave_lista"]}
            value_list={inputAttribute["@valor_lista"]}
            typeList={typeList}
            onChange={(value, option) => {
                const moreValues = FormUtilitaries.getSelectAutoFillField(inputAttribute);
                let allValues = {};
                if (moreValues.length > 0) {
                    allValues = moreValues.reduce((acc, item) => {
                        // const __val = option?.props?.[`attr-${item.key}`];
                        const __val = option?.[item.value];
                        if (__val) {
                            acc[item.key] = __val;
                        }

                        return acc;
                    }, {});
                }

                const _err = hasError({ [self]: value });
                if (!value && inputAttribute["$"]) {
                    changeInputValue({
                        value: inputAttribute["$"], // valor selecionado  in value
                        key: self, // NOme do campo
                        ...inputProps, // propriedades da tabela
                        inputAttribute, // input atributos
                        noMessage: true, // nao mostrar pop up com mesagem de campo errado
                        _value: option ? option.title : "", // valor selecionado - descricao do options
                        allValues,
                    });
                } else {
                    if (updateError) {
                        setError(_err);
                    }
                    changeInputValue({
                        value, // valor selecionado  in value
                        key: self, // NOme do campo
                        ...inputProps, // propriedades da tabela
                        inputAttribute, // input atributos
                        _value: option ? option.title : "", // valor selecionado - descricao do options
                        noMessage: true, // nao mostrar pop up com mesagem de campo errado
                        allValues,
                    });
                }
            }}
        />
        // <Select
        //     defaultValue={selectedDefaultValue()}
        //     showSearch
        //     disabled={justSee || disabled}
        //     style={
        //         inTable
        //             ? {
        //                   minWidth: inputProps.brothers.length > 5 ? "100px" : "200px",
        //               }
        //             : {}
        //     }
        //     allowClear
        //     // value={value}
        //     placeholder=""
        //     loading={loading}
        //     dropdownMatchSelectWidth={false}
        //     optionFilterProp="children"
        //     onChange={(value, option) => {
        //         const moreValues = FormUtilitaries.getSelectAutoFillField(inputAttribute);
        //         let allValues = {};
        //         if (moreValues.length > 0) {
        //             allValues = moreValues.reduce((acc, item) => {
        //                 const __val = option?.props?.[`attr-${item.key}`];
        //                 if (__val) {
        //                     acc[item.key] = __val;
        //                 }

        //                 return acc;
        //             }, {});
        //         }

        //         const _err = hasError({ [self]: value });
        //         if (!value && inputAttribute["$"]) {
        //             changeInputValue({
        //                 value: inputAttribute["$"], // valor selecionado  in value
        //                 key: self, // NOme do campo
        //                 ...inputProps, // propriedades da tabela
        //                 inputAttribute, // input atributos
        //                 noMessage: true, // nao mostrar pop up com mesagem de campo errado
        //                 _value: option && option.props ? option.props.title : "", // valor selecionado - descricao do options
        //                 allValues,
        //             });
        //         } else {
        //             if (updateError) {
        //                 setError(_err);
        //             }
        //             changeInputValue({
        //                 value, // valor selecionado  in value
        //                 key: self, // NOme do campo
        //                 ...inputProps, // propriedades da tabela
        //                 inputAttribute, // input atributos
        //                 _value: option && option.props ? option.props.title : "", // valor selecionado - descricao do options
        //                 noMessage: true, // nao mostrar pop up com mesagem de campo errado
        //                 allValues,
        //             });
        //         }
        //     }}
        //     filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        // >
        //     {list.map((item, idx) => {
        //         const isDisable = item?.["is_disable"] == "t" ? true : false;
        //         return (
        //             <Option
        //                 key={
        //                     typeList === "dinamica"
        //                         ? `${item[inputAttribute["@chave_lista"]]}${self}${idx + 500 * Math.random()}`
        //                         : item[1] + new Date().getTime() + self
        //                 }
        //                 value={selectedValue(item)}
        //                 title={
        //                     typeList === "dinamica"
        //                         ? item[
        //                               inputAttribute["@valor_lista"]
        //                                   .split(";")
        //                                   .filter((it) => !Utilitaries.isEmpty(it))[0]
        //                           ]
        //                         : item[0]
        //                 }
        //                 {...optionsAttributes(item)}
        //                 // attr-text={optionValue}
        //                 disabled={isDisable}
        //             >
        //                 {typeList === "dinamica"
        //                     ? inputAttribute["@valor_lista"]
        //                           .split(";")
        //                           .filter((it) => !Utilitaries.isEmpty(it))
        //                           .map((it) => item[it])
        //                           .join(" - ")
        //                     : item[0]}
        //             </Option>
        //         );
        //     })}
        // </Select>
    );

    return inTable ? (
        <>
            <RenderSelect />
            {error && <span style={{ color: "red" }}>{error}</span>}
        </>
    ) : (
        <>
            {renderGroup && <QADivider text={inputAttribute["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col key={"col-"} xs={colSize}>
                <Form.Item
                    label={
                        <QAToolTip
                            label={getInputLabel(inputAttribute, self)}
                            help={inputAttribute["@ajuda"]}
                            isRequired={isRequired}
                        />
                    }
                >
                    <RenderSelect />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                </Form.Item>
            </Col>
        </>
    );
};

QaSelect.propTypes = {};

export default QaSelect;
