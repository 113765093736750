import { Button, Icon, Popconfirm, Popover, Radio, Row, Table } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import React, { useEffect } from "react";
import { Utilitaries } from "../../../../business";
import { ComponentRender } from "../Components/ComponentRender";
import { getLabel } from "../Components/FormLabel";
import {
    AddTableRowProps,
    FormInputsObjectProps,
    FormInputsProps,
    RenderContextType,
    FormInputDependenceStateProps,
    RemoveTableRowProps,
    FormInputCalcTriggerProps,
    FormRootElementAttributesProps,
} from "../../../types/formTypes";
import { COMPONENT_TYPE } from "../../../utils/FormMapper/XMLInputType";
import { useData } from "../hooks/useData";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { Group } from "../Components/Group";
import { useInputHandle } from "../hooks/InputHandle";
import { GenericObject, handleOnTableChangeValueProps } from "../../../types/common";
import { mapLoad } from "../../../utils/loadMap";
import { getRowSpan } from "../../../utils/functions";

interface TableProps {
    componentsProperties: FormInputsProps;
    formState: FormInputsObjectProps;
    handleTableAddRow: ({ table_key, columns, input_group_path }: AddTableRowProps) => void;
    handleTableRemoveRow: ({ table_key, columns, input_group_path }: RemoveTableRowProps) => void;
    renderContext: RenderContextType;
    formInputDependenceState?: FormInputDependenceStateProps;
    inputCalcTrigger: FormInputCalcTriggerProps;
    handleOnTableChangeDataSource: (props: handleOnTableChangeValueProps) => void;
    popup_params?: GenericObject;
    justSee?: boolean;
    rootElementAttribute: FormRootElementAttributesProps;
}

const tablePagination: PaginationConfig = {
    defaultPageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registos`,
};

interface TableGetColumnsProps {
    componentsProperties: FormInputsProps;
    currentTaskStatus: boolean;
    disabled: boolean;
    handleTableRemoveRow: ({ table_key, columns, input_group_path }: RemoveTableRowProps) => void;
    changeAll: (value: boolean, input: FormInputsProps) => void;
    justSee?: boolean;
    dataSource: any[];
    rootElementAttribute: FormRootElementAttributesProps;
}

function getColumns({
    componentsProperties,
    currentTaskStatus,
    changeAll,
    dataSource,
    handleTableRemoveRow,
    disabled,
    justSee,
    rootElementAttribute,
}: TableGetColumnsProps) {
    let columns: any[] = [];

    const ContentSelections = (dataSource: any[], key: FormInputsProps) => (
        <Radio.Group onChange={(e) => changeAll(e.target.value, key)}>
            {dataSource.map((item: any) => (
                <Radio.Button key={item.key} value={item.key}>
                    {item.key}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
    for (const tableRootKey in componentsProperties) {
        if (typeof componentsProperties[tableRootKey] === "object" && tableRootKey !== "DATA_SOURCE") {
            for (const column in componentsProperties[tableRootKey]) {
                if (typeof componentsProperties[tableRootKey][column] === "object") {
                    let optionsSelect = null;

                    if (componentsProperties[tableRootKey][column]?.select_all === "sim") {
                        const dataSource: any[] = [];
                        optionsSelect = ContentSelections(dataSource, componentsProperties[tableRootKey][column]);
                    }

                    // const currentItem = setValueByPopupParams({
                    //     item: itemData?.[itemProp]?.[col],
                    //     params: allParams,
                    //     getFromLabel: true,
                    // });
                    const currentItem: FormInputsProps = componentsProperties[tableRootKey][column];

                    const { label } = getLabel({ componentsProperties: currentItem, required: false });

                    columns.push({
                        ...currentItem,
                        dataIndex: column,
                        title: (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minWidth: "80px",
                                }}
                            >
                                {label}
                                {currentItem?.select_all === "sim" &&
                                    !Utilitaries.isEmpty(currentItem?.values) &&
                                    !currentTaskStatus && (
                                        <Popover content={optionsSelect} title="Selecionar" trigger="click">
                                            <Button type="link" icon="edit" />
                                        </Popover>
                                    )}
                                {currentItem?.["@seleccionar_todos"] === "sim" &&
                                    Utilitaries.isEmpty(currentItem?.["@valores"]) && (
                                        <Popconfirm
                                            title="Selecionar todos como:"
                                            onConfirm={() => changeAll(true, currentItem)}
                                            onCancel={() => changeAll(false, currentItem)}
                                            okText="Sim"
                                            cancelText="Não"
                                            disabled={currentTaskStatus}
                                        >
                                            <Icon type="check-square" />
                                        </Popconfirm>
                                    )}
                            </div>
                        ),
                    });
                }
            }
        }
    }

    // const columnNumbers = columns.filter((item: any) => item.type && (!item.visible || item.visible !== "nao")).length;

    const invisibleColumns = columns.filter((column) => column?.visible === "nao");

    let renderizar = true;

    // const numbersOfColumnsToRender = Math.abs(columns.length - invisibleColumns.length);   

    const columnsToRender: any = columns
        .filter((column) => column.type && (!column?.visible || column?.visible !== "nao"))
        .map((column: FormInputsProps) => {
            // --- in case column has other component in it
            let item = column;
            if (column.type && (!column?.visible || column?.visible !== "nao")) {
                let render = {};

                if (renderizar) {
                    render = (text: string, record: any, index: number) => {
                        const columnKey = column.dataIndex;
                        // const value = record[columnKey];
                        const rowSpan = getRowSpan({ currentRowIndex: index, dataIndex: columnKey, rowSpanValue: column?.row_span, text, data:  dataSource});
                        return {
                            children: (
                                <>
                                    <ComponentRender
                                        key={"component_input_group" + column.key}
                                        renderContext="column"
                                        componentsProperties={column}
                                        tableRow={{ row_data: record, row_key: record.key }}
                                        justSee={justSee}
                                        rootElementAttribute={rootElementAttribute}
                                    />
                                    {invisibleColumns.map((column) => {
                                        // const columnKey = column.dataIndex;
                                        // const value = record[columnKey];
                                        return (
                                            <ComponentRender
                                                key={"component_input_group" + column.key}
                                                renderContext="column"
                                                componentsProperties={column}
                                                tableRow={{ row_data: record, row_key: record.key }}
                                                rootElementAttribute={rootElementAttribute}
                                            />
                                        );
                                    })}
                                </>
                            ),
                            props: {
                                rowSpan,
                            },
                        };
                        // };
                    };
                    // };
                    renderizar = false;
                } else {
                    render = (text: string, record: any, index: any) => {
                        const columnKey = column.dataIndex;
                        const rowSpan = getRowSpan({ currentRowIndex: index, dataIndex: columnKey, rowSpanValue: column?.row_span, text, data:  dataSource});

                        return {
                            children: (
                                <ComponentRender
                                    key={"component_input_group" + column.key}
                                    renderContext="column"
                                    componentsProperties={column}
                                    tableRow={{ row_data: record, row_key: record.key }}
                                    justSee={justSee}
                                    rootElementAttribute={rootElementAttribute}
                                />
                            ),

                            props: {
                                rowSpan,
                            },
                        };
                    };
                    // };
                }

                item = { ...column, render };
            }
            return item;
        });

    if (
        componentsProperties.type === COMPONENT_TYPE.DYNAMIC_TABLE &&
        componentsProperties.transaction_buttons !== "sem_botoes" &&
        componentsProperties.remove_buttons !== "nao" &&
        !disabled &&
        !justSee
        // &&
        // !currentTaskStatus
    ) {
        columnsToRender.push({
            dataIndex: "actions",
            title: "Ação",
            type: "deleteAction",
            width: "10%",
            render: (_text: string, record: any, index: any) => {
                return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        // eslint-disable-next-line no-script-url
                        href="javascript:;"
                        onClick={(e) => {
                            handleTableRemoveRow({
                                table_key: componentsProperties.key,
                                row_key: record.key,
                                columns,
                                input_group_path: componentsProperties.input_group_path || null,
                            });
                        }}
                        className="qa-text-del"
                    >
                        Eliminar
                    </a>
                );
            },
        });
    }

    return columnsToRender;
}

const getTableColumns = (componentsProperties: FormInputsProps) => {
    const keyFields = Object.keys(componentsProperties).find(
        (el) => el !== "DATA_SOURCE" && typeof componentsProperties[el] === "object"
    );
    const fields = componentsProperties[keyFields || ""];
    const tableFields = [];
    for (const key in fields) {
        if (typeof fields[key] === "object") {
            tableFields.push({ ...fields[key] });
        }
    }
    return tableFields;
};

export function QATable({
    componentsProperties,
    formState,
    handleTableAddRow,
    handleTableRemoveRow,
    renderContext,
    formInputDependenceState,
    handleOnTableChangeDataSource,
    inputCalcTrigger,
    popup_params,
    justSee,
    rootElementAttribute,
}: TableProps) {
    print_when_component_is_rendered(componentsProperties);

    const currentTaskStatus = true;
    const disabled = false;

    const changeAll = (value: boolean, input: FormInputsProps) => {};

    const data_source = componentsProperties?.DATA_SOURCE || {};

    let table_data_source: never[] = [];
    if (data_source) {
        if (!Array.isArray(data_source)) {
            table_data_source = Object.values(data_source);
        }
    } else {
        table_data_source = [];
    }

    const columns = getColumns({
        componentsProperties,
        currentTaskStatus,
        changeAll,
        dataSource: table_data_source,
        handleTableRemoveRow,
        disabled,
        justSee,
        rootElementAttribute,
    });

    function handleTableAdd() {
        if (justSee) {
            return;
        }
        const columns = getTableColumns(componentsProperties);
        handleTableAddRow({
            columns: [...columns],
            input_group_path: componentsProperties.input_group_path ? [...componentsProperties.input_group_path] : null,
            table_key: componentsProperties.key,
        });
    }

    const { data, status: dataStatus } = useData({
        endPoint: "execQuery.php",
        componentsProperties,
        state: formState,
        popup_params,
        rootElementAttribute,
    });

    useEffect(() => {
        if (dataStatus === "FETCHED") {
            if (data.length > 0) {
                const columns = getTableColumns(componentsProperties);
                handleOnTableChangeDataSource({
                    dataSource: data,
                    key: componentsProperties.key,
                    input_group_path: componentsProperties.input_group_path,
                    columns: [...columns],
                });
            } else {
                if (!justSee && Utilitaries.isEmpty(formState[`id_${componentsProperties.form_key}`])) {
                    handleTableAdd();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataStatus]);

    // useEffect(() => {
    //     if (
    //         !justSee &&
    //         !componentsProperties.data_query &&
    //         Utilitaries.isEmpty(formState[`id_${componentsProperties.form_key}`])
    //     ) {
    //         handleTableAdd();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const {
        data: whenValueChangeData,
        status: whenValueChangeStatus,
        // componentValue,
        // hasUpdatedValue,
        // required,
        visible,
        relevant,
        // readOnly,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[componentsProperties?.full_path || ""],
        formState,
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
    });

    useEffect(() => {
        if (whenValueChangeStatus === "FETCHED") {
            const columns = getTableColumns(componentsProperties);
            handleOnTableChangeDataSource({
                dataSource: whenValueChangeData,
                key: componentsProperties.key,
                input_group_path: componentsProperties.input_group_path,
                columns: [...columns],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whenValueChangeStatus]);

    if (!visible || !relevant) {
        return null;
    }

    return (
        <>
            <Group componentsProperties={componentsProperties} renderContext={renderContext}>
                {componentsProperties.type === COMPONENT_TYPE.SELECTION_TABLE && (
                    <div>
                        <Table
                            tableLayout="fixed"
                            size="small"
                            columns={columns}
                            dataSource={table_data_source}
                            pagination={tablePagination}
                            style={{ marginBottom: 32 }}
                            scroll={{ x: "max-content" }}
                            loading={mapLoad([dataStatus, whenValueChangeStatus])}
                        />
                    </div>
                )}
                {componentsProperties.type === COMPONENT_TYPE.DYNAMIC_TABLE && (
                    <div>
                        <Row>
                            {!justSee && componentsProperties.transaction_buttons !== "sem_botoes" && (
                                <Button
                                    icon="plus"
                                    type="primary"
                                    size="small"
                                    disabled={false}
                                    loading={false}
                                    onClick={handleTableAdd}
                                    style={{
                                        float: "right",
                                        marginLeft: 10,
                                        marginBottom: 10,
                                    }}
                                >
                                    Adicionar
                                </Button>
                            )}
                        </Row>
                        <Table
                            size="small"
                            columns={columns}
                            dataSource={table_data_source}
                            pagination={tablePagination}
                            style={{ marginBottom: 32 }}
                            scroll={{ x: "max-content" }}
                            loading={mapLoad([dataStatus, whenValueChangeStatus])}
                        />
                    </div>
                )}
            </Group>
        </>
    );
}
