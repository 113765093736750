/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext, memo, useMemo } from "react";
import { Col } from "antd";

import { QADivider, QAToolTip } from "./";
import { sendHttpRequest } from "../../http.request";
import FormContext from "../context/FormContext";
import { input_validations, getAllDependencies } from "../utils/validations";
import {
    getDataFormInputDependence,
    hasInputDependence,
    hasInputCalculate,
    getElementAction,
    getItemValue,
    getInputLabel,
} from "../utils/functions";
import { Utilitaries } from "../../../business";
import { toArray } from "../../util";
import { LineBreak } from "./lineBreak";

// Usados pela funcao eval nao tirar daqui
// eslint-disable-next-line no-unused-vars
const { round, round3 } = require("../utils/calc");

const QaLabel = ({
    inputAttribute,
    field,
    index,
    visible,
    hasError,
    value: labelValue,
    renderGroup,
    objectInputs,
    colSize,
    ...inputProps
}) => {
    const [dados, setDados] = useState(null);
    // const [value, setValue] = useState(null);
    const { params: formParams, formData, changeInputValue, isInitial, validateField } = useContext(FormContext);

    let valueDepRequired = [];
    if (inputAttribute["@restricao"] && Utilitaries.toString(inputAttribute?.["@restricao"])?.includes("/")) {
        valueDepRequired = getAllDependencies(inputAttribute["@restricao"]) || [];
    }

    useEffect(
        () => {
            if (!inputAttribute["@restricao"] || !Utilitaries.toString(inputAttribute?.["@restricao"])?.includes("/")) {
                return;
            }

            validateField({
                value: labelValue,
                key: field,
                inputAttribute,
                showErrorInModal: false,
                ...inputProps,
                visible,
            });

            // if (updateError) {
            //     const _err = hasError({ [field]: value });
            //     setError(_err);
            // }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        valueDepRequired
            .filter((it) => it !== field)
            .map((it) => {
                if (inputProps?.items?.data[it]) return inputProps.items.data[it];

                return formData ? formData[it] : null;
            })
    );

    useEffect(() => {
        const getData = async () => {
            const formData = new FormData();
            formData.append("accao", "query");
            let dados = inputAttribute["@dados"];

            dados = dados.split(",");

            let queryParams = inputAttribute["@dados"].replace(/\s/g, "").split(",{");

            if (queryParams?.length > 1) {
                queryParams = queryParams?.[1].split(";").forEach((param) => {
                    param = param.replace(/[.|/|{|}]/g, "");

                    // feito para caso de parametro ter valor direto na query dados="fac:serie, {tipodoc, RD}"
                    const [paramName, paramValue] = param.split(",");
                    formData.append(paramName, paramValue);
                });
            }

            formData.append("query", dados[0]);

            return sendHttpRequest("POST", "/Gestor/execQuery.php", formData).then((data) => {
                let result = data.result;
                setDados(result);

                result = result[0];
                const keys = Object.keys(result || {});

                if (keys.length === 1) {
                    result = result[keys[0]];
                } else {
                    const key = inputAttribute?.["@valor_lista"];
                    result = Utilitaries.toString(result?.[key]);
                }

                changeInputValue({
                    key: field,
                    value: result,
                    appLoading: true,
                    inputAttribute,
                    ...inputProps,
                });
            });
        };

        if (
            (!inputProps || !inputProps.inputType || !inputProps.inputType === "etiqueta_value") &&
            inputAttribute["@dados"]
        ) {
            //
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputAttribute["@dados"]]);

    const hasDeps = hasInputDependence({
        input: inputAttribute,
        formData,
        options: inputProps,
        formInputs: objectInputs,
    });

    // Get value
    let valueDep = [];
    let inputActionsName = null;

    const elementAction = getElementAction({ item: inputAttribute });
    if (elementAction) {
        valueDep = getAllDependencies(elementAction.valueDep);
        inputActionsName = elementAction.inputActionsName;
    }

    let allValuesDependencies = [...valueDep];

    if (hasDeps.hasActions) {
        allValuesDependencies = [
            ...new Set([
                ...allValuesDependencies,
                ...hasDeps.params.filter((it) => !Utilitaries.isEmpty(it.value)).map((it) => it.value),
            ]),
        ];
    }

    useEffect(
        () => {
            const getData = async () => {
                let result;
                if (inputAttribute[inputActionsName].includes("auto_")) {
                    if (
                        (inputAttribute[inputActionsName].includes("nome_estabelecimento") &&
                            inputProps.inTable &&
                            inputProps.items.data[field]) ||
                        (isInitial && ((inputProps.inTable && inputProps.items.data[field]) || formData[field]))
                    ) {
                        if (!inputAttribute["@action_with_value"]) {
                            return;
                        }
                    }

                    if (
                        (hasDeps?.query === "fac:sugere_numero" || hasDeps?.query === "fac:sugere_numero_recibo") &&
                        !formData["serie"]
                    ) {
                        return;
                    }

                    if (
                        hasDeps?.query === "stk:stockArmazem_Novo" &&
                        !hasDeps?.params?.find((it) => it.key === "armazemdest")?.value &&
                        !hasDeps?.params?.find((it) => it.key === "armazem")?.value
                    ) {
                        return;
                    }

                    result = await getDataFormInputDependence({
                        key: field,
                        input: inputAttribute,
                        options: inputProps,
                        formInputs: objectInputs,
                        ...hasDeps,
                    });
                } else {
                    let expression = input_validations({
                        item: inputAttribute,
                        // relKey: "@calcular",
                        relKey: inputActionsName,
                        // formData: inputProps.inTable ? inputProps.items.data : formData,
                        formData: formData,
                        inputProps: inputProps,
                        functions: {},
                        returnExpression: false,
                    });
                    result = expression;
                }

                changeInputValue({
                    key: field,
                    value: result,
                    inputAttribute,
                    appLoading: true,
                    ...inputProps,
                });
            };

            if (hasDeps.hasActions) {
                // formulario Nota de Credito - não carregar preso porque ja vem no query que carrega os dados na tabela
                if (
                    inputProps?.inTable &&
                    // inputProps.items.data[self] &&
                    formParams &&
                    formParams?.tipodoc === "NC" &&
                    field === "unidade"
                ) {
                    return;
                }

                getData();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        allValuesDependencies
            .filter((it) => it !== field)
            .map((it) => {
                let itValue;
                if (inputProps?.items?.data.hasOwnProperty(it)) {
                    itValue = inputProps.items.data[it] || null;
                } else {
                    itValue = formData ? formData[it] : null;

                    if (!itValue) {
                        itValue = getItemValue({ itemKey: it, formData, objectInputs });
                    }
                }

                return itValue;
            })
    );

    // use just for get data and update state
    useEffect(() => {
        if (!inputAttribute["utility"]) {
            return;
        }
        const getData = async () => {
            try {
                const dataFormData = new FormData();
                dataFormData.append("accao", "dados");

                let dados = inputAttribute["@dados"];

                dados = dados.split(",");

                dataFormData.append("query", dados[0]);

                const data = await sendHttpRequest("POST", "/Gestor/execQuery.php", dataFormData);

                if (data.result[0]) {
                    const _key = Object.entries(data.result[0])[0][0];
                    const _value = Object.entries(data.result[0])[0][1];
                    //

                    changeInputValue({
                        value: _value, // valor selecionado  in value
                        key: _key, // Nome do campo
                        appLoading: true,
                        inputAttribute, // input atributos
                        ...inputProps, // propriedades da tabela
                        // callback() {
                        // 	hasError({ [field]: _value });
                        // },
                    });
                }
            } catch (error) {}
        };

        //
        // 	"triggerActionWhenFormStateChange",
        // 	field,
        // 	inputAttribute,
        // 	inputProps
        // );

        if (inputAttribute["@dados"]) {
            getData();
        } else if (inputAttribute["@calcular"]) {
            let expression = input_validations({
                item: inputAttribute,
                relKey: "@calcular",
                formData: inputProps.inTable ? inputProps.items.data : formData,
                functions: {},
                returnExpression: true,
            });

            expression = expression.replace(/=/g, "==");

            // if (expression === "formData['versao_atual'] + 1") {
            // 	expression = parseInt(formData["versao_atual"] || 0) + 1;
            // } else {
            expression = `${expression}}`;
            // }

            try {
                const _value = eval(expression);
                changeInputValue({
                    value: _value || "", // valor selecionado  in value
                    key: field, // Nome do campo
                    inputAttribute, // input atributos
                    appLoading: true,
                    ...inputProps, // propriedades da tabela
                    callback() {
                        // hasError({ [field]: _value });
                    },
                });
            } catch (error) {
                console.error(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasCalculation = useMemo(
        () =>
            hasInputCalculate({
                input: inputAttribute,
                formData,
                options: inputProps,
                formInputs: objectInputs,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData]
    );

    const dependenciesOri = useMemo(
        () =>
            toArray(hasCalculation?.params)
                .filter((it) => it.key !== field)
                .map((it) => {
                    if (it?.tableSubKey) {
                        return toArray(formData[it.tableKey]?.[it.tableSubKey])[it.tableIndex][it.key];
                    }
                    if (inputProps?.items?.data[it.key]) return inputProps.items.data[it.key];

                    return formData ? formData[it.key] : null;
                }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasCalculation]
    );

    useEffect(
        () => {
            if (!inputAttribute?.["@calcular"]) {
                return;
            }
            if (
                labelValue &&
                isInitial &&
                (inputAttribute?.["@persiste"] !== "nao" || !inputAttribute?.["@persiste"])
            ) {
                return;
            }
            // if (
            //     !inputAttribute?.["@calcular"] ||
            //     hasCalculation?.params?.length === 0 ||
            //     (labelValue && isInitial && (inputAttribute?.["@persiste"] !== "nao" || !inputAttribute?.["@persiste"]))
            // ) {
            //     return;
            // }

            if (hasCalculation?.params?.length === 0) {
                if (
                    inputAttribute?.["@calcular"]?.startsWith("count(") ||
                    inputAttribute?.["@calcular"].startsWith("sum(")
                ) {
                    changeInputValue({
                        value: 0, // valor selecionado  in value
                        key: field, // Nome do campo
                        inputAttribute, // input atributos
                        ...inputProps, // propriedades da tabela
                        appLoading: true,
                        callback() {
                            // hasError({ [field]: _value });
                        },
                    });
                }
                return;
            }

            let expression = input_validations({
                item: inputAttribute,
                relKey: "@calcular",
                formData: inputProps.inTable ? inputProps.items.data : formData,
                functions: {},
                returnExpression: true,
                allInputs: objectInputs,
            });

            // expression = expression.replace(/=/g, "==");
            try {
                let _value;
                if (inputAttribute["@calcular"]?.startsWith("x_lista://")) {
                    _value = expression;
                } else {
                    _value = eval(expression);
                }

                // if (oldCalculatedValue == _value || isNaN(_value)) {
                //     return;
                // }

                // oldCalculatedValue = _value;

                if ((inputProps.inTable && inputProps.items.data[field] == _value) || formData[field] == _value) {
                    return;
                }

                changeInputValue({
                    value: typeof _value === "number" ? _value : _value || "", // valor selecionado  in value
                    key: field, // Nome do campo
                    inputAttribute, // input atributos
                    ...inputProps, // propriedades da tabela
                    appLoading: true,
                    callback() {
                        // hasError({ [field]: _value });
                    },
                });
            } catch (error) {
                console.error(error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dependenciesOri]
    );

    if (inputAttribute["utility"] && inputAttribute["utility"] === "update_state") {
        return null;
    }

    if (!visible) {
        return null;
    }

    if ((inputProps && inputProps.inputType && inputProps.inputType === "etiqueta_value") || inputProps.inTable) {
        return Utilitaries.toString(inputProps.inTable ? inputProps.items.data[field] : formData[field]);
    }

    if (!inputAttribute["@dados"] && !inputAttribute["@etiqueta"] && !inputAttribute["$"]) {
        if (inputAttribute["@grupo"]) {
            return <QADivider text={inputAttribute["@grupo"]} />;
        }

        return null;
    }

    // const renderValue = () => {
    //     if (labelValue) {
    //         if (inputAttribute["@dados"]) {
    //             return <pre style={{ whiteSpace: "pre-wrap" }}>{labelValue}</pre>;
    //         }

    //         return <pre style={{ whiteSpace: "pre-wrap" }}>{labelValue}</pre>;
    //     } else if (inputAttribute["$"] && inputAttribute["$"].startsWith("{$param.")) {
    //         if (params) {
    //             return params[inputAttribute["$"].replace("{$param.", "").replace("}", "")];
    //         }
    //         return inputAttribute["$"];
    //     } else if (inputAttribute["$"]) {
    //         return <pre style={{ whiteSpace: "pre-wrap" }}>{inputAttribute["$"]}</pre>;
    //     }
    //     return null;
    // };
    if (labelValue && labelValue?.toString().includes("<br>")) {
        labelValue = labelValue.replaceAll("<br>", "\n");
    }

    const renderValue = () => {
        if (!Utilitaries.isEmpty(labelValue)) {
            return <pre style={{ whiteSpace: "pre-wrap" }}>{labelValue}</pre>;
        }
        return null;
    };

    if (!inputAttribute["@dados"] || (inputAttribute["@dados"] && labelValue)) {
        const isBold = inputAttribute["@estilo"]?.includes("negrito");
        return (
            <>
                {<LineBreak item={inputAttribute} />}
                <Col xs={colSize || 24}>
                    <QAToolTip
                        isBold={isBold}
                        label={getInputLabel(inputAttribute, field)}
                        help={inputAttribute["@ajuda"]}
                    />
                    <div>
                        <p>{renderValue() || " "}</p>
                    </div>
                </Col>
            </>
        );
    }

    return (
        <>
            {renderGroup && <QADivider text={inputAttribute?.["@grupo"]} />}
            {<LineBreak item={inputAttribute} />}
            <Col xs={colSize}>
                <pre>{dados ? dados?.[0]?.instru : null}</pre>
            </Col>
        </>
    );
};

export default memo(QaLabel);
