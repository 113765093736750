import React, { useState } from "react";
import { Form, Icon, Input, List, Button, Typography, Alert } from "antd";
import { Link, withRouter } from "react-router-dom";
import { api } from "../../http.request";
import { Session, Theme } from "../../../business";
import { getuserComputerInfo, queryToString } from "../../util";
import { IS_UNICV, DEV_UNICV } from "../../../constants/enviroment";

const { Title } = Typography;

const LoginForm = (props) => {
    const [error, setError] = useState(null);
    const [listLogin, setListLogin] = useState([]);
    const [loading, setLoading] = useState(false);

    const userInfo = getuserComputerInfo();

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        props.form.validateFields(async (err, values) => {
            if (!err) {
                Session.remove();
                Session.unlockScreen();

                setLoading(true);
                setListLogin([]);
                const formData = new FormData();

                formData.append("accao", DEV_UNICV ? "login-teste" : "login-json");
                formData.append("utilizador", values.username);
                formData.append("senha", values.password);
                formData.append("os", userInfo.plataform);
                formData.append("browser", userInfo.browser);
                formData.append("computer", userInfo.computer_uuid);
                try {
                    const resp = await api("/Gestor/gereutilizador.php", formData);

                    if (resp.erro) {
                        setError(resp.erro);

                        if (resp.list) {
                            setListLogin(resp.list);
                        }
                    } else {
                        Session.save({
                            username: resp.username,
                            name: resp.nome,
                            token: resp.token,
                            type: resp.tipo,
                            photoURL: resp.foto_unicv,
                            isFirstLogin: resp.isFirstLogin,
                            parametro1: resp.parametro1,
                            parametro2: resp.parametro2,
                            parametro3: resp.parametro3,
                        });
                        const query = queryToString();

                        if (query["redirect"]) {
                            const redirect = query["redirect"];
                            if (redirect.startsWith("/login")) {
                                window.location.hash = "/";
                            } else {
                                window.location.hash = redirect || "/";
                            }
                            // window.location.href = query["redirect"] || "/";
                            window.location.reload();
                            // props.history.replace(query["redirect"] || "/"); // Não carega a pagina
                        } else {
                            // props.history.replace("/");
                            window.location.reload();
                            // window.history.back()
                        }
                    }
                } catch (error) {
                    console.error(error);
                    setError(error.erro);

                    if (error.list) {
                        setListLogin(error.list);
                    }
                }
                setLoading(false);
            }
        });
    };

    const logout = async (id) => {
        const formData = new FormData();
        formData.append("accao", "logout");
        formData.append("id", id);

        try {
            const resp = await api("/Gestor/gereutilizador.php", formData);
            if (!resp.erro) {
                setListLogin(listLogin.filter((it) => it.id !== id));
            }
        } catch (error) {}
    };

    const { getFieldDecorator } = props.form;

    return (
        <>
            <div className="login-body">
                {/* <Title>QualityMaking Login</Title> */}

                <div style={{ width: "300px", marginTop: "20px" }}>
                    {error && <Alert message={error} type="error" banner />}
                </div>

                {listLogin.length > 0 && (
                    <List
                        header={<div>Logins ativos ({listLogin.length})</div>}
                        dataSource={listLogin}
                        renderItem={(item) => (
                            <List.Item key={item.id}>
                                <List.Item.Meta description={item.os + " - " + item.browser} />
                                <div>
                                    <Button type="link" onClick={() => logout(item.id)}>
                                        Logout
                                    </Button>
                                </div>
                            </List.Item>
                        )}
                    ></List>
                )}

                <Form onSubmit={handleSubmit} className="login-form">
                    <Form.Item>
                        {getFieldDecorator("username", {
                            rules: [
                                {
                                    required: true,
                                    message: "Informe o E-mail!",
                                },
                            ],
                            initialValue: Session.getLastLoginUsername(),
                        })(
                            <Input
                                disabled={loading}
                                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                                placeholder="Utilizador"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: true,
                                    message: "Informe a senha!",
                                },
                            ],
                        })(
                            <Input
                                disabled={loading}
                                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                type="password"
                                placeholder="Senha"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Link className="login-form-forgot" to="/request-reset-password">
                            Esqueci-me da senha!
                        </Link>
                        <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                            Login
                        </Button>

                        {IS_UNICV && (
                            <Link to="/register/unicv" style={{ marginTop: 10 }}>
                                <Button type="link" block style={{ marginTop: 10 }}>
                                    Criar conta
                                </Button>
                            </Link>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default withRouter(Form.create({ name: "normal_login" })(LoginForm));
