import { Badge, Button, Checkbox, Divider, Icon, Input, message, Popconfirm, Row, Select, Table, Upload } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Utilitaries } from "../../../../../business";
import ButtonDownloadDocument from "../../../../../modules/utilities/component/button.download.document";
import { api_fetch } from "../../../../utils/HttpRequest/request";
import { DaysModal } from "../Components/DaysModal";
import { DataChangeProps, FormDataProps } from "../utils/types";

interface IProps extends DataChangeProps {
    formData: FormDataProps;
    form: WrappedFormUtils;
}

const table_key1 = "linha";
const table_key2 = "falta";

export function Fouls({ form, formData, handleAdd, handleDelete, onChange }: IProps) {
    const [systemFauls, setSystemFauls] = useState<any[]>([]);
    const [reasonFauls, setReasonFauls] = useState<any[]>([]);
    const [salaryReasonFauls, setSalaryReasonFauls] = useState<any[]>([]);
    const [years, setYears] = useState<any[]>([]);
    const [months, setMonths] = useState<any[]>([]);
    const [searchText, setSearchText] = useState("");
    const [showModal, setShowModal] = useState<any>(null);

    const searchInput = React.useRef<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await api_fetch({
                endPoint: "/Gestor/execQuery.php",
                method: "POST",
                params: {
                    query: "buscar_faltas_sistema_user",
                    id: formData.id_ficha_colaborador,
                },
            });

            if (response.status === "SUCCESS") {
                setSystemFauls(response.response.result);
            }
        };

        fetchData();
        getQuery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getQuery = async () => {
        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "lista_motivos_faltas",
                tipo: "FC",
                username: form.getFieldValue("username"),
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                setReasonFauls(Utilitaries.toArray(response.response.result));
            }
        });
        // load tipo faltas
        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "lista_motivos_faltas",
                tipo: "S",
                username: form.getFieldValue("username"),
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                setSalaryReasonFauls(Utilitaries.toArray(response.response.result));
            }
        });

        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "lista_anos",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                setYears(response.response.result);
            }
        });

        api_fetch({
            endPoint: "/Gestor/execQuery.php",
            method: "POST",
            params: {
                query: "meses",
            },
        }).then((response) => {
            if (response.status === "SUCCESS") {
                setMonths(response.response.result);
            }
        });
    };

    const getColumnSearchProps = (dataIndex: string) => {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Pesquisar`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
            onFilter: (value: any, record: any) =>
                record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text: any) => (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ),
        };
    };

    const handleSearch = (selectedKeys: any, confirm: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText("");
    };

    async function uploadFileToServer(file: any, record: any) {
        try {
            const response = await api_fetch({
                endPoint: "/Gestor/gereficheiros.php",
                method: "POST",
                params: {
                    accao: "upload",
                    nome: file.name,
                    descricao: file.type,
                    ficheiro: file,
                    chave_sessao: "teste",
                },
            });

            if (response.status === "SUCCESS") {
                onChange({
                    dataIndex: "ficheiro",
                    value: response.response.sucesso,
                    row_key: record.key,
                    table_key1,
                    table_key2,
                });
            } else {
                message.error("Erro ao gravar ficheiro!!");
            }
        } catch (error) {
            message.error("Erro ao comunicar com servidor!!");
        }
    }

    const showDaysModel = (record: any) => {
        setShowModal(record);
    };

    const faltas_sistema_column = [
        {
            title: "Data",
            dataIndex: "data_f_si",
            width: 200,
            ...getColumnSearchProps("data_f_si"),
        },
        {
            title: "Justificação",
            dataIndex: "justificacao_f_si",
            ...getColumnSearchProps("justificacao_f_si"),
        },
    ];

    const columns: any[] = [
        {
            title: "Motivo",
            dataIndex: "motivo_falta",
            width: "25%",
            ...getColumnSearchProps("motivo_falta"),
            onFilter: (value: any, record: any) => {
                const filteredValue = reasonFauls
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.motivo_falta);
                return filteredValue.includes(record["motivo_falta"]);
            },
            render: (text: any, record: any) => {
                const listOptions = record.tipo_falta === "S" ? salaryReasonFauls : reasonFauls;
                return (
                    <Select
                        showSearch
                        allowClear
                        style={{ width: "100%", margin: "0 auto", padding: "0" }}
                        placeholder="seleciona uma opção"
                        optionFilterProp="children"
                        onChange={(value: any) =>
                            onChange({ dataIndex: "motivo_falta", value, row_key: record.key, table_key1, table_key2 })
                        }
                        defaultValue={typeof text !== "object" && text !== "" ? text : undefined}
                        filterOption={(input, option) =>
                            String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {listOptions.map((item) => (
                            <Select.Option key={item.motivo_falta} value={item.motivo_falta}>
                                {item.designacao}
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
        {
            title: "Ano",
            dataIndex: "ano",
            width: "10%",
            ...getColumnSearchProps("ano"),
            onFilter: (value: any, record: any) => {
                const filteredValue = years
                    .filter((item) => item.designacao.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.ano);
                return filteredValue.includes(record["ano"]);
            },
            render: (text: any, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona uma opção"
                    optionFilterProp="children"
                    onChange={(value: any) =>
                        onChange({ dataIndex: "ano", value, row_key: record.key, table_key1, table_key2 })
                    }
                    defaultValue={typeof text !== "object" && text !== "" ? text : undefined}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {years
                        .filter((it) => it.ano <= new Date().getFullYear())
                        .map((item) => (
                            <Select.Option key={item.ano} value={item.ano}>
                                {item.designacao}
                            </Select.Option>
                        ))}
                </Select>
            ),
        },
        {
            title: "Mês",
            dataIndex: "mes",
            width: "10%",
            ...getColumnSearchProps("mes"),
            onFilter: (value: any, record: any) => {
                const filteredValue = months
                    .filter((item) => item.nome.toString().toLowerCase().includes(value.toLowerCase()))
                    .map((item) => item.mes);
                return filteredValue.includes(record["mes"]);
            },
            render: (text: any, record: any) => (
                <Select
                    showSearch
                    allowClear
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    placeholder="seleciona uma opção"
                    optionFilterProp="children"
                    onChange={(value: any) =>
                        onChange({ dataIndex: "mes", value, row_key: record.key, table_key1, table_key2 })
                    }
                    defaultValue={typeof text !== "object" && text !== "" ? text : undefined}
                    filterOption={(input, option) =>
                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {months
                        // .filter((it) => (record.id_falta ? true : it.mes <= new Date().getMonth() + 1))
                        .map((item) => (
                            <Select.Option key={item.mes} value={item.mes}>
                                {item.nome}
                            </Select.Option>
                        ))}
                </Select>
            ),
        },
        {
            title: "Justificação",
            dataIndex: "justificacao",
            align: "center",
            width: "10%",
            render: (text: any, record: any) => (
                <Checkbox
                    defaultChecked={text === "t" && typeof text !== "object" ? true : false}
                    style={{ width: "100%", margin: "0 auto", padding: "0" }}
                    onChange={(e) =>
                        onChange({
                            dataIndex: "justificacao",
                            value: e.target.checked,
                            row_key: record.key,
                            table_key1,
                            table_key2,
                        })
                    }
                />
            ),
        },
        {
            title: "Documento",
            dataIndex: "ficheiro",
            align: "center",
            width: "15%",
            render: (text: any, record: any) => {
                if (record["ficheiro"] && Object.entries(record["ficheiro"]).length !== 0) {
                    return <ButtonDownloadDocument ficheiro={record["ficheiro"]} text="Descarregar" />;
                } else {
                    return (
                        <Upload
                            defaultFileList={typeof text !== "object" && text ? [text] : []}
                            beforeUpload={(file: any) => {
                                uploadFileToServer(file, record);
                                return false;
                            }}
                            name="file"
                            multiple={false}
                            onRemove={() =>
                                onChange({
                                    dataIndex: "ficheiro",
                                    value: "",
                                    row_key: record.key,
                                    table_key1,
                                    table_key2,
                                })
                            }
                        >
                            {!record["ficheiro"] ? (
                                <Button type="primary" icon="file-add" />
                            ) : Object.entries(record["ficheiro"]).length === 0 ? (
                                <Button type="primary" icon="file-add" />
                            ) : null}
                        </Upload>
                    );
                }
            },
        },
        {
            title: "Dias",
            dataIndex: "days",
            width: "5%",
            render: (text: any, record: any) => (
                <Badge
                    count={
                        typeof record["numero_dias"] !== "object" &&
                        typeof record["faltas_colaborador_dias"] !== "object"
                            ? record["numero_dias"]
                            : 0
                    }
                >
                    <Button
                        icon={typeof record["faltas_colaborador_dias"] !== "object" ? "check" : "plus"}
                        type="primary"
                        disabled={
                            record.ano && typeof record.ano !== "object" && record.mes && typeof record.mes !== "object"
                                ? false
                                : true
                        }
                        onClick={() => showDaysModel(record)}
                    />
                </Badge>
            ),
        },
        {
            title: "Ações",
            dataIndex: "action",
            width: "10%",
            render: (text: any, record: any) => (
                <Popconfirm title="Remover a linha?" onConfirm={() => handleDelete(record.key, table_key1, table_key2)}>
                    <a style={{ width: "100%", margin: "0 auto" }} href="!#" className="qa-text-del">
                        Remover
                    </a>
                </Popconfirm>
            ),
        },
    ];

    function handleClose(cancel: boolean, record: any) {
        if (!cancel) {
            onChange({
                dataIndex: "",
                value: record,
                row_key: record.key,
                table_key1,
                table_key2,
            });
        }
        setShowModal(null);
    }

    return (
        <>
            <Divider orientation="left">Faltas de sistema</Divider>
            <Table
                rowKey={(record: any) => record.key}
                size="middle"
                columns={faltas_sistema_column}
                dataSource={systemFauls}
            />

            <Divider orientation="left">Outras faltas</Divider>

            <Row>
                <Button
                    onClick={() => handleAdd(table_key1, table_key2)}
                    icon="plus"
                    size="default"
                    type="primary"
                    style={{ marginBottom: 16, float: "right" }}
                >
                    Adicionar
                </Button>
            </Row>
            <Table
                rowKey={(record: any) => record.key}
                size="middle"
                columns={columns}
                dataSource={formData.linha.falta}
            />

            {showModal && (
                <DaysModal
                    id={Utilitaries.toString(showModal?.["faltas_colaborador_dias"])}
                    handleClose={handleClose}
                    isVisible={showModal !== null}
                    name={form.getFieldValue("nome_completo")}
                    record={showModal}
                    username={form.getFieldValue("username")}
                    workDays={Utilitaries.toArray(form.getFieldValue("dias_trabalho")).map((item: any) => Number(item))}
                />
            )}
        </>
    );
}
