import { Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Utilitaries } from "../../../../business";
import { print_when_component_is_rendered } from "../../../../utils/develop";
import { CommunComponentsProps } from "../../../types/common";
import { getDataSource, getSelectDataKeys } from "../../../utils/FormMapper/formHandle";
import { highlightsErrors } from "../../../utils/functions";
import { FormLabel } from "../Components/FormLabel";
import { Group } from "../Components/Group";
import { compareProps, getFullPathRow } from "../handle/inputHandle";
import { useInputHandle } from "../hooks/InputHandle";

interface ComponentsPropertiesProps extends CommunComponentsProps {}

const { Option } = Select;

const SelectStaticComponent: React.FC<ComponentsPropertiesProps> = ({
    componentsProperties,
    renderContext,
    handleOnInputChangeValue,
    formInputDependenceState,
    formState,
    inputCalcTrigger,
    tableRow,
    justSee,
    triedSave,
    rootElementAttribute,
}) => {
    print_when_component_is_rendered(componentsProperties);
    const [showError, setShowError] = useState(false);

    useEffect(() => setShowError(triedSave), [triedSave]);

    const dataSource = getDataSource(componentsProperties);

    let { key: optionKey, value: valueOption } = getSelectDataKeys(componentsProperties);

    const value =
        renderContext === "column" ? tableRow?.row_data?.[componentsProperties.key] : componentsProperties.value;

    const selectedDefaultValue = () => {
        const _value_ = Utilitaries.nullSafe(value);

        const item = Utilitaries.toArray(dataSource)?.find(
            (it: any) => Utilitaries.toString(it[optionKey]) === Utilitaries.toString(_value_)
        );

        if (item) {
            return String(Utilitaries.toString(item[optionKey]));
        }

        if (componentsProperties.key === "pagar_por") {
            return _value_;
        }

        return "";
    };

    const fullPathRow = getFullPathRow(componentsProperties, tableRow?.row_key);
    const full_path = tableRow?.row_key ? fullPathRow : componentsProperties?.full_path;

    const {
        status: whenValueChangeStatus,
        componentValue,
        hasUpdatedValue,
        required,
        restriction,
        visible,
        relevant,
        readOnly,
        error,
    } = useInputHandle({
        componentProperties: componentsProperties,
        when_value_changed_dependence: formInputDependenceState?.[full_path!],
        calcTrigger: inputCalcTrigger?.[componentsProperties?.full_path || ""],
        calcRowTrigger: inputCalcTrigger?.[fullPathRow],
        formState,
        rowTable: tableRow,
        value,
        showError,
        rootElementAttribute,
    });

    useEffect(() => {
        if (hasUpdatedValue && whenValueChangeStatus !== "IDLE") {
            handleOnInputChangeValue({
                value: componentValue,
                componentsProperties,
                row_key: tableRow?.row_key,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentValue, hasUpdatedValue]);

    if (!visible || !relevant) {
        return null;
    }

    return (
        <Group componentsProperties={componentsProperties} renderContext={renderContext}>
            <FormLabel componentsProperties={componentsProperties} required={required} renderContext={renderContext}>
                <Select
                    showSearch
                    allowClear
                    dropdownMatchSelectWidth={false}
                    style={{ ...highlightsErrors({ value, required, restriction }), width: "100%" }}
                    disabled={justSee || readOnly}
                    value={selectedDefaultValue()}
                    placeholder={componentsProperties.PLACEHOLDER || ""}
                    onChange={(value: string, option: any) => {
                        const selectOptions = option as any;
                        const select_value_text = selectOptions?.props?.["attr-text"];
                        handleOnInputChangeValue({
                            value,
                            componentsProperties,
                            select_value_text,
                            row_key: tableRow?.row_key,
                        });
                        setShowError(true);
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        String(option?.props?.children)?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    {Utilitaries.toArray(dataSource)?.map((data: any) => {
                        let optionText = "";
                        if (!Array.isArray(valueOption)) {
                            optionText = data[valueOption];
                        }
                        return (
                            <Option
                                key={componentsProperties.key + Utilitaries.toString(data[optionKey])}
                                attr-text={optionText}
                                value={Utilitaries.toString(data[optionKey])}
                                title={optionText}
                            >
                                {optionText}
                            </Option>
                        );
                    })}
                </Select>

                {showError && error && <span style={{ color: "red" }}>{error}</span>}
            </FormLabel>
        </Group>
    );
};

export const QASelectStatic = memo(SelectStaticComponent, (prev, next) =>
    compareProps<ComponentsPropertiesProps>(prev, next)
);
