import { isEmpty } from "../../../modules/process_execution/utils/fields";
import { toArray } from "../../util";
import { getItemValue, getTableAction } from "../utils/functions";
import { Utilitaries } from "../../../business";

// Usados pela funcao eval nao tirar daqui
// eslint-disable-next-line no-unused-vars
const { round, round3 } = require("./calc");

const emptyFunction = () => {};

const regex =
    /\w+\s*(?==)|\w+\s*(?=\*)|\w+\s*(?=-)|\w+\s*(?=>)|\w+\s*(?=<)|\w+\s*(?=!)|\w+\s*(?=\+)|\w+(?=})|\w+(?=\))|\w+\s*(?=div)|\w+\s*(?=\s(else))|\w+$|(?<=expressao_lenght\(\.\.\/)\w+/g;
// if(//tipoterceiro='C') then //tipoterceiro + //terceiro else ''

export function regex_match(xpath) {
    if (!xpath || !xpath.match(regex)) return [];

    const items = xpath.match(regex);
    const params = items.map((item) => {
        const clearItem = /\w+/.exec(item);
        return clearItem?.[0];
    });

    return params;
}

export function getAllDependencies(xpath) {
    if (!xpath || !xpath.match(regex)) return [];
    // const regexDependencies = /\w+\s*(?==)|\w+\s*(?=>)|\w+\s*(?=<)|\w+\s*(?=!)|\w+\s*(?=\+)|\w+(?=})|\w+(?=\))|(?<=\/\/)\w+(?!(\/|\w))/g;
    const regexDependencies =
        /\w+\s*(?==)|\w+\s*(?=>)|\w+\s*(?=<)|\w+\s*(?=!)|\w+\s*(?=\+)|\w+\s*(?=\*)|\w+(?=})|\w+(?=\))|(?<=\/\/)\w+(?!(\/|\w))/g;
    const dependencies = xpath.match(regexDependencies).map((item) => /\w+/.exec(item)[0]);
    return [...new Set([...dependencies])];
}

export function input_validations({
    item,
    relKey,
    formData = {},
    functions = {
        comparar_datas: emptyFunction,
        moment: emptyFunction,
        texto: emptyFunction,
        auto_preenche: emptyFunction,
    },
    returnExpression = false,
    rootDataName = undefined,
    inputProps = {},
    allInputs = {},
    selfValue,
}) {
    // eslint-disable-next-line no-unused-vars
    const { comparar_datas, moment, texto, auto_preenche } = functions;

    // eslint-disable-next-line no-unused-vars
    const toInteger = (value) => {
        try {
            if (typeof value === "number" || typeof value === "string") {
                return parseInt(value);
            }
        } catch (error) {}

        return "";
    };

    // eslint-disable-next-line no-unused-vars
    const isValidateBicSwift = (value) => {
        value = value.trim();
        if (value.length == 8 || value.length == 11) {
            return true;
        }
        return false;
    };

    // eslint-disable-next-line no-unused-vars
    const expressao_lenght = (value, length) => {
        // const itemValue = eval(value);

        const itemValueLength = Utilitaries.toString(value).length;

        return itemValueLength === length;
    };

    const xpath = item["@tipo"] === "conjunto" ? getTableAction({ item, relKey }).valueDep : item[relKey];

    let allFieldNames = regex_match(xpath);
    // let jsExp = se tipo conju ? funcao () : item[relKey];
    let jsExp = item["@tipo"] === "conjunto" ? getTableAction({ item, relKey }).valueDep : item[relKey];

    if (xpath === "nif") {
        return Utilitaries.isValidateNIF_PT(selfValue);
    }

    if (relKey === "@apenas_leitura" && jsExp === "true()") {
        return true;
    }

    if (jsExp === "../estabelecimento = ''") {
    }

    //
    jsExp = jsExp
        .replace(/\sor\s/gi, " || ")
        .replace(/\sand\s/gi, " && ")
        .replace(/\s=\s/gi, " == ")
        .replace(/(?<=[a-z])=(?=')/gi, " == ")
        .replace(/'true'/gi, "'t'")
        .replace(/true/gi, "'t'")
        .replace(/'false'/gi, "'f'")
        .replace(/false/gi, "'f'")
        .replace(/''''/gi, "''")
        .replace(/\sdiv\s/gi, " / ")
        .replace(/(?:\\[rn])+/g, "");

    if (!/\s!=\s/g.test(jsExp) && !/\s>=\s/g.test(jsExp) && !/\s<=\s/g.test(jsExp) && !/\s==\s/g.test(jsExp)) {
        jsExp = jsExp.replace(/=/g, "==");
    }

    // have if - else (then - else) condition
    if (/then/.test(jsExp)) {
        // jsExp = jsExp.replace(/\sthen\s/gi, " { ").replace(/\selse\s/gi, " } $& { ");
        // jsExp = jsExp + "}";
        jsExp = jsExp.replace(/if/gi, "");
        jsExp = jsExp.replace(/\sthen\s/gi, " ? ").replace(/\selse\s/gi, " : ");
    }

    // have just THEN
    if (/THEN/.test(jsExp)) {
        jsExp = jsExp.replace(/THEN/, "");
    }

    // ------------------------------
    const sumRegex = /(?<=sum\().*(?=\))/;
    if (sumRegex.test(xpath)) {
        const expression = jsExp.match(/sum\(.*?\)/g);
        expression.forEach((element) => {
            const resultCount = sumConvertion({ xpath: element, formData, allInputs });
            jsExp = jsExp.replace(element, resultCount);
        });
        // allFieldNames = jsExp.match(fieldNameRegex) || [];
        allFieldNames = regex_match(jsExp);
    }

    const countRegex = /(?<=count\().*(?=\))/;
    if (countRegex.test(xpath)) {
        const expression = jsExp.match(/count\(.*?\)/g);
        expression.forEach((element) => {
            const resultCount = countConvertion({ xpath: element, formData, allInputs });
            jsExp = jsExp.replace(element, resultCount);
        });
        // allFieldNames = jsExp.match(fieldNameRegex) || [];
        allFieldNames = regex_match(jsExp);
    }

    // const listRegex = /x_lista:\/\/\w+\/\w+\[[^\]]*\]/;
    const listRegex = /x_lista:\/\/\w+\/\w+\[.*?\]|x_lista:\/\/\w+\/\w+/;
    if (listRegex.test(xpath)) {
        const expression = jsExp.match(/x_lista:\/\/\w+\/\w+\[.*?\]|x_lista:\/\/\w+\/\w+/g);
        expression.forEach((element) => {
            const resultCount = listConvertion({ xpath: element, formData, allInputs });
            jsExp = jsExp.replace(element, resultCount);
        });
        // allFieldNames = jsExp.match(fieldNameRegex) || [];
        allFieldNames = regex_match(jsExp);
    }
    // ------------------------------

    let existEmptyValues = false;
    // if (
    //     jsExp ===
    //     "if(../id_subsidio == '') { auto_preenche(load_subs_by_design,{estab,../estab},{design,../designacao_real},{ignoraCache, 1}) }  else  { ../designacao}"
    // )
    //     ;
    for (const fieldName of allFieldNames) {
        const check = isNaN(parseInt(fieldName));
        if (!check) {
            continue;
        }
        let fieldValue = null;

        if (Utilitaries.toArray(inputProps?._nodes).length > 0) {
            fieldValue = getItemValue({ itemKey: fieldName, formData, objectInputs: inputProps });
        }

        if (!fieldValue) {
            if (inputProps.brothers && inputProps.brothers.includes(fieldName)) {
                fieldValue = `'${
                    typeof inputProps.items.data?.[fieldName] == "number"
                        ? inputProps.items.data?.[fieldName]
                        : inputProps.items.data?.[fieldName] || ""
                }'`;
            } else {
                fieldValue = `${rootDataName ? rootDataName : "formData"}['${fieldName}']`;
                const value = eval(fieldValue);
                fieldValue = typeof value === "number" ? value : `'${isEmpty(value) ? "" : value}'`;
            }
        }

        // if start with function to compare date
        if (jsExp.startsWith("comparar_datas(")) {
            fieldValue = `moment(formData['${fieldName}'])` || null;

            if (!formData[fieldName]) {
                existEmptyValues = true;
            }
        } else {
            let value = null;
            try {
                value = eval(fieldValue);
            } catch (error) {}

            if ((!value || value === "''") && typeof value !== "number") {
                value = getItemValue({ itemKey: fieldName, formData, objectInputs: allInputs });
            }
            fieldValue = typeof value === "number" ? value : `'${isEmpty(value) ? "" : value}'`;
        }

        if (jsExp.includes("..//" + fieldName)) {
            const regex = new RegExp(`..//${fieldName}`, "g");
            jsExp = jsExp.replace(regex, fieldValue);
        }
        if (jsExp.includes("../" + fieldName)) {
            const regex = new RegExp(`../${fieldName}`, "g");
            jsExp = jsExp.replace(regex, fieldValue);
        } else if (jsExp.includes("//" + fieldName)) {
            jsExp = jsExp.replace("//" + fieldName, fieldValue);
        }
    }

    jsExp = jsExp.replace(/'true'/gi, "'t'");

    // not validate if field is alread null
    //
    if (existEmptyValues && relKey === "@restricao") {
        return true;
    }

    if (jsExp.startsWith("tamanho_maximo(")) {
        if (inputProps?.items?.data?.["serie"]?.length > parseInt(allFieldNames)) {
            return false;
        } else return true;
    }

    if (jsExp.startsWith("comparar_datas(")) {
        jsExp = jsExp.replace(/\s>=\s(?=moment)/g, ".isSameOrAfter(").replace(/\s<=\s(?=moment)/g, ".isSameOrBefore(");
        jsExp = jsExp.replace(/']\)\)/g, "$&)");

        if (existEmptyValues) {
            return true;
        }
    }

    jsExp = jsExp.replace(/(?<=\s|^)bic\/swift(?=$|\s)/g, " isValidateBicSwift(selfValue) ");

    if (returnExpression) {
        return jsExp;
    }

    //
    let result = "";
    try {
        // eslint-disable-next-line no-eval
        result = eval(jsExp);
    } catch (error) {
        console.error(error);
        //
        //     item,
        //     relKey,
        //     formData,
        //     functions,
        //     returnExpression,
        //     rootDataName,
        //     inputProps,
        // });
    }

    return result;
}

const listConvertion = ({ xpath, formData, allInputs = {} }) => {
    // downFile(JSON.stringify({ xpath, formData, allInputs }), "json.txt", "text/plain");

    const root = xpath
        .replace("x_lista://", "")
        .split("/")
        .map((it) => it.replace(/\W/g, ""));

    // Make to work in dataGrid

    const tableOrDataGridRoot = root[0];

    if (!formData) {
        return "";
    }

    const searchedTable = Object.entries(formData || {}).find(([key, inputs]) => {
        if (typeof inputs === "object" && inputs && inputs?.[tableOrDataGridRoot]) {
            return true;
        }

        return false;
    });

    let tableFirstKey = null;
    let table = null;
    if (searchedTable) {
        [tableFirstKey, table] = searchedTable;
    }

    // let table = Object.values(formData).find((item) => item[rootPath]);

    // let tableFirstKey = null;
    let tableLastKey = null;

    if (table) {
        if (formData?.["linha"]?.[tableOrDataGridRoot]) {
            tableFirstKey = "linha";
        }
        tableLastKey = tableOrDataGridRoot;
    }

    //

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

        let allFieldNames = xpath
            .match(regex)
            ?.filter((item) => item)
            ?.map((it) => it.trim());

        // x_lista://areas/area[../incluir='true']

        //     // const fieldName = allFieldNames;
        const tableValues = toArray(formData[tableFirstKey][tableLastKey]);
        // const tableValues = formData[tableFirstKey][tableLastKey];

        let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        let fieldToGet = xpath.match(/\w*\s*(?=\[)/g);
        fieldToGet = fieldToGet?.[0];

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            if (!fieldToGet) {
                fieldToGet = conditionXpath;
            }
            //

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;
                allFieldNames.forEach((fieldName) => {
                    condition = condition.replace(fieldName, `current["${fieldName}"]`);
                });
                const check = eval(condition);
                if (check) {
                    if (accumulator.length > 0) {
                        accumulator += `,'${current[fieldToGet]}'`;
                    } else {
                        accumulator += `'${current[fieldToGet]}'`;
                    }
                }
                return accumulator;
            }, "");
            return countValue;
        } else {
            if (!fieldToGet) {
                fieldToGet = root[root.length - 1];
            }

            let countValue = tableValues.reduce((accumulator, current) => {
                if (accumulator.length > 0) {
                    accumulator += `,'${current[fieldToGet]}'`;
                } else {
                    accumulator += `'${current[fieldToGet]}'`;
                }

                return accumulator;
            }, "");
            return countValue;
        }
    }

    return "";
};

const sumConvertion = ({ xpath, formData, allInputs = {} }) => {
    // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)/g;
    // downFile(JSON.stringify({ xpath, rootPath: tablePath, formData }), "json.txt", "text/plain");

    const rootPath = xpath.match(/\w+\s*(?=)/g)[1];

    if (!formData) {
        return 0;
    }
    const searchedTable = Object.entries(formData || {}).find(([key, inputs]) => {
        if (typeof inputs === "object" && inputs && inputs?.[rootPath]) {
            return true;
        }

        return false;
    });
    let tableFirstKey = null;
    let table = null;
    if (searchedTable) {
        [tableFirstKey, table] = searchedTable;
    }

    // let table = Object.values(formData).find((item) => item[rootPath]);

    // let tableFirstKey = null;
    let tableLastKey = null;

    // if in inputGroup
    if (!table) {
        let allTable = { ...allInputs };

        // ;
        table = Object.entries(allTable).find(([inputKey, inputs]) => {
            if (inputs["@tipo"] === "conjunto") {
                const tableSecondKey = Object.keys(inputs).find(
                    (tableAttributes) =>
                        !tableAttributes.startsWith("@") &&
                        !Array.isArray(inputs[tableAttributes]) &&
                        typeof inputs[tableAttributes] === "object"
                );

                if (inputs[tableSecondKey][rootPath]) {
                    tableFirstKey = inputKey;
                    tableLastKey = tableSecondKey;
                    return true;
                }
            }
            return false;
        });
    } else {
        if (formData?.["linha"]?.[rootPath]) {
            tableFirstKey = "linha";
        }
        tableLastKey = rootPath;
    }

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

        let allFieldNames = xpath
            .match(regex)
            .filter((item) => item)
            .map((it) => it.trim());

        const tableValues = toArray(formData[tableFirstKey]?.[tableLastKey]);

        //     /**
        //      * Garantir que funcione
        //      * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
        //      * count(//requisito_forn/avaliacao = 'NC')
        //      * count(//requisito_forn/id_requisito_aval_forn)
        //      * count(//requisito_forn/avaliacao = 'NA')
        //      * count(//requisito_forn/avaliacao != 'NA')
        //      * count(//requisito_forn/avaliacao > 'NA')
        //      * count(//requisito_forn/avaliacao < 'NA')
        //      * count(//requisito_forn/avaliacao)
        //      */
        let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let fieldToSum = xpath.match(/\w*\s*(?=\[)/g);

            if (!fieldToSum) {
                fieldToSum = conditionXpath;
            } else {
                fieldToSum = fieldToSum[0];
            }

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    condition = condition.replace(fieldName, `current["${fieldName}"]`);
                });

                const check = eval(condition);
                if (check) {
                    let currentValue = current[fieldToSum];
                    if (typeof currentValue === "string") {
                        currentValue = currentValue.replace(",", ".");
                    }
                    accumulator += Number(currentValue);
                }
                return accumulator;
            }, 0);
            return countValue;
        }
    }
    return 0;
};

const countConvertion = ({ xpath, formData, allInputs = {} }) => {
    // const regex = /\w*\s*(?==)|\w*\s*(?=!=)|\w*\s*(?=>)/g;
    // downFile(JSON.stringify({ xpath, rootPath: tablePath, formData }), "json.txt", "text/plain");
    const rootPath = xpath.match(/\w+\s*(?=)/g)[1];

    if (!formData) {
        return 0;
    }
    const searchedTable = Object.entries(formData || {}).find(([key, inputs]) => {
        if (typeof inputs === "object" && inputs && inputs?.[rootPath]) {
            return true;
        }

        return false;
    });
    let tableFirstKey = null;
    let table = null;
    if (searchedTable) {
        [tableFirstKey, table] = searchedTable;
    }

    // let table = Object.values(formData).find((item) => item[rootPath]);

    // let tableFirstKey = null;
    let tableLastKey = null;

    // if in inputGroup
    if (!table) {
        let allTable = { ...allInputs };

        // ;
        table = Object.entries(allTable).find(([inputKey, inputs]) => {
            if (inputs["@tipo"] === "conjunto") {
                const tableSecondKey = Object.keys(inputs).find(
                    (tableAttributes) =>
                        !tableAttributes.startsWith("@") &&
                        !Array.isArray(inputs[tableAttributes]) &&
                        typeof inputs[tableAttributes] === "object"
                );

                if (inputs[tableSecondKey][rootPath]) {
                    tableFirstKey = inputKey;
                    tableLastKey = tableSecondKey;
                    return true;
                }
            }
            return false;
        });
    } else {
        if (formData?.["linha"]?.[rootPath]) {
            tableFirstKey = "linha";
        }
        tableLastKey = rootPath;
    }

    if (table) {
        const regex = /\w*\s*(?=(=|\)|!|>|<))/g;

        let allFieldNames = xpath
            .match(regex)
            .filter((item) => item)
            .map((it) => it.trim());

        // ;

        //     // const fieldName = allFieldNames;
        const tableValues = toArray(formData[tableFirstKey]?.[tableLastKey]);

        //     /**
        //      * Garantir que funcione
        //      * count(//requisito_forn/avaliacao[../avaliacao = 'C'])
        //      * count(//requisito_forn/avaliacao = 'NC')
        //      * count(//requisito_forn/id_requisito_aval_forn)
        //      * count(//requisito_forn/avaliacao = 'NA')
        //      * count(//requisito_forn/avaliacao != 'NA')
        //      * count(//requisito_forn/avaliacao > 'NA')
        //      * count(//requisito_forn/avaliacao < 'NA')
        //      * count(//requisito_forn/avaliacao)
        //      */
        let conditionXpath = xpath.match(/\[.*?\]|\/\w+\s*((=|>|<|!).*)*\)/g);

        if (conditionXpath) {
            conditionXpath = conditionXpath[0].replace(/\[|\/|\]|\.|\)/g, "");

            let fieldToSum = xpath.match(/\w*\s*(?=\[)/g);

            if (!fieldToSum) {
                fieldToSum = conditionXpath;
            } else {
                fieldToSum = fieldToSum[0];
            }

            let countValue = tableValues.reduce((accumulator, current) => {
                let condition = conditionXpath;

                allFieldNames.forEach((fieldName) => {
                    condition = condition.replace(fieldName, `current["${fieldName}"]`);
                });

                const check = eval(condition);
                if (check) {
                    accumulator += 1;
                }
                return accumulator;
            }, 0);
            return countValue;
        }
    }
    return 0;
};
